.research_container {
    padding: 16px;
    margin: 0 16px;
    background-color: #fff;
    height: calc(100% - 59px);
    overflow-y: scroll;
    padding-top: 0;
    .search {
        display: flex;
        justify-content: space-between;
        background-color: #fff;

        .search_con {
            flex: 1 1;
            display: flex;
            flex-wrap: wrap;

            .search_item {
                margin-right: 30px;
                display: flex;
                align-items: center;
                padding-top: 16px;
                .label {
                    width: 60px;
                    display: inline-block;
                    text-align: right;
                    margin-right: 8px;
                }
            }

        }

        .search_btn {
            display: flex;
            margin-left: 54px;
            margin-right: 27px;
            padding-top: 16px;
        }
    }

    .cpds_content {
        padding-top: 22px;

        .planStatus {

            .release,
            .design {
                color: #52C41A;
                position: relative;
                padding-left: 10px;
                font-weight: 300;

                &::before {
                    position: absolute;
                    width: 6px;
                    height: 6px;
                    content: "";
                    background-color: #52C41A;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    border-radius: 50%;
                }
            }

            .design {
                color: #3391F0;

                &::before {
                    background-color: #3391F0;
                }
            }
        }
    }
}