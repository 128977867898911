.login {
  width: 100%;
  height: 100%;
  background-color: #fff;
  background-image: url("login_bg2.png");
  background-repeat: no-repeat;
  background-size: 900px 1080px;
  background-position: 0 0;
  padding:200px 0 0 1000px;

  .login_register {
    display: inline-block;
    margin-right: 15px;
    cursor: pointer;
    color: #3988EF;
  }

  .title {
    color: #3988EF;
    font-size: 43px;
    line-height: 43px;
    margin-bottom: 16px;
    letter-spacing: 1px;
    font-family: PingFang SC;
  }

  .title_tips {
    color: #D9D9D9;
    font-size: 24px;
    font-family: Arial;
    margin-bottom: 60px;
    letter-spacing: 1px;
  }

  .login_main {
    width: 550px;
    .user_label{
      color: #3D3D3D;
      padding-bottom: 12px;
    }
    .ant-input {
      height: 30px;
    }
    .fontIcon{
      font-size: 18px;
      color: #c0c0c0;
    }
    .iconEye{
      font-size: 18px;
      color: #000;
    }
    .agreement{
      padding-left: 10px;
      .view_agreement{
        cursor: pointer;
        color: #3988EF;
      }
    }

    .action_submit{
      .ant-form-item-control-input-content{
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
    }

    .ant-btn.ant-btn-primary.submit {
      text-align: center;
      font-family: PingFang SC;
      width: 130px;
      background-color: #3988EF;
      border: none;
      font-size: 18px;
      height: auto;
    }


  }


  .ant-form-item-explain.ant-form-item-explain-error {
    text-align: left;
  }

}

.login_hd {
  width: 100%;
  height: 100%;
  background-color: #fff;
  background-image: url("login_bg3.png");
  background-repeat: no-repeat;
  background-size: 50% 100%;
  background-position: 0 0;
  padding:15% 0 0 60%;

  .title_box {
    background-image: url('login_title_bg.png');
    padding-left: 12%;
    width: 60%;
    height: 17vh;
    background-repeat: no-repeat;
    background-position: 0 0%;
    background-size: 100% 90%;
    margin-left: 10%;

    .title {
      color: #54ae9f;
      margin-bottom: 0;
      letter-spacing: 1px;
      font-family: PingFang SC;
      word-wrap: initial;
    }

    .title_tips {
      color: #54ae9f;
      font-family: Arial;
      margin-bottom: 60px;
      letter-spacing: 1px;
    }
    @media (max-width: 1920px) {
      .title {
        font-size: 3em;
      }
      .title_tips {
        font-size: 1.2em;
      }
    }
    @media (max-width: 1680px) {
      .title {
        font-size: 2.5em;
      }
      .title_tips {
        font-size: 1em;
      }
    }
    @media (max-width: 1440px) {
      .title{
        font-size: 2em;
      }
      .title_tips {
        font-size: 0.9em;
      }
    }
    @media (max-width: 1280px) {
      .title{
        font-size: 1.8em;
      }
      .title_tips {
        font-size: 0.8em;
      }
    }
  }

  .login_main {
    width: 60%;
    margin-left: 10%;

    .user_label{
      color: #828282;
      padding-bottom: 12px;
    }
    .ant-input {
      height: 36px;
      border: 1px solid #e1e1e1;
      border-radius: 5px;
      font-size: 16px;
    }
    .fontIcon{
      font-size: 18px;
      color: #c0c0c0;
    }
    .iconEye{
      font-size: 18px;
      color: #000;
    }
    .agreement{
      padding-left: 10px;
      .view_agreement{
        cursor: pointer;
        color: #3988EF;
      }
    }

    .ant-input-affix-wrapper{
      border: 1px solid #e1e1e1;
      border-radius: 5px;

      .ant-input {
        border: 0px;
      }
    }

    .action_submit{
      .ant-form-item-control-input-content{
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
    }

    .ant-btn.ant-btn-primary.submit {
      text-align: center;
      font-family: PingFang SC;
      width: 130px;
      background-color: #55af9f;
      border: none;
      font-size: 18px;
      height: auto;
      border-radius: 6px;
    }


  }

}

@media (max-width: 1680px) {
  .login{
    background-size: 787.5px 945px !important;
    background-position: 0 0;
    padding:200px 0 0 830px !important;
    .login_main{
      width: 500px !important;
    }
  }
}


@media (max-width: 1440px) {
  background-size: 675px 810px  !important;
  background-position: 0 0;
  padding:200px 0 0 700px !important;
  .login_main{
    width: 450px !important;
  }
}


@media (max-width: 1280px) {
  .login{
    background-size: 594px 800px !important;
    background-position: 0 0;
    padding:200px 0 0 630px !important;
    .login_main{
      width: 400px !important;
    }
  }
}


.login_code_modal{
  .ant-modal-body{
    width: 100%;

  }
  .login_code{

    width: 80%;
   
  }
}






