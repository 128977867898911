.monthReport{
    .partPop{
        text-align: right;
        .ant-btn-primary{
            display: inline-block;
            background: #6287EC;
            border-color: #6287EC;
            margin-left: 15px;
        }
        .ant-btn.ant-btn-primary[disabled] {
            color: rgba(0, 0, 0, 0.25);
            background: #f5f5f5;
            border-color:transparent;
            box-shadow: none;
          }
    }
    .ant-table-pagination.ant-pagination{
        padding: 16px 0 0;
        background: #fff;
        margin:0;
    }

    .con_div{
        text-align: left;
        .title{
            font-size: 14px;
            color: #000;
            margin-bottom: 5px;
        }
        .sub-title{
            font-size: 12px;
            color: rgba(0, 0, 0, 0.45);
        }
    }

    
}