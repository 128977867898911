.e_Group1 {
  width: 30%;
  margin-bottom: 0;
  float: left;
  background: #fff;
  padding: 36px 32px 36px 32px;
  min-height: 800px;

  > ul {
    padding-left: 0px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0px;

    > li {
      text-align: center;
      margin-bottom: 36px;
      width: 50%;

      .echarts_title {
        font-size: 14px;
        text-align: center;
        margin-top: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;

        span {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #484848;
        }

        p {
          margin: 0px;
          width: 62%;
          background: #6C96F7;
          border-radius: 2px;
          margin: 0 auto;
          margin-bottom: 6px;

          span {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
          }
        }
      }
    }
  }
}