.user_container {
    padding: 16px;
    margin: 0 16px;
    background-color: #fff;

    .search {
        padding-bottom: 30px;
        display: flex;
        align-items: center;

        .searchItem {
            margin-right: 10px;
            display: flex;
            align-items: center;
        }
    }

    .opertion {
        display: flex;

        .jurisdiction {
            color: #5CBDCE;
            cursor: pointer;
        }

        .disable {
            color: #FCA130;
            cursor: pointer;
            padding-left: 20px;
        }


        .delete {
            color: #F8412B;
            cursor: pointer;
            padding-left: 20px;
        }
    }
}

.resourceModal {

    .resource {

        max-height: 500px;
        overflow-y: scroll;

        .firstResource {
            padding-bottom: 20px;

            .firstTitle {
                font-size: 22px;
                padding-bottom: 10px;
            }

            .secondResource_content {
                padding-top: 10px;
                padding-left: 17px;
                background-color: #efefef;
                .secondResource {
                    margin-right: 10px;
                    padding-bottom: 6px;

                    .secondTitle {
                        font-size: 18px;
                        padding-bottom: 5px;
                    }

                    .threeResource_content {
                        display: flex;
                        flex-wrap: wrap;
                        padding-left: 17px;
                        background-color: #efefef;
                        .threeResource {
                            width: 180px;
                        }
                    }
                }
            }
        }
    }

}