.reasonModal {

  .ant-checkbox-group-item {
    width: 100% !important;
  }

  .case-audit-text-area {
    margin-top: 5px;
    border-radius: 5px;
    width: 100%;
    min-height: 100px;
    outline: none;
    border-color: #d4d0d0;
  }
}