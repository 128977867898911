.applyList {
    padding: 0 16px 16px;
    background: #f5f7fa;
    height: calc(100% - 55px);

    .apply_wrap {
        height: 100%;
        padding: 20px;
        background: #fff;
        overflow: auto;
    }

    .search_wrap1 {
        text-align: right;
        z-index: 2;

        .autoTxt {
            color: #1890ff;
            font-size: 12px;
        }

        button.ant-btn.ant-btn-primary {
            border-radius: 5px;
        }
    }

    .btns_wrap {
        background: #fff;
    }

    .ant-btn.ant-btn-link {
        font-size: 13px;
    }

    .partPop {
        text-align: left;

        .ant-btn.ant-btn-link {
            padding-left: 0;
        }

        .ant-btn.ant-btn-primary[disabled] {
            color: rgba(0, 0, 0, 0.25);
            background: #f5f5f5;
            border-color: transparent;
            box-shadow: none;
        }
    }

    .ant-table-pagination.ant-pagination {
        padding: 16px 0 0;
        background: #fff;
        margin: 0;
    }

    .con_div {
        text-align: left;
        font-size: 13px;

        .title {
            color: #000;
            margin-bottom: 5px;
        }

        .sub-title {
            color: rgba(0, 0, 0, 0.45);
        }

        .yellow {
            color: orange;
        }
    }

    .apply_state {
        .ant-btn {
            height: auto;
            line-height: auto;
            padding: 2px 7px;
            border: none;
            border-radius: 5px;
            font-size: 8px !important;
        }

        .ant-btn-primary {
            color: #00B4FF;
            background-color: #CAEEFE;
        }

        .ant-btn-default {
            color: #999;
            background-color: #eee;
        }
    }
}



.applyList_modal {
    .linkBtn {
        font-size: 13px;
        color: #1890ff;
    }

    .mr20 {
        margin-right: 90px;
    }

    .model_detail_Info {
        margin-bottom: 20px;
        padding: 0 40px;

        .title {
            display: inline-block;
            margin-bottom: 10px;
        }

        .wraps {
            font-size: 13px;
            margin-bottom: 10px;
            height: 24px;
            display: flex;

            .label {
                color: #999;
                display: inline-block;
                width: 20%;
                text-align: right;
                margin-right: 16px;
            }

            .ant-picker.ant-picker-disabled,
            .ant-select {
                width: 100%;
            }
        }

        .wraps_Image {
            margin-left: calc(20% + 16px);

            .Image_item {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                flex-wrap: wrap;
                align-items: center;
                height: 60px;

                .ant-image {
                    margin-right: 15px;

                    .ant-image-mask-info {
                        font-size: 12px;
                    }
                }
            }
        }

    }

    .ant-form-item {
        padding: 0 40px;
    }

    .ant-form-item.mb10 {
        margin-bottom: 10px;
    }

    .ant-form-item-explain {
        font-size: 12px;
    }

    .ant-form-small .ant-form-item-label>label {
        font-size: 13px;
        color: #999;
    }

    .ant-form-item-explain {
        line-height: 20px;
    }

    .ant-form-item:last-of-type {
        position: absolute;
        z-index: 1;
        bottom: -9px;
        right: 16px;
        padding: 0;

        .ant-btn.ant-btn-primary {
            line-height: 32px;
            height: 32px;
            border-radius: 5px;
            padding: 0 15px;
        }
    }

    .ant-modal-body {
        padding-bottom: 5px;
    }

    .ant-modal-footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 55px;

        .ant-btn {
            font-size: 13px;
            border-radius: 5px;
        }

        .ant-btn.ant-btn-primary {
            margin-left: 20px;
        }

        .ant-picker,
        .ant-select {
            width: 100% !important;
        }
    }
}