.control {
    min-height: 450px;
    overflow: auto;
    height: calc(100vh - 300px);
    background-color: #fbfbfb;

    & .formItem {
        width: 100%;

        & .title {
            color: #2E2F33;
            font-size: 16px;
            font-weight: 500;
            padding-left: 10px;
            border-left: 6px solid #6287EC;
            margin-left: 4px;
            margin-top: 10px;
        }

        & .form_body {
            padding: 0 10px;

            & .formCom {
                display: flex;
                align-items: center;
                margin-top: 10px;
                padding: 6px 0;

                & .label {
                    color: #696868;
                    line-height: 30px;
                    font-weight: 500;
                    display: flex;
                    text-align: right;
                    justify-content: flex-end;
                }

                & .form_field {
                    margin-left: 10px;
                    display: flex;
                    align-items: center;
                    flex-shrink: 1;
                    width: 100%;
                    min-height: 32px;
                }
            }

            & .formCom_styleTwo {
                margin-top: 10px;
                padding: 6px 0;

                & .label {
                    color: #696868;
                    line-height: 30px;
                    font-weight: 500;
                    display: flex;
                }

                & .form_field {
                    margin-left: 10px;
                    display: flex;
                    align-items: center;
                    flex-shrink: 1;
                    min-height: 32px;
                }
            }

            & .formCom_styleThree {
                display: flex;
                align-items: center;
                margin-top: 10px;
                padding: 6px 0;

                & .label {
                    color: #696868;
                    line-height: 30px;
                    font-weight: 500;
                    display: flex;
                    width: 150px;
                    text-align: right;
                    justify-content: flex-end;

                }

                & .form_field {
                    margin-left: 10px;
                    display: flex;
                    align-items: center;
                    flex-shrink: 1;
                    width: calc(100% - 150px);
                    min-height: 32px;
                }
            }
        }
    }

    .ActionView_content {
        display: none;
        vertical-align: bottom;
    }
}