.area-manager {
  &--container {
    padding: 16px;
    margin: 0 16px;
    background-color: #fff;
    overflow-y: auto;
    height: calc(100% - 80px);
    .ant-form-item {
      margin-bottom: 0;
    }
  }
  &--table {
    margin-top: 16px;
    .primary {
      color: #6c96f7;
    }
    .success {
      color: #52c41a;
    }
    .warning {
      color: #ef8767;
    }
    .secondary {
      color: #e6ebf5;
    }
  }
  &--progress {
    display: flex;
    grid-gap: 8px;
    gap: 8px;
    justify-content: center;
    align-items: center;
    .progress-item {
      flex: 1 1;
      max-width: 10px;
      height: 50px;
      &[data-state='Complete'] {
        background-color: #6c96f7;
      }
      &[data-state='Running'] {
        background-color: #52c41a;
      }
      &[data-state='Wait'] {
        background-color: #e6ebf5;
      }
      &[data-state='Question'] {
        background-color: #ef8767;
      }
      &[data-state='Answer'] {
        background-color: #477291;
      }
    }
  }
}