.user_container {
    padding: 16px;
    margin: 0 16px;
    background-color: #fff;

    height: calc(100% - 59px);
    overflow-y: scroll;

    .search {
        padding-bottom: 30px;
        display: flex;
        justify-content: space-between;

        .searchLeft {
            display: flex;

            .searchItem {
                margin-right: 10px;
            }
        }

        .addBtn {
            float: right;
        }
    }

    .opertion {
        .anticon {
            margin-right: 15px;
            cursor: pointer;
            font-size: 16px;
        }

        .opertion {
            display: flex;

            .jurisdiction {
                color: #5CBDCE;
                cursor: pointer;
            }

            .disable {
                color: #FCA130;
                cursor: pointer;
                padding-left: 20px;
            }


            .delete {
                color: #F8412B;
                cursor: pointer;
                padding-left: 20px;
            }
        }
    }

    .remarks {
        white-space: nowrap;
        /*强制一行显示*/
        overflow: hidden;
        /*超出部分隐藏*/
        text-overflow: ellipsis;
        /*最后添加省略号*/
    }
}