/*项目月报明细*/
.details_detail{
    background-color: #fff;
    padding: 10px;
    .ant-table-tbody td,
    .ant-table-thead th{
        padding:5px 8px;
    }
    .ant-pagination{
        position: relative;
        li.ant-pagination-total-text{
            position: absolute;
            left: 0;
            font-size: 12px;
            color: #999;
        }
    }
}
