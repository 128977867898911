.feat-message--quick {
  width: 300px;
  height: 200px;
  overflow: hidden;
  overflow-y: auto;
  padding: 8px 12px;
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      height: 24px;
      line-height: 24px;
      font-size: 13px;
      color: #333;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 0 6px;
      &:hover {
        cursor: pointer;
        background-color: #f1f1f1;
        border-radius: 4px;
      }
    }
  }
}

.feat-message--quick-overlay {
  .ant-popover-inner-content {
    padding: 0;
  }
}