.data_wrap {
    width: 100%;
    background: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: 60px;

    .cols-con{
      padding: 0 10px;
      background-color: #fff;
      display: flex;
      align-items: center;

      .subjects-left {
        margin: 0 10px;
        .item_icon {
          width: 45px;
          height: 45px;
          border-radius: 50%;
          position: relative;
  
          .anticon {
            svg {
              font-size: 20px;
              position: absolute;
              left: 0;
              right: 0;
              top: 0;
              bottom: 0;
              margin: auto;
            }
          }
        }
      }
  
      .subjects-right {
        padding-left: 6px;
        text-align: left;
  
        .number{
          font-size: 22px;
          font-family: Roboto;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .name{
          font-size: 14px;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.65);
          margin-bottom: 5px;
        }
      }
    }
    .rline{
      width: 1px;
      height: 40px;
      background-color: #F6F7FC;
    }
  }