.enter_content {
    letter-spacing: 1px;
    border-radius: 5px;
    /*height: 100%;*/
    height: calc(100% - 60px);

    .card{
      /*padding-top: 20px;*/
      height: 100%;
      .ant-card{
        margin: 0 16px 16px;
        height: calc(100% - 16px);
      }
    }
    .ant-card-head{
      min-height: auto;
      padding: 0 15px;
      .ant-card-head-wrapper{
        .ant-card-head-title, .ant-card-extra{
          padding: 5px 0;
        }
      }
    }
    
    .ant-card-body{
      padding: 0;
      height: calc(100% - 40px);
    }

    .pictureList{
      border-right: 1px solid #eee;
      padding: 20px 0;
      height: 100%;
      background-color: #ededed;
      position: relative;

      .slider_top{
        height: calc(100% - 56px);
        margin-top: 15px;
        margin-bottom: 15px;
        
        .slick-prev, .slick-next{
          display: none;
        }

        .slick-slider{
          height: 100%;
          .slick-list{
            height: 100%;
            .slick-track{
              height: 100%;
              .slick-slide{
                height: 100%;
                >div {
                  height: 100%;
                  .ImgHeight{
                    height: 100%;
                    position: relative;
                    background: rgba(77, 77, 77, .7);
                    .ant-image{
                      height: 100%;
                      background: rgba(77, 77, 77, .7);
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      .ant-image-img{
                        max-width:100%;
                        max-height:100%;
                        object-fit: contain;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .slider_bottom{
        height: 60px;
        padding: 0 20px;
        position: relative;
        
        .slick-slider{
          height: 100%;
          z-index: 1;
          .slick-prev:before, .slick-next:before{
            color: #ccc;
          }
          .slick-next{
            right: -17px;
            top: 45%;
          }
          
          .slick-prev{
            left: -15px;
            top: 45%;
          }
          .slick-list{
            height: 100%;

            /*background-image: linear-gradient(to right, transparent 99.5%, rgba(0, 0, 0, 0.05) 10% ), 
            linear-gradient(to left, transparent 99.5%, rgba(0, 0, 0, 0.05) 10% ), 
            linear-gradient(to top, transparent 99.5%, rgba(0, 0, 0, 0.05) 10% ), 
            linear-gradient(to bottom, transparent 99.5%, rgba(0, 0, 0, 0.05) 10% );
            background-size: 10% 100%;*/
            border: 1px solid rgba(0, 0, 0, 0.05);

            .slick-track{
              height: 100%;
              margin-left: 0;
              margin-right: 0;
              .slick-slide{
                height: 100%;
                >div {
                  height: 100%;
                  
                  .ImgHeight{
                    height: 100%;
                    padding: 3px;
                    position: relative;
                  }
                  .select{
                    height: 100%;
                    border: 1px solid rgb(10, 88, 255);
                    border-radius: 2px;
                    background: rgba(77, 136, 245, .2);
                    padding: 3px;
                    position: relative;
                  }
                  .corner{
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    z-index: 2;
                    width: 100%;
                  }
                }
              }
            }
          }
        }
        .slick-slide img{
          width:100%;
          height:100%;
          object-fit: contain;
          margin: auto;
        }
        .slick-dots{
          bottom: 0;
          top: 85%;
        }
        .slick-dots li {
          width: 10%;
          height: 10px;
          background-color: #ccc;
          border-radius: 20px;
          .renderCustomPaging{
            width: 100%;
            height: 100%;
          }
        }
        .slick-dots li.slick-active{
          background-color: #4e8ce8;
        }

        .slider-bg{
          position: absolute;
          width: calc(100% - 40px);
          height: 100%;
          top: 0;
          left: 20px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          /*z-index: -1;*/
          
          .bg-item{
            width: 10%;
            height: 100%;
            border-right: 1px solid #ddd;
          }
        }
      }
      .enter_info{
        font-size: 14px;
        padding: 25px;
        display: flex;
        justify-content: start;
        align-items: center;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        background: rgba(0, 0, 0, 0.5);
    
        .tit{
          margin-bottom: 5px;
          font-size: 16px;
          color: rgba(255,255,255,0.8);
        }
        .subtit_con{
          position: relative;
          width: 100%;
          height: 100%;
          .subtit{
            color: rgba(255,255,255,0.7);
            display: inline-block;
          }
          .sensitiveinfo{
            position: absolute;
            top:0;
            right: 0;
            height: 100%;
          }
        }
        .CloseOutlined{
          position: absolute;
          right: 1%;
          top: -11px;
          font-size: 14px;
          color: rgba(255, 255, 255, 0.8);
          z-index: 2;
          background: rgba(0,0,0,.5);
          border-radius: 50%;
          padding: 5px;
        }
      }
      .InfoOutlined{
        position: absolute;
        right: 5%;
        bottom: 4%;
        color: #fff;
        background: rgba(0, 0, 0, 0.3);
        border-radius: 50%;
        padding: 7px;
        cursor: pointer;
      }
    }
    .enterInfo{
      padding: 10px 15px 0;
      height: calc(100% - 80px);
      overflow-y: auto;
      .ant-spin-nested-loading{
        height: 100%;
        background-color: #fbfbfb;
      }
    }
    .operate_bottom{
      padding: 16px 16px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 50px;
      
      .btns .ant-btn{
        font-size: 13px;
        margin-left: 16px;
      }
      .countDown{
        color: red;
        font-size: 13px;
      }
    }

    .switch{
      display: flex;
      align-items: center;
      border: 1px solid #ddd;
      border-radius: 8px;
      .left{
        padding: 5px 15px;
        border-right: 1px solid #ddd;
        border-radius: 8px 0 0 8px;
      }
      .right{
        padding: 5px 15px;
        border-radius: 0 8px 8px 0;
      }
    }

    .dots_dom{
      width: 100%;
      height: 20px;
      background-color: red;
      border-radius: 30px;
    }

    .sign{
      background-color: #eed0a6;
      color: #ef9923;
      padding: 3px 10px;
      border-radius: 7px;
      font-weight: normal;
      display: inline-block;
      margin-right: 10px;
      font-size: 15px;
    }

  }