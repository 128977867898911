.tenantSetting_wrap{
   font-size: 16px;
   width: 50%;
   margin: 20px auto;
   
  .pTop {
      color: rgba(0, 0, 0, 0.85);
      font-size: 14px;
  }
  .leftLable {
      text-align: right;
  }
  .rowBottom {
      margin-bottom: 40px;
  }
}