.ChangePwd_wrap{
   font-size: 16px;
   color: #5CBDCE;
   height: calc(100% - 75px);
   background-color: #fff;
   margin: 0 16px 16px;
   display: flex;
   justify-content: center;
   align-items: center;
   overflow-y: auto;
   
   .ant-form-item-control-input .ant-btn-primary {
      background-color: #6287ec;
      border-color: #6287ec;
      border-radius: 5px;
      margin-top: 30px;
  }
  .pTop {
      color: rgba(0, 0, 0, 0.85);
      font-size: 14px;
  }
  .leftLable {
      text-align: right;
  }
  .rowBottom {
   margin-bottom: 40px;
  }
}