.modal_editor {
  p{
    margin:0;
  }
  .ant-modal-body,
  .ant-modal-footer {
    background: #F6F7FC;
    border-top: none;
  }

  .ant-modal-body {
    padding: 30px 46px 0px 46px;
  }

  .ant-modal-footer {
    padding: 0px 46px 30px 46px;
  }

  .ant-modal-close-x {
    height: 50px;
    line-height: 50px;

    .anticon {
      font-size: 12px;
    }
  }

  .ant-modal-header {
    padding: 14px 26px;
  }

  .ant-modal-title {
    font-family: PingFangSC-Regular, PingFang SC;
    line-height: 21px;
    font-size: 14px;
    font-weight: 500;
    color: #2E2F33;
  }

  .ant-btn.ant-btn-primary,
  .cancelBtn {
    background: #6287EC;
    border: 1px solid #6287EC;
    color: #fff;
  }

  .ant-btn.ant-btn-primary[disabled] {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    border-color: #d9d9d9;
    box-shadow: none;
  }

  .ant-form-item {
    margin-bottom: 15px !important;
  }
}