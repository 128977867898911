.ImgTask_wrap, .enterTask_wrap{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100% - 60px);
    overflow-y: auto;
    
    .txt{
      margin-top: 1%;
      font-size: 16px;
    }
    .hint{
      font-size: 14px;
      color: #999;
      display: inline-block;
      margin: 10px 0 30px;
      height: 22px;
    }
    .checkBtn.ant-btn-primary{
      letter-spacing: 2px;
      width: 10%;
      height: 55px;
      border-radius: 10px;
      font-size: 18px;
    }
  }