.InCompleteModals{
    position: relative;
    .ant-modal-body{
        max-height: 500px;
        overflow: auto;
    }
    .refuseContent{
        padding: 0 40px;
        
        .ant-row{
            display: block;
        }

        .ant-form-item:nth-of-type(1){
            margin-bottom: 10px;
        }
        .ant-form-item:last-of-type{
            position: absolute;
            z-index: 2;
            right: 20px;
            bottom: -14px;
        }

        .ant-form-item-label{
            margin-bottom: 15px;
        }
        .ant-checkbox-group{
            .ant-checkbox-wrapper{
                width: 100%;
                margin-bottom: 10px;
            }
        }
    }
}