html, body, #root {
  height: 100%;
  margin: 0;
  border: 0;
}

a,
a:link,
a:active,
a:hover,
a:visited {
  color: #2B4B59;
}

//icon 图标样式
.anticon {
  font-size: 13px;
}

.app .content {
  padding-top: 0px !important;
  padding-left: 0px !important;
  position: fixed;
  top: 50px;
  margin-left: 2px;
}

.ant-layout {
  /*background: #fff;*/
  background: #f6f7fc;
}

.app {
  height: 100%;
  background-color: #F7F7F7;

  .omz-hide {
    display: none;
  }

  .omz-fit {
    width: 100%;
    height: 100%;
  }

  .content {
    padding-top: 56px;
    padding-left: 220px;
    height: calc(100% - 50px);
  }


  .omz-toolbar {

    height: 42px;
    padding: 10px;
    background-color: #FFFFFF;
    box-shadow: 0 1px 1px #e2e2e2;

    .omz-breadcrumb {
      float: left;
      width: 50%;
    }

    .omz-action {
      float: right;
      width: 50%;
      text-align: right;
    }
  }

  .omz-page {
    height: 100%;
  }

  .omz-content {
    padding: 12px 16px 20px 16px;
    height: calc(100% - 36px);
  }

  .omz-panel {
    box-shadow: 0 1px 1px #e2e2e2;
    background-color: white;
    border-radius: 6px;

    .omz-title {
      padding: 12px;
      height: 48px;
      background-color: #F9F9F9;
      color: #6A6A6A;
      font-size: 16px;
      font-weight: 800;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }

    .omz-body {
      padding: 12px;
    }
  }

  .omz-search {
    padding-bottom: 10px;
  }
}

/*
.ant-popover-message-title {
  padding-left: 0px;
}

.ant-popover-buttons {
  text-align: center;
}

.ant-btn,
.ant-btn:hover,
.ant-btn:active {
  border-color: #d9d9d9;
  color: rgba(0, 0, 0, 0.85);
  border-shadow: none;
}

.ant-btn-primary {
  border-color: #F04864;
  background: #F04864;
}

.ant-btn-primary:hover,
.ant-btn-primary:active {
  border-color: #F04864;
  background: #F04864;
}*/
