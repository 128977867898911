.project_manage_modal {
  background: #FFFFFF;
  padding-bottom: 0;
  .ant-modal-title {
    font-weight: bold !important;
    font-size: 16px !important;
  }
  .ant-modal-body,
  .ant-modal-footer {
    background: #FFFFFF;
    border-top: none;
  }

  .ant-modal-body {
    padding: 30px 46px 0px 46px;
  }

  .ant-modal-footer {
    padding: 0px 46px 30px 46px;
  }

  .ant-modal-close-x {
    height: 50px;
    line-height: 50px;

    .anticon {
      font-size: 12px;
    }
  }

  .ant-modal-header {
    padding: 14px 26px;
  }

  .ant-modal-title {
    font-family: PingFangSC-Regular, PingFang SC;
    line-height: 21px;
    font-size: 14px;
    font-weight: 500;
    color: #2E2F33;
  }

  .ant-btn.ant-btn-primary,
  .cancelBtn {
   /*
    * background: #6287EC;
    * border: 1px solid #6287EC;
    * color: #fff;
    */
    span {
      &:last-child {
        /*margin-left: 5px;*/
      }
    }
  }

  .ant-btn.ant-btn-primary[disabled] {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    border-color: #d9d9d9;
    box-shadow: none;
  }

  .upFile,
  .upIngFile,
  .upedFile {
    text-align: left
  }

  .upFile {
    /*padding-left: 34px;*/
    position: relative;

    .select {
      /*margin-right: 20px;*/
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
    }

    .tips {
      width: 186px;
      line-height: 30px;
      border-radius: 20px;
      position: absolute;
      left: 254px;
      top: 0px;
      background: #FFF1F0;
      border: 1px solid #FFA8A5;
      color: #756E6E;
      padding-left: 10px;
    }

    .tip {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.45);
      padding-left: 96px;
      margin-top: 10px;
    }

    .ant-upload-list-item-info {
      padding-left: 0px;

      .ant-upload-text-icon {
        display: none;
      }

      .ant-upload-list-item-name {
        padding-left: 0px;
      }
    }
  }

  .upIngFile {
    display: block;
    width: 100%;
    height: 100%;
    padding-top: 35px;

    .title {
      text-align: center;
      margin: 0;
      padding: 0;
      margin-bottom: 20px;

      img {
        margin-top: -3px;
      }

      span {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
        margin-left: 8px;
        margin-top: 2px;
      }
    }

    .progress {
      text-align: center;
      width: 400px;
      margin: 0 auto;

      .ant-progress-inner {
        background-color: #ebebeb;
      }
    }

    .result {
      margin-top: 19px;
      text-align: center;

      span {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;

        &:nth-of-type(1) {
          color: #52C41A
        }

        &:nth-of-type(2) {
          color: #F5222D
        }

        &:nth-of-type(3) {
          color: #1890FF
        }
      }
    }
  }

  .upedFile {
    padding-top: 10px;

    .title {
      text-align: center;
      margin: 0;
      padding: 0;
      margin-bottom: 9px;

      img {
        margin-top: -3px;
      }

      span {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
        margin-left: 8px;
        margin-top: 2px;
      }
    }

    .title1 {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.45);
      margin-bottom: 9px;
      text-align: center;
    }

    .message {
      height: 100px;
      background-color: rgb(235, 235, 235);
      width: 429px;
      /* margin-left: 200px; */
      overflow-y: scroll;
      padding: 15px 20px;
      margin: 0 auto;
    }
  }
}