.signAudit_content {
    padding: 0 16px 16px;
    background: #f5f7fa;
    height: calc(100% - 55px);

    .apply_wrap {
        height: 100%;
        padding: 20px;
        background: #fff;
        overflow: auto;
    }

    .search_wrap_content {
        display: flex;
        justify-content: space-between;
        padding-bottom: 10px;

        .search_left {
            flex-basis: 80%;

            .search_row {
                position: relative;

                .arrow_fold {
                    width: 50px;
                    position: absolute;
                    right: -40px;
                    top: 0;
                    padding-top: 6px;
                    color: #3391F0;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                }

                .search_label {
                    margin-right: 6px;
                }
            }

            .ant-col {
                margin-bottom: 16px;
                min-width: 200px;
            }
        }

        .right_action {
            flex-basis: 200px;
            display: flex;

            grid-gap: 10px;

            gap: 10px;

            .ant-btn {
                width: 90px;
                margin-bottom: 5px;
            }

        }

    }

    .btns_wrap {
        background: #fff;
        padding-bottom: 10px;

        .ant-btn-background-ghost {
            margin-right: 16px;
        }
    }


    .apply_state {
        .state {
            color: #000;
            position: relative;

            .dot {
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background-color: #000;
                display: none;
            }
        }

        .noStart {
            color: #52C41A;

            .dot {
                background-color: #52C41A;
            }
        }


        .running {
            color: #EF822B;
            cursor: pointer;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            line-clamp: 1;
            -webkit-box-orient: vertical;

            .dot {
                background-color: #EF822B;
            }
        }

        .success {
            color: #3391F0;

            .dot {
                background-color: #3391F0;
            }
        }

        .notPass {
            color: #F03333;

            .dot {
                background-color: #F03333;
            }
        }
    }

    .case-audit-checkbox-wrapper {
        .case-audit-text-area {
            margin-top: 5px;
            border-radius: 5px;
            width: 100%;
            min-height: 100px;
            outline: none;
            border-color: #d4d0d0;
        }
    }
}