.addNewProject {
  margin: 0 16px 16px;
  height: calc(100% - 71px);
  background-color: #fff;
  padding: 16px;
  overflow-y: auto;

  .addNewProject_title {
    color: rgba(79, 117, 133, 100);
    font-size: 16px;
    text-align: left;
    font-family: SourceHanSansSC-regular;
  }

  .addNewProject_con {
    padding-left: 18px;
    padding-top: 15px;
  }

  #nest-messages {
    .ant-form-item-label > label {
      font-size: 14px;
    }

    .ant-form-item-label {
      /*text-align: left;*/
    }
  }

  .ant-btn-primary {
    background: #6287EC;
    border-color: #6287EC;
  }
}
