.participants {
  height: 100%;

  .pp_top {
    overflow: hidden;
    background: #fff;
    padding: 20px;

    .selectQueryGroup {
      width: 100%;
      float: left;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .ant-form {
        width: 100%;

        .ant-form-item {
          margin-right: 0px;

          &.editClass {
            .ant-form-item-control-input {
              .ant-input {
                color: #6287EC;
              }
            }

            .ant-select-selection-search input,
            .ant-select-selection-item,
            .ant-input-number-input-wrap input,
            .ant-select-selection-item .ant-select-selection-item-content {
              color: #6287EC;
            }
          }

          .ant-input-number-input-wrap input {
            height: 36px;
            line-height: 36px;
          }
        }
      }

      .ant-select {
        /*margin-right: 10px;*/

        &.ant-select-single {
          .ant-select-selector {
            border-color: #EEEFF7;

            .ant-select-selection-item {
              line-height: 36px;
            }
          }
        }

        .ant-select-selector {
          border-radius: 2px;
          height: 36px;
          border-color: #EEEFF7;

          .ant-select-selection-placeholder {
            line-height: 36px;
          }

          input {
            font-size: 14px;
            color: rgba(16, 16, 16, 100);
            font-family: PingFang SC;
          }
        }

        .ant-select-arrow {
          .anticon {
            > svg {
              vertical-align: middle;
              font-size: 12px;
              margin-top: -6px;
              color: #C3C3C3;
            }
          }
        }
      }

      .submit {
        background-color: rgba(92, 189, 206, 100);
        color: rgba(255, 255, 255, 100);
        text-align: center;
        box-shadow: 0px 0px 1px 0px rgba(170, 170, 170, 100);
        font-family: PingFang SC;
        border: none;
        height: auto;
      }

      .ant-btn.ant-btn-primary.submit,
      .ant-btn:active,
      .ant-btn:focus {
        background-color: #5cbdce;
      }

      .ant-btn.ant-btn-primary {
        background: #6287EC;
        border: 1px solid #6287EC;
      }

      .cancelBtn {
        margin-right: 8px;
        border-color: #6287EC;

        span {
          color: #6287EC;
        }
      }

      .ant-btn:hover,
      .ant-input-search-button:hover,
      .ant-btn-icon-only:hover,
      .ant-btn:active,
      .ant-input-search-button:active,
      .ant-btn-icon-only:active {
        border: 1px solid #6287EC;
        --antd-wave-shadow-color: none;

        &.cancelBtn {
          span {
            /*color: rgba(0, 0, 0, 0.85);*/
            color: #6287EC;
          }
        }
      }
    }
  }

  .pp_content {
    width: 100%;
    min-height: 200px;
    margin-top: 16px;
    background: #fff;
    min-height: 100%;

    .search {
      border-bottom: 1px solid #E9E9E9;
      padding-left: 20px;
      padding-right: 40px;
      line-height: 60px;
      height: 60px;

      .rc_title {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
      }

      .search_group {
        float: right;
      }

      .ant-input-affix-wrapper:hover,
      .ant-input-affix-wrapper-focused {
        border: 1px solid #d9d9d9;
        /*border-right: none;*/
        box-shadow: none;
      }

      .ant-input-group-addon,
      .ant-input-group-addon:hover {
        border-left: none;

        .ant-btn:hover,
        .ant-input-search-button:hover,
        .ant-btn-icon-only:hover,
        .ant-btn:active,
        .ant-input-search-button:active,
        .ant-btn-icon-only:active {
          border: 1px solid #d9d9d9;
          --antd-wave-shadow-color: none;
        }

        .ant-btn,
        .ant-btn:hover,
        .ant-input-search-button:hover,
        .ant-btn-icon-only:hover {
          border-left: none;
        }
      }

      .ant-btn.ant-btn-primary {
        background: #6287EC;
        border: 1px solid #6287EC;
      }
    }

    .itemList {

      .item {
        width: 100%;

        .item_con {
          width: 100%;
          display: flex;
          padding: 13px 15px 13px 15px;
          border-bottom: 1px solid #E9E9E9;

          .con_div {
            justify-content: center;
            vertical-align: center;
            color: #101010;
            font-size: 14px;
          }

          p {
            margin: 0px;
            padding: 0px;
          }

          .c_left {
            width: 40%;
            font-size: 18px;
            overflow: hidden;

            .photo {
              display: inline-block;
              vertical-align: middle;
              width: 36px;
              height: 36px;
              border-radius: 2px;
              background: #597EF7;
              margin-right: 20px;
              position: relative;

              img {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                margin: auto;
                display: inline-block;
                width: 24px;
                height: 24px;
              }
            }

            .c_left_title {
              display: inline-block;
              vertical-align: middle;

              p {
                font-size: 14px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: rgba(0, 0, 0, 0.65);
              }
            }
          }

          .c_left2 {
            width: 10%;
            text-align: center;
            padding-top: 3px;

            p {
              &:first-child {
                margin-bottom: 4px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.45);
              }

              &:last-child {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.45);
              }
            }
          }

          .c_left3 {
            width: 15%;
            text-align: center;
            padding-top: 3px;

            p {
              &:first-child {
                margin-bottom: 4px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.45);
              }

              &:last-child {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.45);
              }
            }
          }

          .c_left4 {
            width: 15%;
            text-align: center;
            padding-top: 4px;

            p {
              &:first-child {
                margin-bottom: 3px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.45);
              }

              &:last-child {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.45);
              }
            }
          }

          .c_right {
            width: 30%;
            text-align: right;
            line-height: 56px;

            .ant-btn-dangerous.ant-btn-primary {
              margin-left: 13px;
            }

            .ant-select {
              width: 170px;
              text-align: left;
              color: #888;
              margin-right: 13px;

              .ant-select-arrow {
                .anticon {
                  > svg {
                    color: #C3C3C3;
                    margin-top: -2px;
                  }
                }
              }
            }

            .submit {
              background-color: rgba(92, 189, 206, 100);
              color: rgba(255, 255, 255, 100);
              text-align: center;
              box-shadow: 0px 0px 1px 0px rgba(170, 170, 170, 100);
              font-family: PingFang SC;
              border: none;
              height: auto;
            }
          }
        }

        &.itemSelected {
          background: #F4F7FF;

          .item_con {
            .c_left .c_left_title,
            .c_left1,
            .c_left2,
            .c_left3,
            .c_left4 {
              p {
                color: #6287EC;
              }
            }
          }
        }
      }

      .ant-table {
        .ant-table-tbody {

          > tr:hover:not(.ant-table-expanded-row) > td, .ant-table-row-hover, .ant-table-row-hover > td {
            background: #fff;
          }

          .ant-table-row.ant-table-row-level-1,
          .ant-table-row.ant-table-row-level-2,
          .ant-table-row.ant-table-row-level-3 {
            background: #FCFCFF;

            .ant-table-cell {
              padding: 8px 16px 8px 16px;

              &:last-child {
                padding-left: 0px;
              }
            }

            &:hover:not(.ant-table-expanded-row) > td, .ant-table-row-hover, .ant-table-row-hover > td {
              background: #FCFCFF;
            }
          }

          .ant-table-cell {

            .ant-select-arrow {
              .anticon {
                > svg {
                  vertical-align: middle;
                  font-size: 12px;
                  margin-top: -6px;
                  color: #C3C3C3;
                }
              }
            }

            &:last-child {
              padding-left: 0px;
            }

            .ant-select-selector {
              border-color: #EEEFF7;

              .ant-select-selection-item {
                /*line-height: 36px;*/
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.25);
              }
            }

            .ant-btn.ant-btn-primary.ant-btn-dangerous {
              background: #fff;
              border-color: #F04864;
              padding: 4px 10px;

              span {
                font-size: 13px;
              }
            }
          }

          .con_div {
            /* justify-content: center;
             vertical-align: center;*/
            color: #101010;
            font-size: 14px;
          }

          p {
            margin: 0px;
            padding: 0px;
          }

          .c_left {
            /*width: 40%;*/
            font-size: 18px;
            overflow: hidden;

            .photo {
              display: inline-block;
              vertical-align: middle;
              width: 36px;
              height: 36px;
              border-radius: 2px;
              background: #597EF7;
              margin-right: 16px;
              position: relative;

              img {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                margin: auto;
                display: inline-block;
                width: 24px;
                height: 24px;
              }
            }

            .c_left_title {
              display: inline-block;
              vertical-align: middle;

              p {
                &:first-child {
                  font-size: 14px;
                  text-align: left;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #000;
                  /*margin-bottom: 3px;*/
                }

                &:last-child {
                  font-size: 12px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: rgba(0, 0, 0, 0.45);
                }
              }
            }
          }

          .c_left2 {
            text-align: left;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.45);

            p {
              &:first-child {
                /*margin-bottom: 3px;*/
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #000;
              }

              &:last-child {
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.45);
              }
            }
          }

          .c_left3 {
            text-align: left;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.45);

            p {
              &:first-child {
                /*margin-bottom: 3px;*/
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: rgba(0, 0, 0, 1);
              }

              &:last-child {
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.45);
              }
            }
          }

          .c_left4 {
            /* width: 10%;*/
            text-align: left;

            p {
              &:first-child {
                /*margin-bottom: 3px;*/
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: rgba(0, 0, 0, 1);
              }

              &:last-child {
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.45);
              }
            }
          }

          .c_right {

            .ant-select {

              &.ant-select-single {
                .ant-select-selector {
                  border-color: #EEEFF7;
                  height: 36px;

                  .ant-select-selection-search {
                    line-height: 36px;

                    input,
                    input::placeholder {
                      line-height: 36px;
                    }
                  }

                  .ant-select-selection-placeholder,
                  .ant-select-selection-item {
                    line-height: 36px;
                  }
                }
              }
            }
          }

          .clickRowStyl {
            background: #F4F7FF;
            color: #6287EC;

            .c_left2,
            .c_left3,
            .c_left4 {
              p {
                color: #6287EC;
              }
            }

            .c_left {
              .c_left_title {
                p {
                  color: #6287EC;
                }
              }
            }

            &:hover:not(.ant-table-expanded-row) > td, .ant-table-row-hover, .ant-table-row-hover > td {
              background: #F4F7FF !important;
            }
          }

          .ant-table-row.ant-table-row-level-1.clickRowStyl,
          .ant-table-row.ant-table-row-level-2.clickRowStyl,
          .ant-table-row.ant-table-row-level-3.clickRowStyl {
            background: #F4F7FF;
          }
        }
      }
    }
  }
  
  // 受试者转移
  .transfer{
    border:1px solid #6287EC;
    padding: 8px 10px;
    border-radius: 3px;
    color: #6287EC;
    margin-right: 10px;
    cursor: pointer;
  }
  .tag{
    color: #F04864;
    font-size: 12px;
  }
}