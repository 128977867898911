@keyframes mBreath {
    from {
        opacity: 100%; 
    }
    to {
        opacity: 60%;
    }
}
.image-canvas_content {
    width: 100%;
    height: 100%;
    position: relative;
    /*
    overflow: auto;
    display: flex;
    align-items: center;
    */
    .image-canvas_main {
        width: 100%;
        height: 100%;
        overflow: auto;
    }
    /*
    ::-webkit-scrollbar {
        width: 0px;
        height: 0px;
    } */
    .image-canvas_main canvas {
    }
        /*
        font-size: 0;
        width: 100%;
        /* background-color: #e9eaec; */
        /*
        height: 50px;
        display: flex;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        */
        .left-area {
            -webkit-user-select: none;
                    user-select: none;
            position: absolute;
            top: 5px;
            left: 5px;
        }
        .right-area {
            text-align: right;
            -webkit-user-select: none;
                    user-select: none;
            position: absolute;
            top: 5px;
            right: 0px;
            .img {
                margin-right: 5px;
            }
            .tuomin-and-cancel {
                display: inline-block;
                .img {
                    margin-right: 5px;
                }
            }
        }
        .img {
            width: 30px;
            height: 30px;
            cursor: pointer;
            display: inline-block;
        }
        .image-canvas_top-main {
            display: flex;
            width: 100%;
        }
    .image-canvas_bottom {
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        color: #fff;
        background-color: #cfd0db;
        width: 100%;
    }
    .image-canvas_bottom > div {
        flex: 1 1;
        font-size: 12px;
        text-align: center;
        align-items: center;
        justify-content: center;
        border: 1px solid #dadada;
        border: none;
        height: 64px;
        display: flex;
        align-items: center;
        .word {
            margin-top: 3.5px;
        }
        .img {
            width: 18px;
            height: 18px;
        }
    }
    .image-canvas_bottom .complete {
        flex: 2 1;
        display: flex;
        .confirm-btn {
            width: 3.53333rem;
            height: 1.85173rem;
            line-height: 1.85173rem;
            background-color: #6287EC;
            border-radius: 2.5px;
        }
    }
    .cancel-tuomin {
        width: 45px;
        height: 45px;
    }
    .breath {
        animation: mBreath 0.6s infinite;
    }
}