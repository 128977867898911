.dctAccount {
    height: calc(100% - 60px);
    background: #f6f7fc;
    padding: 0 16px 16px 16px;
    overflow: auto;
    .tabsTitle {
        width: 100%;
        padding: 16px;
        background-color: #fff;

        .multiple_select {
            margin: 12px 0;
            .all_pass {
                margin-left: 0;
            }
            .all_reject {
                margin-left: 12px;
            }
            .select_count {
                margin-left: 20px;
            }
        }

        .search{
            
            font-size: 14px;
            color: #999;
            .ant-picker-input,
            input::placeholder,
            .ant-select-selection-item{
                font-size: 14px;
            }

            input {
                width: 15%;
                margin-right: 1%;
            }

            .ant-select {
                width: 18%;
                .ant-select-selection-placeholder{
                    font-size: 14px;
                }
            }
            .ant-row{
                .search_label{
                    color: rgba(0, 0, 0, 0.85);
                    display: inline-block;
                    width: 80px !important;
                    text-align: right;
                }
                .search_content{
                    width: calc(100% - 90px);
                }
            }
          
        }
    }

 

}    
.modal-account{
    .demo-click{
        cursor: pointer;
        color: #1890ff;
    }
    .upload_fail_content {
         margin-top: 20px;
        .fail_title {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-bottom: 8px;
            
            img {
                margin-right: 6px;
            }

            span {
                font-weight: bold;
            }
        }

        .fail_tip {
            text-align: center;
            padding-bottom: 10px;
            color: rgba(0, 0, 0, .8);
        }

        .error_list {
            padding-top: 10px;
            border-top: 1px solid rgba(0, 0, 0, .1);
            max-height: 200px;
            overflow-y: auto;

            .error_item {
                color: #333;
                padding-bottom: 4px;
                span{
                    color:red;
                }
            }
        }
    }
}