.all {

  .all_content {
    .listItem {
      margin-bottom: 0px;
      cursor: pointer;

      .ant-card {
        .ant-card-body {
          padding: 12px 10px 11px 15px;

          .card_top {
            .float_set{
              height: 24px;
              margin-bottom: 6px;
            }
            .form_info{
              float: left;
              span {
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #9A9FAB;
              }
              .chat {
                padding: 0 5px;
                margin-left: 5px;
                font-size: 0;
                position: relative;
                i {
                  height: 12px;
                  line-height: 12px;
                  padding: 0 3px;
                  border-radius: 50%;
                  font-size: 10px;
                  font-style: normal;
                  color: #fff;
                  position: absolute;
                  right: -3px;
                  top: -3px;
                  background-color: rgb(231, 14, 14);
                }
              }
            }
            .user {
              float: right;
              span {
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #9A9FAB;
              }

             
            }

            .info {
              font-size: 0;
              margin-top: -3px;

              .left {
                display: inline-block;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                margin-right: 8px;
                position: relative;
                background: rgba(244, 245, 249, 1);
                position: relative;
                vertical-align: middle;

                .anticon {
                  font-size: 20px;

                  svg {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                  }
                }
              }

              .right {
                display: inline-block;
                vertical-align: middle;
                width: calc(100% - 62px);

                p {
                  padding: 0;
                  margin: 0;
                  font-size: 16px;
                  font-family: PingFangSC-Medium, PingFang SC;
                  font-weight: 500;
                  color: #2E2F33;

                  &:nth-of-type(2), &:nth-of-type(3){
                    width: 100%;
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #9A9FAB;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap
                  }
                }
                @media (max-width: 1680px) {
                  .subjectCode{
                    font-size: 14px;
                    width: 100%;
                  }
                }
                @media (max-width: 1400px) {
                  .subjectCode{
                    font-size: 14px;
                    width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap
                  }
                }

                .subjectCode {
                  display: flex;
                  flex-direction: column;
                  height: 34px;
                  justify-content: center;
                  span {
                    line-height: 20px;
                    flex: 1 1;
                    display: flex;
                    align-items: center;
                    &:nth-child(2) {
                      height: 14px;
                      line-height: 14px;
                      font-size: 11px;
                      color: #8d6b0d;
                      display: inline-block;
                    }
                  }
                }

              }
            }
          }

          .group_name{
            margin-top: 5px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #9A9FAB;
          }

          .card_middle {
            margin-bottom: 11px;
            margin-top: 6px;

            p {
              margin: 0;
              padding: 0;
              color: rgba(89, 136, 145, 100);
              font-size: 14px;
              text-align: center;
              font-family: Roboto;
            }

            .progress {
              display: flex;
              font-size: 0;
              flex-wrap: nowrap;
              justify-content: space-between;
              margin-top: 5px;

              span {
                display: inline-block;
                /*width: 16px;*/
                width: 50%;
                height: 5px;
                background: #e6ebf5;
                margin-right: 3px;
                border-radius: 4px;

                &.complete {
                  background: #6c96f7;
                }

                &.running {
                  background: #52C41A;
                }

                &.question {
                  background: #ef8767;
                }

                &.answer {
                  background: #477291;
                }
              }
            }
          }

          .card_bottom {
            margin: 0;
            padding: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: #6c96f7;
            font-size: 12px;
            text-align: left;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;

            &.warn {
              color: #ff8c69;
            }

            &.complete {
              color: #6c96f7;
            }

            &.running {
              color: #52C41A;
            }

            &.lose {
              color: #fa413b;
            }

            &.wait {
              color: #e6ebf5;
            }

            &.answer {
              color: #f7b51e;
            }

            span {
              .anticon {
                margin-right: 6px;
              }
            }
          }

          .card_bottom.gray{
            color: #333;
          }
        }
      }
    }
    .ant-row{
      &>div{
        border: 1px solid #f0f0f0!important;
        background-color: #ffffff;
      }
    }
  }
}
