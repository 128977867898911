.feat-message--main {
  height: 100%;
  flex: 1 1;
  flex-shrink: 0;
  background-color: #F6F7FC;
  display: flex;
  flex-direction: column;
  padding: 8px;
  box-sizing: border-box;
  position: relative;

  .feat-message--spining {
    width: 30px;
    height: 30px;
    position: absolute;
    left: 50%;
    top: 10px;
    margin-left: -15px;
  }

  .feat-message--chat {
    overflow: hidden;
    overflow-y: auto;
    flex: 1 1;
    flex-shrink: 0;
    border-radius: 4px;

    ul {
      height: auto;
      overflow: hidden;
      padding: 8px;
      margin: 0;
    }

    li {
      width: 100%;
      height: auto;
      min-height: 48px;
      overflow: hidden;
      padding-right: 30%;
      padding-top: 18px;
      margin-bottom: 15px;

      .feat-message--avatar {
        width: 48px;
        height: 48px;
        color: #aaa;
        float: left;
        border-radius: 50%;
        background-color: #ededed;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      
      .feat-message--content {
        width: calc(100% - 68px);
        float: left;
        background-color: #fafafa;
        border-radius: 4px;
        padding: 12px;
        margin-left: 18px;
        position: relative;
        box-sizing: border-box;

        &::before {
          content: attr(data-content);
          font-size: 11px;
          color: #898989;
          position: absolute;
          top: -18px;
          left: 0;
        }

        &::after {
          content: '';
          width: 0;
          height: 0;
          border-color: transparent;
          border-width: 10px;
          border-style: solid;
          border-right-color: #fafafa;
          position: absolute;
          left: -20px;
          top: 15px;
        }

        p {
          line-height: 24px;
          font-size: 14px;
          color: #393939;
          margin: 0;
        }
        
        img {
          display: block;
        }
      }
    }

    .mine {
      padding-right: 0;
      padding-left: 30%;
      .feat-message--avatar {
        float: right;
      }
      .feat-message--content {
        margin-right: 18px;
        margin-left: 0;
        &::before {
          left: inherit;
          right: 0;
        }
        &::after {
          border-left-color: #fafafa;
          border-right-color: transparent;
          left: initial;
          right: -20px;
        }
      }
      
    }
  }

  .feat-message--message {
    margin-top: 12px;
    min-height: 42px;
    padding: 12px 12px 7px 12px;
    background-color: #fff;
    border-radius: 4px;
    display: flex;
    position: relative;
    
    .feat-message--readonly {
      position: absolute;
      inset: 0;
      background-color: rgba(255, 255, 255, 0.5);
      cursor: not-allowed;
    }

    .feat-message--file {
      width: auto;
    }

    .feat-message--typing {
      flex: 1 1;
      margin: 0 10px;

      .feat-message--input {
        height: 30px;
      }
    }

    .feat-message--shortcut {
      width: auto;
      margin-right: 5px;
    }

    .feat-message--send {
      width: auto;
    }
  }
}