.project-details{
    background-color: #F6F7FC;
    padding: 0 16px 16px;
    height: calc(100% - 55px);
    overflow-y: auto;
    
    .title-con{
        padding: 15px 20px 10px;
        background: #fff;
        display: flex;
        justify-content: space-between;
        .title{
            font-weight: bolder;
            font-size: 14px;
        }
        .ant-btn-primary{
            background: #6287ec;
            border-color: #6287ec;
        }
    }
    
    .detailContent{
        background-color: #fff;
        padding: 0 16px 0px;
        min-height: calc(100% - 57px);

        .ant-modal-body{
            padding:20px 16px 16px;
        }
        .titleStyle{
            font-size: 13px;
            margin:0 0 8px;
            font-weight: bolder;
            border-left: 3px solid #6287ec;
            line-height: 12px;
            padding-left: 8px;
        }

        .ant-table-tbody td,
        .ant-table-thead th{
            color: #666;
            font-size: 13px;
        }

        /*项目月报概要*/
        .outline{
            margin-bottom: 20px;

            .ant-table-pagination.ant-pagination{
                display: none;
            }
            .ant-table-tbody td,
            .ant-table-thead th{
                padding:8px;
            }
        }
        
        /*项目月报明细*/
        .tab-details{
            .ant-table-tbody td,
            .ant-table-thead th{
                padding:5px 8px;
            }
        }
    }
    
}
