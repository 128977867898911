.operatorModal {
  p{
    margin:0;
  }
  .ant-modal-body,
  .ant-modal-footer {
    background: #F6F7FC;
    border-top: none;
  }

  .ant-modal-body {
    padding: 30px 46px 0px 46px;
  }

  .ant-modal-footer {
    padding: 0px 46px 30px 46px;
  }

  .ant-modal-close-x {
    height: 50px;
    line-height: 50px;

    .anticon {
      font-size: 12px;
    }
  }

  .ant-modal-header {
    padding: 14px 26px;
  }

  .ant-modal-title {
    line-height: 21px;
    font-size: 14px;
    font-weight: 500;
    color: #2E2F33;
  }

  .ant-btn.ant-btn-primary,
  .cancelBtn {
    background: #6287EC;
    border: 1px solid #6287EC;
    color: #fff;
  }

  .ant-btn.ant-btn-primary[disabled] {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    border-color: #d9d9d9;
    box-shadow: none;
  }

  .operateContent{
    .left{
      font-size: 18px;
      .msg{
        font-size: 14px;
        margin-top: 10px;
      }
      .color{
        color: #6287EC;
      }
    }

    .ant-select-arrow{
      top: 15px;
    }
    
    .ant-radio-button-wrapper:hover{
      color: #6287EC;
    }

    .ant-radio-button-wrapper-checked:hover,
    .ant-radio-button-wrapper-checked{
      background-color: #6287EC;
      border-color: #6287EC;
      
    }
    .ant-radio-button-wrapper-checked:hover{
      color: #fff;
    }
    .ant-form-item:last-of-type{
      margin-bottom: 0;
      padding-bottom: 25px;
    }
  }
  
  .txtStyle{
    color: orangered;
    font-size: 13px;
    margin-top: 10px;
    height: 13px;
    line-height: 13px;
    margin-left: 25%;
  }
  .submit{
    width: 100%;
    padding-bottom: 30px;
    text-align: right;
    .ant-btn{
      height: 36px;
    }
  }
}