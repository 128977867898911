.crfForm {
  /*padding: 15px 0px 0px 0px;*/
  height: calc(100% - 40px);
  overflow-y: scroll;
  margin: 0 16px 16px;
  background-color: #fff;

  .searchItem {
    margin: 10px;
    height: 64px;
    box-shadow: 0px 0px 3px 0px rgba(187, 187, 187, 100);
    border: 1px solid rgba(255, 255, 255, 100);

    .searchRow {
      width: 100%;
      display: flex;
      align-items: center;

      .ant-form-item-label label {
        font-size: 13px;
        text-align: right;
        color: rgba(92, 136, 145, 100);
      }

      .ant-form-item-control input {
        width: 150px;
        height: 25px;
        border-radius: 3px;
      }

      .ant-form-item-control .ant-select-single {
        width: 150px;
        height: 25px;
        border-radius: 3px;

        .ant-select-selector {
          height: 100%;
        }

        .ant-select-arrow svg {
          vertical-align: middle;
          font-size: 12px;
          margin-top: -6px;
          color: #C3C3C3;
        }
      }

      .ant-form-item-control .ant-btn {
        background: #fff;
        width: 80px;
        border-radius: 4px;
        color: rgba(16, 16, 16, 100);
        font-size: 14px;
        text-align: center;
        border: 1px solid rgba(187, 187, 187, 100);
      }

    }
  }

  .table-list {
    margin: 0px 10px 10px;
  }
}