.cso_doctor_audit_research_container {
    margin: 0 16px;
    background-color: #fff;
    height: calc(100% - 59px);
    overflow-y: scroll;
    padding-top: 0;
    .txc {
        text-align: center;
    }
    .name_verify {
        margin-left: 18px;
    }
    .role_verify {
        margin-left: 10px;
    }
    > div {
        padding-left: 16px;
        padding-right: 16px;
    }
    .introduce {
        display: flex;
        padding-top: 20px;
        padding-bottom: 21px;
        > div {
            flex: 1 1;
            text-align: center;
            border-right: #E4E4E4 1px solid;
            &:last-child {
                border-right: none;
            }
            > div:nth-child(1) {
                color: #9A9A9A;
                font-size: 16px;
            }
            > div:nth-child(2) {
                color: #3D3D3D;
                font-size: 20px;
                font-weight: bold;
                margin-top: 16px;
            }
        }
    }
    .gray {
        color: #959595;
    }
    .gap {
        width: 100%;
        height: 16px;
        background-color: #F3F5F9;
    }
    .search {
        display: flex;
        justify-content: space-between;
        background-color: #fff;

        .search_con {
            flex: 1 1;
            display: flex;
            flex-wrap: wrap;

            .search_item {
                margin-right: 30px;
                display: flex;
                align-items: center;
                padding-top: 16px;

                .label {
                    width: 70px;
                    display: inline-block;
                    text-align: right;
                    margin-right: 8px;
                }
            }

        }

        .search_btn {
            display: flex;
            margin-left: 27px;
            margin-right: 27px;
            padding-top: 16px;
        }
    }

    .cpds_content {
        padding-top: 22px;


        .progress {
            text-align: center;

            .status {
                display: flex;

                span {
                    flex: 1 1;
                    color: rgba(51, 51, 51, 0.45);
                }
            }
        }

        .block_list {
            display: flex;
            padding: 0 30px;


            .block {
                min-width: 22px;
                height: 22px;
                border-radius: 50%;
                margin-left: 60px;
                position: relative;

                &:first-child {
                    margin-left: 0;

                    &::after {
                        display: none;
                    }

                    .time {
                        display: none;
                    }
                }

                &::after {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: -60px;
                    content: "";
                    width: 60px;
                    height: 1px;
                    border-bottom: 2px dashed #000;
                }



                .time {
                    position: absolute;
                    top: -10px;
                    left: -60px;
                    width: 60px;
                    text-align: center;
                }
            }



            .success {
                background-image: url('../../../static/csoImages/success.svg');
                background-size: 100% 100%;

                &::after {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: -60px;
                    content: "";
                    width: 60px;
                    height: 1px;
                    border-bottom: 2px solid #3391F0;
                }

                .time {
               
                    color: #3391F0;
                }
            }

            .error {
                background-image: url('../../../static/csoImages/error.svg');
                background-size: 100% 100%;

                &::after {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: -60px;
                    content: "";
                    width: 60px;
                    height: 1px;
                    border-bottom: 2px dashed #FE1805;
                }

                .time {
                    color: #FE1805;
                }
            }

            .wait {
                background-image: url('../../../static/csoImages/wait.svg');
                background-size: 100% 100%;

                &::after {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: -60px;
                    content: "";
                    width: 60px;
                    height: 1px;
                    border-bottom: 2px dashed #00b40d;
                }

                .time {
                    color: #00b40d;
                }
            }

            .notStarted {
                background-image: url('../../../static/csoImages/notStarted.svg');
                background-size: 100% 100%;

                &::after {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: -60px;
                    content: "";
                    width: 60px;
                    height: 1px;
                    border-bottom: 2px dashed #D8D8D8;
                }

                .time {
                    color: #D8D8D8;
                }
            }


        }

    }
    .role_verify {
        position: relative;
        &::after {
            position: absolute;
            left: -10px;
            top: 50%;
            transform: translateY(-50%);
            content: '';
            width: 7px;
            height:7px;
            border-radius: 3.5px;
        }
    }
    .pass {
        color: #00B011;
        &::after {
            background-color: #00B011;
        }
    }
    .no_pass {
        color: #FF2727;
        &::after {
            background-color: #FF2727;
        }
    }
    .to_audit {
        color: #3391F0;
        &::after {
            background-color: #3391F0;
        }
    }
}


.cso_doctor_audit_resource_modal {
    .ant-modal-body {
        padding: 41px;
    }
    .modal_content {
        max-height: 70vh;
        overflow-y: auto;

        .menu {
            padding-bottom: 14px;

            .title {
                font-size: 14px;
                color: #3391F0;
                display: flex;
                align-items: center;
                justify-content: space-between;
                img {
                    width: 16px;
                    height: 16px;
                    margin-right: 8px;
                }
                .pointer{
                    cursor: pointer;
                    display: none;
                }
            }
            .title_left {
                display: flex;
                align-items: center;
            }

            .menu_content {
                padding-top: 17px;
                display: flex;
                flex-wrap: wrap;

                .info_item {
                    flex-basis: 50%;
                    display: flex;
                    color: #333;
                    padding-bottom: 16px;
                    .info_label {
                        width: 100px;
                        flex-shrink: 0;
                    }

                    .widthAuto {
                        width: auto;
                    }

                    .text_right {
                        text-align: right;
                    }
                    .required {
                        position: relative;
                        &::before  {
                            display: inline-block;
                            position: absolute;
                            content: '*';
                            color: #F03333;
                            top: 50%;
                            transform: translateY(-50%);
                            margin-left: -8px;
                        }
                    }
                    .info {

                        .block_list {
                            display: flex;
                            padding: 0 30px;


                            .block {
                                min-width: 22px;
                                height: 22px;
                                border-radius: 50%;
                                margin-left: 60px;
                                position: relative;

                                &:first-child {
                                    margin-left: 0;

                                    &::after {
                                        display: none;
                                    }

                                    .time {
                                        display: none;
                                    }
                                }

                                &::after {
                                    position: absolute;
                                    top: 50%;
                                    transform: translateY(-50%);
                                    left: -60px;
                                    content: "";
                                    width: 60px;
                                    height: 1px;
                                    border-bottom: 2px dashed #000;
                                }
                                .time {
                                    width: 60px; 
                                    position: absolute;
                                    top: -10px;
                                    left: -60px;
                                    text-align: center;
                                    font-size: 12px;
                                }

                                .name{
                                    width: 30px;
                                    position: absolute;
                                    bottom: -28px;
                                    left: 0px;
                                }
                            }
                            .success {
                                background-image: url('../../../static/csoImages/success.svg');
                                background-size: 100% 100%;

                                &::after {
                                    position: absolute;
                                    top: 50%;
                                    transform: translateY(-50%);
                                    left: -60px;
                                    content: "";
                                    width: 60px;
                                    height: 1px;
                                    border-bottom: 2px solid #3391F0;
                                }

                                .time {
                                    position: absolute;
                                    top: -10px;
                                    left: -60px;
                                    text-align: center;
                                    color: #3391F0;
                                }
                                .name{
                                    color: #3391F0;
                                }
                            }

                            .error {
                                background-image: url('../../../static/csoImages/error.svg');
                                background-size: 100% 100%;

                                &::after {
                                    position: absolute;
                                    top: 50%;
                                    transform: translateY(-50%);
                                    left: -60px;
                                    content: "";
                                    width: 60px;
                                    height: 1px;
                                    border-bottom: 2px dashed #FE1805;
                                }

                                .time {
                                
                                    color: #FE1805;
                                }
                                .name{
                                    color: #FE1805;
                                }
                            }

                            .wait {
                                background-image: url('../../../static/csoImages/wait.svg');
                                background-size: 100% 100%;

                                &::after {
                                    position: absolute;
                                    top: 50%;
                                    transform: translateY(-50%);
                                    left: -60px;
                                    content: "";
                                    width: 60px;
                                    height: 1px;
                                    border-bottom: 2px dashed #00b40d;
                                }

                                .time {
                            
                                    color: #00b40d;
                                }
                                .name{
                                    color: #00b40d;
                                }
                            }

                            .notStarted {
                                background-image: url('../../../static/csoImages/notStarted.svg');
                                background-size: 100% 100%;

                                &::after {
                                    position: absolute;
                                    top: 50%;
                                    transform: translateY(-50%);
                                    left: -60px;
                                    content: "";
                                    width: 60px;
                                    height: 1px;
                                    border-bottom: 2px dashed #D8D8D8;
                                }

                                .time {
                                    position: absolute;
                                    top: -10px;
                                    left: -30px;
                                    color: #D8D8D8;
                                }
                                .name{
                                    color: rgba(51,51,51,0.45);
                                }
                            }
                        }
                    }
                }
            }

        }

    }
    .modal_footer {
        display: flex;
        align-items: center;
        .footer_left {
            flex: 1 1;
            text-align: left;
            margin-left: 8px;
            > img {
                width: 16px;
                height: 16px;
                margin-right: 8px;
                margin-bottom: 3px;
            }
            > span {
                line-height: 1;
                color: #3391F0;
                font-size: 16px;
            }
        }
    }
    .info {
        margin-left: 30px;
        position: relative;
        &::after {
            position: absolute;
            left: -10px;
            top: 50%;
            transform: translateY(-50%);
            content: '';
            width: 7px;
            height:7px;
            border-radius: 3.5px;
        }
    }
    .pass {
        color: #00B011;
        &::after {
            background-color: #00B011;
        }
    }
    .no_pass {
        color: #FF2727;
        &::after {
            background-color: #FF2727;
        }
    }
    .to_audit {
        color: #3391F0;
        &::after {
            background-color: #3391F0;
        }
    }
    .ant-modal-footer {
        button:first-child {
            color: #EA0000;
            border-color: #EA0000;
        }
    }
    .no-data {
        color: #959595;
    }
}

.cso_doctor_audit_order_record_modal_wrap {
    .modal_content {
        max-height: 70vh;
        overflow-y: auto;
    }
    .title {
        display: flex;
        align-items: center;
    }
    .title_text {
        font-size: 16px;
        margin-left: 10px;
        font-weight: bold;
    }
    .item {
        display: flex;
        margin-top: 10px;
    }
    .item_left {
        width: 70px;
        margin-right: 10px;
    }
    .item_right {
        flex: 1 1;
    }
    .check_group {
        > label {
            display: block;
            > span {
                display: inline-block;
            } 
        }
    }
}
