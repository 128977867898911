.settlementMange {
    padding: 0 16px 16px;
    height: calc(100% - 150px);

    .content_wrap {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
    }

    .search_wrap {
        text-align: left;
        margin-bottom: 20px;
        display: flex;
        background-color: #fff;
        padding: 16px;
        align-items: baseline;
        .search_con {
            flex: 1 1;
            display: flex;
            flex-wrap: wrap;

            .search_item {
                margin-right: 30px;
                display: flex;
                align-items: center;
                padding-top: 16px;

                .label {
                    width: 60px;
                    display: inline-block;
                    text-align: right;
                    margin-right: 8px;
                }
            }

        }

        .searchBtn {
            display: flex;
            justify-content: flex-end;
        }
    }

    .content_table {
        width: 100%;
        background-color: #fff;
        padding: 16px;
    }

    .ant-btn.ant-btn-link {
        font-size: 13px;
    }

    .partPop {
        text-align: left;

        .ant-btn.ant-btn-link {
            padding-left: 0;
        }

        .ant-btn.ant-btn-primary[disabled] {
            color: rgba(0, 0, 0, 0.25);
            background: #f5f5f5;
            border-color: transparent;
            box-shadow: none;
        }
    }

    .ant-table-pagination.ant-pagination {
        padding: 16px 0 0;
        background: #fff;
        margin: 0;
    }

    .con_div {
        text-align: left;
        font-size: 13px;

        .title {
            color: #000;
            margin-bottom: 5px;
        }

        .sub-title {
            color: rgba(0, 0, 0, 0.45);
        }

        .yellow {
            color: orange;
        }
    }

    .current_state {
        .ant-btn {
            height: auto;
            line-height: auto;
            padding: 2px 7px;
            border: none;
            border-radius: 5px;
            font-size: 13px !important;
            background-color: transparent;
        }

        .ant-btn-link {
            color: #1890ff;
        }

        .ant-btn-text {
            color: #999;
        }
    }
}

.tooltip_text {
    color: red;
    margin-bottom: 20px;
}