.dctTasks_content {

    .top_card{
        min-height: 256px;
        height: 256px;
        margin-bottom: 16px;
        background-color: #fff;
        overflow-y: auto;

        .more{
            font-size: 12px;
            color: #999;
            cursor: pointer;
        }
    }

    .ant-list-item-meta-avatar{
        width: 45px;
        margin-right: 10px;

        .ant-tag{
            display: inline-block;
            width: 100%;
            text-align: center;
        }

      .ant-tag-default,
      .ant-tag-processing,
      .ant-tag-purple,
      .ant-tag-error,
      .ant-tag-warning{
          border-color: transparent;
      }

      .ant-tag-default{
          background-color: #f4f4f4;
      }
    }

    .ant-list-item{
        border-color: transparent;
        padding: 3px 0;
      }

    .ant-list-item-meta-title{
        margin-bottom: 0;
        font-size: 12px;
        line-height: 16px;
      }

      .ant-card-head{
        min-height: auto;
        border-color: transparent;
      }

    .ant-card-head-title{
        font-weight: bolder;
        font-size: 14px;
        padding: 10px 0 0px;
    }

    .ant-card-extra{
        padding: 10px 0 0px;
    }
    
    .ant-card-body{
        padding-top: 10px;
        padding-bottom: 18px;
    }

    .timeTxt{
        font-size: 12px;
        color: #888;
    }
  }