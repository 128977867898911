.subModal {
  .ant-modal-close-x {
    height: 50px;
    line-height: 50px;

    .anticon {
      font-size: 12px;
    }
  }

  .ant-modal-header {
    padding: 14px 26px;
  }

  .ant-modal-title {
    font-family: PingFangSC-Regular, PingFang SC;
    line-height: 21px;
    font-size: 14px;
    font-weight: 500;
    color: #2E2F33;
  }

  .ant-btn.ant-btn-primary {
    background: #6287EC;
    border: 1px solid #6287EC;
    color: #fff;

    /*span {
      &:last-child {
        margin-left: 5px;
      }
    }*/
  }

  .ant-modal-body {
    padding: 30px 46px;

    .ant-form-item {
      margin-bottom: 20px;
    }

    .ant-form-item-label {
      > label {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.65);
      }
    }

    .ant-radio-group {
      > label {
        span {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.65);
        }

        .ant-radio-checked {
          .ant-radio-inner {
            border-color: #6287EC;
          }
        }
      }
    }
  }

  .ant-modal-body,
  .ant-modal-footer {
    background: #F6F7FC;
    border-top: none;
  }

  .ant-modal-footer {
    padding: 0px;
  }
}