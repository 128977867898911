.am-list-item {
    .am-list-line {
        .am-list-content {
            white-space: normal !important;
            font-size: 13px;
        }
    }
}

.mobile_filed {

    &>.formItem {
        padding: 0 10px;

        & .title {
            margin: 10px 0px;
            text-align: left;
            font-size: 14px !important;
            color: #000000;
            line-height: 20px;
            border-left: 6px solid #6c96f7;
            padding-bottom: 0 !important;
            border-bottom: none !important;
        }


        & .field {
            border-bottom: 1px solid #F3F5F9;

            & .content_has_photo {
                border-bottom: none !important;
            }

            & .content_has_video {
                border-bottom: none !important;
            }

            & .field_content {
                width: 100%;
                background-color: #ffffff;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                position: relative !important;

                & .field_label {
                    font-size: 13px;
                    line-height: 40px;
                    color: #2E2F33;
                    padding-left: 10px;
                    margin-right: auto;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    min-width: 130px;
                    /*max-width: 70%;*/
                }

                & .field_label_remark {
                    color: #9C9D9D;
                    font-size: 12px;
                }

                & .text_field_label {
                    white-space: normal;
                    line-height: 20px;
                    padding: 0.8em 1.6em 1.6em 1.6em;
                    color: #9C9D9D;
                    font-size: 11px;
                    background-color: #f5f5f9;
                    width: 100%;
                }

                & .field_right {
                    padding-right: 10px;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    word-break: break-all;
                    min-width: 92px;
                    max-width: 45%;

                    & .defaultValue {
                        color: #9C9D9D;
                        font-size: 12px;
                        padding: 6px 10px 6px 10px;
                        padding-right: 5px;
                        white-space: revert;
                        text-align: right;
                        max-width: 100%;
                    }
                }

                & .field_annotation {
                    height: 18px;
                    width: 18px;
                    border: 1px solid red;
                    border-radius: 50%;
                    color: red;
                    font-size: 14px;
                    line-height: 18px;
                    margin: 0 0 0 10px;
                    text-align: center;
                    position: relative;
                    z-index: 10;
                }
            }

            & .addNextList {
                font-size: 12px;
                line-height: 40px;
                border-radius: 10px;
                margin: 0 10px;
                color: black;
                display: flex;
                align-items: center;
                justify-content: center;

                & img {
                    width: 15px;
                    height: 15px;
                }
            }

            & .necessary {
                color: red;
                padding-right: 4px;
            }

            & .am-list-content {
                font-size: 12px;
                color: #5C8891;
            }

            & .am-input-label {
                font-size: 12px;
                color: #5C8891;
            }

            & .am-textarea-label {
                font-size: 12px;
                color: #5C8891;
            }

            & .am-list-extra {
                font-size: 12px;
                color: #9C9D9D;
            }



            & .IconAnswer {
                width: 18px;
                height: 18px;
                display: inline-block;
                margin-top: 3%;
            }

        }


    }


    //模态框
    .closeModal {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-content: center;
        border-bottom: 1px solid rgb(146, 143, 143);
        color: black;

        & div {
            width: calc(100% - 32px);
            text-align: center;
            padding-left: 32px;
            font-size: 16px;
            line-height: 40px;
        }

        & img {
            width: 18px;
            height: 18px;
            margin: 10px;
        }
    }

    .modalContent {
        width: 100%;
        overflow: scroll;
        padding: 10px;
        padding-top: 0;
        box-sizing: border-box;

        & .label_content {
            display: flex;
            align-content: center;
            margin-top: 10px;

            & span {
                font-size: 14px;
                padding: 2px 6px;
                margin-right: 10px;
                border: 1px solid #11B5D2;
                border-radius: 6px;
                color: #2B4B59;

                & img {
                    width: 16px;
                    height: 16px;
                    vertical-align: text-top;
                }
            }
        }

        & .field_content {
            width: 100%;

            & .noteBtn {
                display: flex;
                border: 1px solid #11B5D2;
                border-radius: 6px;
                justify-content: flex-start;

                & .noteItem {
                    flex: 1 1;
                    font-size: 14px;
                    text-align: center;
                    line-height: 26px;
                    border-left: 1px solid #11B5D2;
                    color: #11B5D2;
                    background-color: #ffffff;
                    white-space: nowrap;
                }

                & .active {
                    background-color: #11B5D2;
                    color: #ffffff;
                }

                & .noteItem:first-child {
                    border-left: 0;
                    border-top-left-radius: 6px;
                    border-bottom-left-radius: 6px;
                }

                & .noteItem:last-child {
                    border-top-right-radius: 6px;
                    border-bottom-right-radius: 6px;
                }
            }

            & .am-input-item {
                width: 100%;
            }

            & .field_form {
                margin-top: 20px;
                border: 1px solid #6287EC;
                padding: 2px 4px;
                border-radius: 6px;
                display: flex;
                align-items: center;
                justify-content: flex-start;

                & .field_unit {
                    margin-left: auto;
                    padding: 0 6px;
                    white-space: nowrap;
                    text-align: right;
                }

                & .inputrange_divider {
                    width: 2px;
                    height: 20px;
                    background: #ddd;
                }

                & .field_annotation {
                    height: 18px;
                    width: 22px;
                    border: 1px solid red;
                    border-radius: 50%;
                    color: red;
                    font-size: 14px;
                    line-height: 17px;
                    margin: 0 5px;
                }
            }

            & .field_noFlex {
                margin-top: 20px;
                border: 1px solid #6287EC;
                padding: 2px 4px;
                border-radius: 6px;
                overflow: hidden;

                & .am-list-content {
                    min-width: 80%;
                }
            }
        }

        & .saveBtn {
            display: flex;
            justify-content: flex-end;
            margin-top: 40px;
            margin-bottom: 20px;

            & span {
                padding: 4px 16px;
                font-size: 14px;
                background-color: #6287EC;
                color: white;
                border-radius: 14px;
                display: flex;
                align-items: center;

                & img {
                    width: 12px;
                    height: 14px;
                    margin-right: 6px;
                }
            }
        }

        //质疑部分
        .question_content {
            padding: 4px;

            & .questionList {
                & .questionItem {
                    padding: 10px 6px;
                    border-top: 1px dashed gray;

                    & .questionItemTop {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;

                        & .questionItem_left {
                            & .questionItem_left_top {
                                color: #2A344D;
                                font-size: 12px;
                                display: flex;
                                line-height: 16px;
                                display: flex;
                                align-items: center;

                                & img {
                                    margin-right: 4px;
                                    width: 14px;
                                    height: 14px;
                                }

                                & .question_text {
                                    white-space: nowrap;
                                }

                                & .questionContent {
                                    text-align: left;
                                }
                            }

                            & .questionItem_left_bottom {
                                margin-left: 20px;
                                text-align: left;
                                color: #2A344D;
                                font-size: 10px;
                                display: flex;
                                align-items: center;

                                & img {
                                    width: 14px;
                                    height: 14px;
                                    margin-right: 2px;
                                }
                            }

                        }

                        & .questionItem_right {
                            margin-left: auto;
                            display: flex;

                            & .answer_btn {
                                min-width: 54px;
                                height: 16px;
                                background-color: #6287EC;
                                color: #ffffff;
                                font-size: 10px;
                                border-radius: 6px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                padding: 2px 4px;
                                margin-left: 10px;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                overflow: hidden;

                                & span {
                                    margin-right: 4px;
                                }

                                & img {
                                    width: 10px;
                                    height: 10px;
                                    margin-right: 4px;
                                }
                            }
                        }
                    }

                    & .questionAction_content {
                        & .questionAction_content_taps {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            height: 30px;
                            margin-top: 10px;

                            & .questionAction_content_tapItem {
                                padding: 2px 4px;
                                color: #6287EC;
                                font-size: 12px;
                                border-right: 1px solid #6287EC;
                                border-top: 1px solid #6287EC;
                                border-bottom: 1px solid #6287EC;
                            }

                            & .questionAction_content_tapItem:first-child {
                                border-top-left-radius: 4px;
                                border-bottom-left-radius: 4px;
                                border-left: 1px solid #6287EC;
                            }

                            & .questionAction_content_tapItem:last-child {
                                border-top-right-radius: 4px;
                                border-bottom-right-radius: 4px;
                            }
                        }

                        & .form_content {
                            & .input_item {
                                border: 1px solid #6287EC;
                                border-radius: 4px;
                                padding: 4px;
                                padding-right: 16px;
                                margin-top: 10px;
                            }

                            & .form_content_action {
                                display: flex;
                                justify-content: flex-end;
                                margin-top: 10px;

                                & .cancel_btn {
                                    color: #6287EC;
                                    border: 1px solid #6287EC;
                                    padding: 2px 14px;
                                    border-radius: 6px;
                                    font-size: 12px;

                                    & span {
                                        margin-right: 2px;
                                    }
                                }

                                & .save_btn {
                                    color: #ffffff;
                                    background-color: #6287EC;
                                    padding: 2px 14px;
                                    margin-left: 10px;
                                    border-radius: 6px;
                                    font-size: 12px;
                                    border: 1px solid #6287EC;

                                    & span {
                                        margin-right: 2px;
                                    }
                                }
                            }
                        }
                    }


                }

                & .questionItem:first-child {
                    border-top: 0;
                }
            }

            & .question_title {
                text-align: left;
                font-size: 16px;
                color: #000000;
                line-height: 20px;
                border-left: 6px solid #6287EC;
                margin: 8px 0;
            }

            & .question {
                border: 1px solid #6287EC;
                padding: 4px;
                border-radius: 6px;

                & .questionForm_Item {
                    border-top: 1px dashed #6287EC;

                    & .am-list-content {
                        color: #2E2F33;
                        font-size: 14px;
                    }

                    & .am-textarea-label {
                        color: #2E2F33;
                        font-size: 14px;
                    }

                    & .am-list-extra {
                        font-size: 14px;
                        color: #2A344D;
                    }

                    & textarea {
                        color: #2A344D;
                    }
                }

                & .questionForm_Item:first-child {
                    border-top: 0;
                }

                textarea::-webkit-input-placeholder {
                    /*WebKit browsers*/
                    font-size: 14px;
                    text-align: right;
                    color: #2A344D !important;
                    padding-right: 24px;
                }

                textarea::-moz-input-placeholder {
                    /*Mozilla Firefox*/
                    font-size: 14px;
                    text-align: right;
                    color: #2A344D !important;
                    padding-right: 24px;
                }

                textarea::-ms-input-placeholder {
                    /*Internet Explorer*/
                    font-size: 14px;
                    text-align: right;
                    color: #2A344D !important;
                    padding-right: 24px;
                }
            }
        }

        //痕迹稽查部分
        & .traceTable {
            margin-top: 10px;

            & table {
                border-radius: 5px;

                & th,
                td {
                    text-align: center;
                    font-family: Open Sans, sans-serif;
                    font-size: 0.2rem;
                    font-weight: 400;
                    line-height: 22px;
                    color: #192038;
                }

                & thead {
                    & tr:first-child {
                        background-color: #6287EC;
                        color: #ffffff;

                        & th {
                            color: #ffffff;
                        }
                    }
                }

                & tbody {
                    & tr:nth-child(2n) {
                        background-color: #eff2fb;
                    }
                }
            }
        }
    }

    ion-modal {
        z-index: 999 !important;
    }
}