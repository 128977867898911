ul, li {
  list-style: none;
}

/*header 开始*/
.headerNav {
  width: 77%;
  float: left;
  height: 100%;
  /*background: #5CBDCE 10000%;*/
  background: #3391F0 10000%;

  .ant-menu {
    color: #98AFB3;
  }

  .ant-tabs {
    color: #D5EAFF;
  }

  .ant-tabs-tab .ant-tabs-tab-btn {
    padding: 0px 5px !important;
    /*border-right: 1px solid #fff;*/
  }

  .ant-tabs-ink-bar {
    height: 2px !important;
    background-color: #fff;
    border-radius: 2px;
  }

  .ant-tabs-tab {
    font-size: 14px;
    /*margin-right: 5px !important;*/
    line-height: 1;
    height:56px;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #fff;
  }

  .ant-tabs-tab-active:after {
    background-color: #fff;
    color: #fff
  }

  .ant-tabs-tab:hover,
  .ant-tabs-tab:active,
  .ant-tabs-tab-btn:active,
  .ant-tabs-tab:link,
  .ant-tabs-tab:visited {
    color: #fff
  }

}

.headerItems {
  width: 100%;
  overflow: hidden;
  margin-bottom: 0px;
  padding-left: 0px;
  display: table;

  li {
    float: left;
    line-height: 56px;
    display: table-cell;
    position: relative;
  }

  .ant-tabs-tab .anticon {
    margin-right: 6px;
  }

  /*span:first-child {
    color: rgba(255, 255, 255, 100);
    font-size: 16px;
    text-align: center;
    font-family: Roboto;
    margin-bottom: 0px;
    padding: 0px 20px;
    border-right: 1px solid #fff;
  }*/
}

/*header 结束*/

/*menu 开始*/

.menuNav {
  display: inline-block;
  /*width: 200px;*/
  height: 100%;

  .menu_top {
    /*width: 100%;*/
    width: 200px;
    height: 56px;
    background: #3391F0;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: -6px 1px 6px #f2f2f2;

    .menu_photo {
      width: 34px;
      height: 34px;
      margin-right: 5px;
      position: relative;

      img {
        position: absolute;
        left: 0;
        top: 0;
        width: 34px;
        height: 34px;
      }
    }

    .menu_name {
      font-size: 16px;
      color: #fff;
      margin-right: 15px;
    }
    .collapsed-icon{
      color: #fff;
      padding: 0 5px 0 0;
      cursor: pointer;
    }
  }

  .menu_bottom {
    height: calc(100% - 56px);
    /*box-shadow: 0px 0px 3px 0px rgba(187, 187, 187, 100);*/
    background-color: #fff;

    .ant-menu-item {
      font-size: 16px;
      color: #98AFB3;
      padding-left: 25px !important;
    }

    .ant-menu-submenu {
      .ant-menu-submenu-title {
        font-size: 14px;
        color: #98AFB3;

        span {
          font-size: 14px;
          color: #98AFB3;
        }
      }

      .ant-menu-submenu-arrow {
        display: none;
      }
    }

    /*.ant-menu-submenu-open {
      background-color: #ECEEF7;

      .ant-menu-submenu-title {
        span {
          font-size: 16px;
          color: #5CBDCE;
        }
      }
    }*/

    .ant-tree .ant-tree-treenode {
      /*height: 34px;
      line-height: 34px;*/
      width: 100%;
      padding-bottom: 0px;
      /*padding-top: 4px;*/
    }

    .ant-tree .ant-tree-node-content-wrapper {
      background-color: rgba(168, 236, 236, 0.0);
      display: inline-block;
      width: calc(100% - 25px);

      .ant-tree-title {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;
        display: inline-block;
        vertical-align: top;
      }

      &.ant-tree-node-selected {
        color: #3391F0;
        background-color: #E7F3FF;

        .ant-tree-title {
          color: #3391F0;
        }
      }
    }

    .ant-menu-dark .ant-menu-inline.ant-menu-sub {
      background-color: red;
    }

    .ant-tabs-top > .ant-tabs-nav::before {
      border-bottom: none;
    }

    #tabTree {
      .ant-tabs-tab {
        &:nth-of-type(6) {
          .ant-tabs-tab-btn {
            border-right: none;
          }
        }
      }

    }

    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background-color: #E7F3FF;
      color: #3391F0;
    }

    .ant-menu-item-selected {
      background-color: #E7F3FF;
      color: #3391F0;
    }

    .ant-menu-inline .ant-menu-item::after {
      left: 0px;
      right: auto;
     /*border-right: 3px solid #3391F0;*/
     border-right: none;
    }

    .ant-menu-inline{
      border-right: none;
    }

    .ant-menu-item span,
    .ant-menu-item .ant-menu-item-icon,
    .ant-menu-submenu.ant-menu-submenu-inline span,
    .ant-menu-submenu.ant-menu-submenu-inline .ant-menu-item-icon {
      font-size: 14px;
      padding-top: 2px;
      padding-bottom: 2px;
    }
    
    .ant-menu-submenu-active {
      background-color: #E7F3FF;
    }

    .ant-menu-item:hover,
    .ant-menu-item:active,
    .ant-menu-item:link,
    .ant-menu-item:visited {
      color: #98AFB3;
    }

    .ant-menu-submenu > .ant-menu,
    .ant-tree {
      background-color: rgba(168, 236, 236, 0.1);
    }

    .ant-tree-show-line .ant-tree-switcher {
      background-color: rgba(168, 236, 236, 0.0);
    }

    .menuNav .ant-tree-title {
      font-size: 14px !important;
      color: #98afb3;
    }

    .ant-tree-list {
      padding-left: 20px;
      background-color: #E7F3FF;
    }

  }
  
  .collapsed_menu{
    height: 50px;
  }

  .ant-tree-title {
    font-size: 16px;
    color: rgba(152, 175, 179, 100);
  }

  .ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content, 
  .ant-menu-inline.ant-menu-root .ant-menu-submenu-title > .ant-menu-title-content {
    font-size: 14px; 
  }
}

/*menu 结束*/

/*crumbs 开始*/

.crumbs {
  width: 100%;
  /*height: 40px;*/
  /*padding: 0px 26px 15px 26px;*/
  /*line-height: 40px;*/
  padding: 16px;

  .crumbs_icon {
    display: inline-block;
    /*margin-right: 13px;*/
  }

  .crumbs_nav {
    display: inline-block;
    /*padding-top: 22px;*/

    .ant-breadcrumb {
      .first_item {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.45);
      }

      .ant-breadcrumb-link {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.65);

        a {
          font-size: 14px;
          color: rgba(0, 0, 0, 0.65);
        }
      }

      span {
        &:first-child {
          .ant-breadcrumb-link {
            a {
              color: rgba(0, 0, 0, 0.45)
            }
          }
        }

        &:nth-of-type(2) {
          .ant-breadcrumb-link {
            a {
              color: #6287ec
            }
          }
        }
      }
    }

    .ant-breadcrumb-separator {
      color: #B2C1C3;
    }

    .refresh{
      color: #6287ec;
      cursor: pointer;
    }
  }
}


/*crumbs 结束*/