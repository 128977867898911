.projectReport{
    height: 100%;
    
    .ant-tabs {
        overflow: hidden !important;
    }
    
    .tabsTitle{
        width: 100%;
        padding: 8px 25px 16px 16px;
        background-color: #fff;
        min-height: 100%;
        .ant-tabs-nav{
            position: static !important;
            padding:0 !important;
            z-index: 0 !important;
            margin-bottom: 0 !important;
            width: 100% !important;
        }
        .ant-tabs-content-holder{
            margin-top: 0;
            padding: 0 !important;
            margin-top: 0 !important;
        }
    }
}