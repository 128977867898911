
.details_Summarize{
    margin-bottom: 15px;
    background-color: #fff;
    padding: 10px;

    .ant-table-pagination.ant-pagination{
        display: none;
    }
    .ant-table-tbody td,
    .ant-table-thead th{
        padding:8px;
    }
}