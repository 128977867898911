.dictionaryDetail {
  padding: 16px;
  height: calc(100% - 59px);
  overflow-y: scroll;
  background: #F6F7FC;

  .dictionaryDetailCon {
    width: 100%;
    /*height: 100%;*/
    /*background: #fff;*/

    .dictionaryDetail_title {
      margin-bottom: 12px;
      background: #fff;

      h4 {
        background: #fff;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000;
        padding-left: 20px;
        padding-right: 40px;
        line-height: 60px;
        height: 60px;
        border-bottom: 1px solid #e9e9e9;
        margin-bottom: 0px;
      }

      .info {
        display: flex;
        flex-direction: column;
        padding: 20px;

        .div1,
        .div2 {
          display: flex;
          align-items: center;
          margin-bottom: 16px;

          &:last-child {
            margin-bottom: 0px;
          }

          div {
            width: 20%;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.65);

            span {
              display: inline-block;
              width: 70px;
              text-align: right;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.65);
            }
          }
        }

        .div3 {
          p {
            display: block;
            margin: 0px;
            width: 100%;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.65);

            &:first-child {
              margin-bottom: 4px;
            }

            &:nth-of-type(2) {
              text-align: left;
              letter-spacing: 1.4px;
              margin-bottom: 0;
              height: 47px;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
              word-break: break-all;
              white-space: pre-wrap;
              padding-right: 94px;
            }
          }
        }
      }
    }

    .dictionaryDetail_con {
      background: #fff;

      h4 {
        background: #fff;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000;
        padding-left: 20px;
        padding-right: 40px;
        line-height: 60px;
        height: 60px;
        border-bottom: 1px solid #e9e9e9;
        margin-bottom: 0px;
      }

      .dictionaryForm {
        padding: 20px;
        width: 850px;
        margin: 0 auto;

        .ant-btn-primary {
          background: #6287EC;
          border-color: #6287EC;
        }

        .ant-btn-dashed {
          color: #6287EC;
          border-color: #6287EC;
        }

        .anticon {
          font-size: 16px;

          &.anticon-minus-circle {
            color: #f04864;
          }

          &.anticon-plus-circle {
            color: #6287EC;
          }
        }
      }
    }
  }
}
