.dctReport {
    height: calc(100% - 60px);
    background: #f6f7fc;
    padding: 0;

    .tabsTitle {
        width: 100%;
        padding: 16px;
        height: 100%;
        position: relative;

        .ant-tabs {
            height: 100%;
            padding: 10px 16px 16px;
            background: #fff;
        }

        .ant-tabs-nav {
            position: static;
            padding: 0;
            z-index: 0;
            margin-bottom: 0;
            background-color: #fff;

        }

        .ant-tabs-nav-list {
            .ant-tabs-tab {
                color: #999;
                font-size: 14px;
            }
        }

        .ant-tabs-content-holder {
            margin-top: 0;
            padding: 0;
            overflow: auto;
        }

        .ant-tabs-nav-wrap{
            border-bottom: none;
        }

        .search{
            position: absolute;
            right: 30px;
            top: 30px;
            z-index: 2;
            font-size: 12px;
            color: #999;
            .ant-picker-input,
            input::placeholder,
            .ant-select-selection-item{
                font-size: 12px;
            }
        }
    }

}