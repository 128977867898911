.countDown-container{
  .countDown{
    color: red;
    font-size: 13px;
  }
}
.countDown-modal{
  .title{
    font-size: 16px;
    .anticon.anticon-warning{
      font-size: 18px;
      margin-right: 10px;
    }
  }
  .content{
    padding-left: 25px;
  }
  .ant-modal-close-x{
    display: none;
  }
  .ant-modal-footer{
    border-top: none;
    padding: 0 25px 25px;
  }
  .ant-modal-body{
    padding: 30px;
  }
}
