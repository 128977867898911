.cso_doctor_medicine_manage_container {
    margin: 0 16px;
    background-color: #fff;
    height: calc(100% - 59px);
    overflow-y: scroll;
    padding-top: 0;
    .txc {
        text-align: center;
    }
    .name_verify {
        margin-left: 18px;
    }
    .role_verify {
        margin-left: 10px;
    }
    > div {
        padding-left: 16px;
        padding-right: 16px;
    }
    .introduce {
        display: flex;
        padding-top: 20px;
        padding-bottom: 21px;
        > div {
            flex: 1 1;
            text-align: center;
            border-right: #E4E4E4 1px solid;
            &:last-child {
                border-right: none;
            }
            > div:nth-child(1) {
                color: #9A9A9A;
                font-size: 16px;
            }
            > div:nth-child(2) {
                color: #3D3D3D;
                font-size: 20px;
                font-weight: bold;
                margin-top: 16px;
            }
        }
    }
    .gray {
        color: #959595;
    }
    .gap {
        width: 100%;
        height: 16px;
        background-color: #F3F5F9;
    }
    .search {
        display: flex;
        justify-content: space-between;
        background-color: #fff;

        .search_con {
            flex: 1 1;
            display: flex;
            flex-wrap: wrap;

            .search_item {
                margin-right: 30px;
                display: flex;
                align-items: center;
                padding-top: 16px;

                .label {
                    width: 70px;
                    display: inline-block;
                    text-align: right;
                    margin-right: 8px;
                }
                .ant-input-affix-wrapper-textarea-with-clear-btn {
                    width: 280px;
                }
            }

        }

        .search_btn {
            display: flex;
            margin-left: 27px;
            margin-right: 27px;
            padding-top: 16px;
        }
    }

    .cpds_content {
        padding-top: 22px;


        .progress {
            text-align: center;

            .status {
                display: flex;

                span {
                    flex: 1 1;
                    color: rgba(51, 51, 51, 0.45);
                }
            }
        }

        .block_list {
            display: flex;
            padding: 0 30px;


            .block {
                min-width: 22px;
                height: 22px;
                border-radius: 50%;
                margin-left: 60px;
                position: relative;

                &:first-child {
                    margin-left: 0;

                    &::after {
                        display: none;
                    }

                    .time {
                        display: none;
                    }
                }

                &::after {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: -60px;
                    content: "";
                    width: 60px;
                    height: 1px;
                    border-bottom: 2px dashed #000;
                }



                .time {
                    position: absolute;
                    top: -10px;
                    left: -60px;
                    width: 60px;
                    text-align: center;
                }
            }



            .success {
                background-image: url('../../../static/csoImages/success.svg');
                background-size: 100% 100%;

                &::after {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: -60px;
                    content: "";
                    width: 60px;
                    height: 1px;
                    border-bottom: 2px solid #3391F0;
                }

                .time {
               
                    color: #3391F0;
                }
            }

            .error {
                background-image: url('../../../static/csoImages/error.svg');
                background-size: 100% 100%;

                &::after {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: -60px;
                    content: "";
                    width: 60px;
                    height: 1px;
                    border-bottom: 2px dashed #FE1805;
                }

                .time {
                    color: #FE1805;
                }
            }

            .wait {
                background-image: url('../../../static/csoImages/wait.svg');
                background-size: 100% 100%;

                &::after {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: -60px;
                    content: "";
                    width: 60px;
                    height: 1px;
                    border-bottom: 2px dashed #00b40d;
                }

                .time {
                    color: #00b40d;
                }
            }

            .notStarted {
                background-image: url('../../../static/csoImages/notStarted.svg');
                background-size: 100% 100%;

                &::after {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: -60px;
                    content: "";
                    width: 60px;
                    height: 1px;
                    border-bottom: 2px dashed #D8D8D8;
                }

                .time {
                    color: #D8D8D8;
                }
            }


        }

    }
    .role_verify {
        position: relative;
        &::after {
            position: absolute;
            left: -10px;
            top: 50%;
            transform: translateY(-50%);
            content: '';
            width: 7px;
            height:7px;
            border-radius: 3.5px;
        }
    }
    .in {
        color: #3391F0;
        &::after {
            background-color: #3391F0;
        }
    }
    .out {
        color: #FF2727;
        &::after {
            background-color: #FF2727;
        }
    }
    .btn_disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
}


.cso_medical_manage_edit_modal {
    .ant-modal-body {
        padding: 41px;
    }
    .icon_plus {
        color: #40a9ff;
        font-size: 18px;
        margin-left: 18px;
        cursor: pointer;
    }
    .modal_content {
        max-height: 80vh;
        overflow-y: auto;
        padding-left: 70px;
        .search_item {
            margin-bottom: 20px;
            display: flex;
            align-items: center;
            .ant-input-affix-wrapper-textarea-with-clear-btn {
                width: 280px;
            }
            &:last-child {
                margin-bottom: 0;
            }
            .label {
                display: inline-block;
                text-align: right;
                width: 80px;
                margin-right: 20px;
            }
            .label_upload {
                width: 280px;
                display: inline-block;
                margin-top: 10px;
            }
        }
        .search_item_upload {
            display: inline-flex;
            align-items: center;
        }
        .select_item {
            text-align: left;
        }
    }
    .modal_footer {
        text-align: right;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .footer_left {
            flex: 1 1;
            text-align: left;
            margin-left: 8px;
            > img {
                width: 16px;
                height: 16px;
                margin-right: 8px;
                margin-bottom: 3px;
            }
            > span {
                line-height: 1;
                color: #3391F0;
                font-size: 16px;
            }
        }
    }
}

.cso_medical_manage_detail_modal {
    .ant-modal-body {
        padding: 41px;
    }
    .modal_content {
        max-height: 70vh;
        overflow-y: auto;
        display: flex;
        flex-wrap: wrap;
        .search_item {
            margin-bottom: 20px;
            &:last-child {
                margin-bottom: 0;
            }
            .label {
                display: inline-block;
                text-align: right;
                margin-right: 10px;
                width: 80px;
                vertical-align: top;
            }
            .text {
                display: inline-block;
                width: 200px;
            }
            .label_upload {
                width: 280px;
                display: inline-block;
                margin-top: 10px;
            }
        }
    } 
}