.cso_project_manage_add {
  .add_item {
    display: flex;
    align-items: center;
    margin-top: 15px;
    position: relative;
  }
  .add_item_input_doctor {
    position: absolute;
    left: 225px;
    top: 35px;
    z-index: 999;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 3px 0px #d0cfcf;
    width: 400px;
    height: 150px;
    padding: 10px;
    overflow: auto;
  }
  .add_item_input_doctor_item {
    cursor: pointer;
  }
  .add_item_input_doctor_item:hover {
    color: #1890ff;
  }
  .add_item_label {
    width: 200px;
    margin-right: 25px;
    text-align: right;
  }
  .add_item_input {
    width: 400px;
  }
  .add_item_equal_input {
    width: 400px;
    display: flex;
    align-items: center;
    .add_item_equal_input_select {
      width: 100px;
      margin-left: 10px;
    }
    .add_item_equal_input_input {
      flex: 1 1;
      margin-left: 10px;
    }
  }
  .add_item_equal_input_btns {
    width: 400px;
    text-align: right;
    button {
      margin-left: 10px;
      margin-bottom: 10px;
    }
  }
  .add_item_desc {
    margin-left: 25px;
    color: #40a9ff;
    display: flex;
    align-items: center;
    svg {
      font-size: 18px;
    }
    > span {
      margin-left: 5px;
    }
  }
  .icon_plus {
    color: #40a9ff;
    font-size: 18px;
  }
  .add_item_add_prescription {
    color: #40a9ff;
    margin-left: 10px;
  }
  .icon_minus {
    color: red;
    font-size: 18px;
  }
  .cursor_pointer {
    cursor: pointer;
  }
}