.dctMOnitor_content {
  
    .dct_bottom {
      margin-top: 16px;
      background: #fff;
      position: relative;
      height: 460px;
  
      .searchs {
        position: absolute;
        right: 16px;
        top: 16px;
        z-index: 2;
      }
  
      .ant-tabs-nav {
        background: #fff;
        padding: 15px 20px 8px;
        margin-bottom: 5px;
  
        .ant-tabs-nav-wrap {
          border-bottom: none;
        }
  
        .ant-tabs-tab .ant-tabs-tab-btn {
          font-size: 14px;
          color: #666;
          margin-right: 0px;
        }

        .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
            font-weight: bolder;
            color: #333;
          }
  
        .ant-tabs-ink-bar {
          background: rgba(98, 135, 236, 1);
        }
      }

      .ant-tabs-content{
          padding: 0 20px 20px;
      }

      .ant-list-item{
        border-color: transparent;
        padding: 3px 0;
      }
      
    }

    .ant-list-item-meta-title{
      margin-bottom: 0;
      font-size: 13px;
    }

    .ant-list-item-meta-avatar{
        width: 80px;
        margin-right: 10px;

        .ant-tag{
            display: inline-block;
            width: 100%;
            text-align: center;
        }

      .ant-tag-magenta,
      .ant-tag-default,
      .ant-tag-processing,
      .ant-tag-purple,
      .ant-tag-error,
      .ant-tag-warning{
          border-color: transparent;
      }

      .ant-tag-default{
          background-color: #f4f4f4;
      }
    }

    .ant-card-head-title{
        font-weight: bolder;
        font-size: 14px;
    }

    .timeTxt{
        font-size: 12px;
        color: #888;
    }
}

.monitor_modal{
  .ant-checkbox + span{
    font-size: 13px;
    padding-right: 20px !important;
  }
}