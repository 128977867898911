.cso_info_record_container {
    margin: 0 16px;
    background-color: #fff;
    height: calc(100% - 59px);
    overflow-y: scroll;
    padding-top: 0;

    .search {
        display: flex;
        justify-content: space-between;
        background-color: #F8F9FE;
        padding: 16px;
        position: relative;
        .customer {
            position: absolute;
            top: -10px;
            left: 77px;
            width: 57px;
            height: 57px;
        }
        .ask {
            position: absolute;
            bottom: 12px;
            right: 139px;
            width: 67px;
            height: 67px;
        }
        .line_gap {
            background-color: #D8D8D8;
            width: 1px;
            transform: translate(-13px, 10px);
        }
        .search_con {
            flex: 1 1;
            display: flex;
            flex-wrap: wrap;
            .search_item {
                margin-right: 30px;
                padding-top: 16px;
                position: relative;
                .label {
                    display: block;
                    text-align: left;
                    margin-bottom: 4px;
                    color: #8F8F8F;
                    font-size: 16px;
                }
                .ant-input-affix-wrapper {
                    border-radius: 8px;
                }
                .search_item_input_pre_suffix {
                    display: flex;
                }
            }
            .item_input_select {
                position: absolute;
                top: 48px;
                right: 0;
                width: 280px;
                background-color: #FFFFFF;
                color: #666666;
                box-shadow: 0px 0px 3px 0px #d0cfcf;
                max-height: 150px;
                overflow: auto;
                z-index: 999;
                cursor: pointer;
                padding: 10px;
                .item_input_select_list:hover {
                    color: #1890ff;
                }
                .item_input_select_list > div {
                    display: inline-block;
                }
            }
        }

        .search_btn {
            display: block;
            margin-left: 12px;
            margin-right: 27px;
            padding-top: 45px;
        }
    }
    .title {
        text-align: center;
        color: #3D3D3D;
        font-size: 20px;
        font-weight: bold;
        background-color: #F8F9FE;
        padding-top: 17px;
    }
    .cpds_content {
        padding-top: 22px;
        padding: 16px;
        .progress {
            text-align: center;

            .status {
                display: flex;

                span {
                    flex: 1 1;
                    color: rgba(51, 51, 51, 0.45);
                }
            }
        }
        .no_record {
            text-align: center;
            img {
                width: 198px;
                margin-top: 122px;
            }
            .text {
                color: #B9C4E1;
                font-size: 16px;
                margin-top: 21px;
            }
        }
        .block_list {
            display: flex;
            padding: 0 19px;


            .block {
                min-width: 22px;
                height: 22px;
                border-radius: 50%;
                margin-left: 40px;
                position: relative;

                &:first-child {
                    margin-left: 0;

                    &::after {
                        display: none;
                    }

                    .time {
                        display: none;
                    }
                }

                &::after {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: -40px;
                    content: "";
                    width: 40px;
                    height: 1px;
                    border-bottom: 2px dashed #000;
                }



                .time {
                    position: absolute;
                    top: -10px;
                    left: -40px;
                    width: 40px;
                    text-align: center;
                }
            }



            .success {
                background-image: url('../../../static/csoImages/success.svg');
                background-size: 100% 100%;

                &::after {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: -40px;
                    content: "";
                    width: 40px;
                    height: 1px;
                    border-bottom: 2px solid #3391F0;
                }

                .time {
               
                    color: #3391F0;
                }
            }

            .error {
                background-image: url('../../../static/csoImages/error.svg');
                background-size: 100% 100%;

                &::after {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: -40px;
                    content: "";
                    width: 40px;
                    height: 1px;
                    border-bottom: 2px dashed #FE1805;
                }

                .time {
                    color: #FE1805;
                }
            }
            .refund {
                background-image: url('../../../static/csoImages/refund.svg');
                background-size: 100% 100%;

                &::after {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: -40px;
                    content: "";
                    width: 40px;
                    height: 1px;
                    border-bottom: 2px dashed #FFA34C;
                }

                .time {
                    color: #FFA34C;
                }
                .name {
                    color: #FFA34C;
                }
            }
            .wait {
                background-image: url('../../../static/csoImages/wait.svg');
                background-size: 100% 100%;

                &::after {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: -40px;
                    content: "";
                    width: 40px;
                    height: 1px;
                    border-bottom: 2px dashed #00b40d;
                }

                .time {
                    color: #00b40d;
                }
            }

            .notStarted {
                background-image: url('../../../static/csoImages/notStarted.svg');
                background-size: 100% 100%;

                &::after {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: -40px;
                    content: "";
                    width: 40px;
                    height: 1px;
                    border-bottom: 2px dashed #D8D8D8;
                }

                .time {
                    color: #D8D8D8;
                }
            }


        }

    }
}
