.overall {
    width: 100%;
    padding: 40px 30px 0;

    .ul {
      padding-left: 0px;
      width: 100%;
      display: flex;
      justify-content: space-between;
  
      .li {
        text-align: center;
        width: 16.66%;
  
        .echarts_title {
          text-align: center;   
          .num{
            margin: 5px 0 0;
            font-size: 22px;
          }
          .txt{
            font-size: 14px;
            color: #999;
          }
        }
      }
    }
  }