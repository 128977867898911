.projectStatistics {
  margin-top: 16px;
  position: relative;
  .ant-tabs-nav{
    position: static !important;
    padding-top: 10px;
  }
  .ant-tabs-content-holder{
    margin-top: 0 !important;
    background-color: #fff !important;
  }
  .search{
    position: absolute;
    z-index: 2;
    width: 20%;
    right: 16px;
    top: 15px;
  }
  .none{
    display: none;
  }
}