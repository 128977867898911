.detail_container {
    padding: 16px;
    height: calc(100% - 59px);
    overflow-y: scroll;

    .plan_header {
        background-color: #fff;
        padding: 17px 22px;

        .planName {
            font-size: 18px;
            font-weight: 600;

            .label,
            .design {
                margin-left: 8px;
                color: #52C41A;
                font-size: 14px;
                position: relative;
                padding-left: 10px;
                font-weight: 300;

                &::before {
                    content: "";
                    position: absolute;
                    width: 6px;
                    height: 6px;
                    background-color: #52C41A;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    border-radius: 50%;
                }
            }

            .design {
                color: #3391F0;

                &::before {
                    background-color: #3391F0;
                }
            }
        }

        .planInfo {
            display: flex;
            padding-top: 16px;

            .info_item {
                margin-right: 72px;
                color: #333;

                .label {
                    color: #666;
                }

                .userInfo {
                    display: flex;
                    align-items: center;

                    .avatar {
                        width: 16px;
                        height: 16px;
                        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' fill='none' version='1.1' width='16' height='16' viewBox='0 0 16 16'%3E%3Cg style='mix-blend-mode:passthrough'%3E%3Cg style='mix-blend-mode:passthrough'%3E%3Cellipse cx='8' cy='8' rx='8' ry='8' fill='%23E9F3FE' fill-opacity='1'/%3E%3C/g%3E%3Cg style='mix-blend-mode:passthrough'%3E%3Cg style='mix-blend-mode:passthrough'%3E%3Cellipse cx='8' cy='5' rx='3' ry='3' fill='%23ACD0F4' fill-opacity='1'/%3E%3C/g%3E%3Cg style='mix-blend-mode:passthrough'%3E%3Cellipse cx='8' cy='11' rx='5' ry='3' fill='%23ACD0F4' fill-opacity='1'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                        background-size: 100% 100%;
                        margin-right: 4px;
                    }
                }

            }
        }

        .introduce {
            padding-top: 16px;

            .label {
                color: #666;
            }
        }
    }

    .plan_record {
        margin-top: 16px;
        background-color: #fff;
        padding: 12px 16px;

        .record_tabs {
            display: flex;
            font-size: 16px;
            color: #666;

            .record_tab {
                margin-right: 32px;
                cursor: pointer;
            }

            .current {
                color: #333;
                font-weight: 600;
            }


            .extraContent {
                flex: 1 1;
                display: flex;
                justify-content: end;

                .ant-btn {
                    border: none;
                    background-color: #DCEDFF;
                    color: #3391F0;
                }

            }
        }

        .record_con {
            padding-top: 12px;

            .isCurrent {
                width: 64px;
                height: 22px;
                background: #52C41A;
                border-radius: 16px 16px 16px 16px;
                color: #fff;
                text-align: center;
                font-size: 12px;
                line-height: 22px;
            }

            .planStatus {

                .release,
                .design {
                    color: #52C41A;
                    position: relative;
                    padding-left: 10px;
                    font-weight: 300;

                    &::before {
                        position: absolute;
                        width: 6px;
                        height: 6px;
                        content: "";
                        background-color: #52C41A;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        border-radius: 50%;
                    }
                }

                .design {
                    color: #3391F0;

                    &::before {
                        background-color: #3391F0;
                    }
                }
            }

            .isCheck {
                .passed {
                    color: #52C41A;
                    display: flex;
                    align-items: center;

                    .icon {
                        width: 16px;
                        height: 16px;
                        background: url("data:image/svg+xml,%3Csvg t='1675673513893' class='icon' viewBox='0 0 1024 1024' version='1.1' xmlns='http://www.w3.org/2000/svg' p-id='2410' width='200' height='200'%3E%3Cpath d='M512.001024 64.004096C264.589312 64.004096 64 264.580096 64 512.00512c0 247.41888 200.589312 447.98976 448.001024 447.98976 247.428096 0 447.998976-200.57088 447.998976-447.98976 0-247.425024-200.57088-448.001024-447.998976-448.001024z m261.523456 328.922112L457.92256 707.489792c-4.404224 4.388864-10.202112 6.528-15.9744 6.443008-5.772288 0.084992-11.573248-2.055168-15.980544-6.443008L271.735808 553.764864c-8.649728-8.61184-8.649728-22.575104 0-31.192064 8.634368-8.607744 22.648832-8.607744 31.292416 0l138.916864 138.459136 300.295168-299.290624c8.643584-8.61184 22.656-8.61184 31.2832 0 8.641536 8.609792 8.641536 22.581248 0.001024 31.184896z' p-id='2411' data-spm-anchor-id='a313x.7781069.0.i0' class='selected' fill='%2352C41A'%3E%3C/path%3E%3C/svg%3E");
                        background-size: 100% 100%;
                        margin-right: 4px;
                    }
                }

                .failed {
                    color: #F03333;
                    display: flex;
                    align-items: center;

                    .icon {
                        margin-right: 4px;
                        width: 16px;
                        height: 16px;
                        background: url("data:image/svg+xml,%3Csvg t='1675673657651' class='icon' viewBox='0 0 1024 1024' version='1.1' xmlns='http://www.w3.org/2000/svg' p-id='2734' width='200' height='200'%3E%3Cpath d='M511.997952 64.00512c-247.409664 0-447.997952 200.574976-447.997952 448 0 247.41888 200.588288 447.988736 447.997952 447.988736 247.42912 0 448.002048-200.57088 448.002048-447.988736 0-247.425024-200.572928-448-448.002048-448z m205.896704 653.888512c-8.745984 8.748032-22.929408 8.748032-31.684608 0L511.997952 543.676416 337.787904 717.893632c-8.747008 8.748032-22.929408 8.748032-31.67744 0-8.753152-8.747008-8.753152-22.92736 0-31.67744l174.214144-174.215168-174.214144-174.219264c-8.753152-8.73984-8.753152-22.924288 0-31.675392 8.749056-8.741888 22.931456-8.741888 31.67744 0l174.214144 174.216192 158.379008-158.380032c8.745984-8.741888 22.929408-8.741888 31.666176 0 8.749056 8.751104 8.749056 22.935552 0 31.67744L543.676416 511.996928l174.21824 174.219264c8.748032 8.75008 8.748032 22.930432 0 31.67744z' p-id='2735' fill='%23F03333'%3E%3C/path%3E%3C/svg%3E");
                        background-size: 100% 100%;
                    }
                }
            }
        }
    }
}

.plan_modal {

    .right_opertions {
        .ant-btn {
            color: #999;
            padding-right: 4px;
            padding-left: 4px;
            margin-right: 16px;
        }

        .anticon-close {
            cursor: pointer;
        }
    }


    .footerBtns {
        display: flex;
        justify-content: end;
        padding-top: 24px;
    }
}