.batchSettlemet{
    padding: 0 16px 16px;
    background: #f5f7fa;
    height: calc(100% - 60px);

    .content_wrap{
        height: 100%;
        padding: 20px;
        background: #fff;
        box-sizing: border-box;
    }

    .search_wrap{
        text-align: left;
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;

        button.ant-btn.ant-btn-primary{
            border-radius: 5px;
        }

        
    }
    
    .ant-btn.ant-btn-link{
        font-size: 13px;
    }
    .partPop{
        text-align: left;
        .ant-btn.ant-btn-link{
            padding-left: 0;
        }
        .ant-btn.ant-btn-primary[disabled] {
            color: rgba(0, 0, 0, 0.25);
            background: #f5f5f5;
            border-color:transparent;
            box-shadow: none;
          }
    }
    .ant-table-pagination.ant-pagination{
        padding: 16px 0 0;
        background: #fff;
        margin:0;
    }

    .con_div{
        text-align: left;
        font-size: 13px;
        .title{
            color: #000;
            margin-bottom: 5px;
        }
        .sub-title{
            color: rgba(0, 0, 0, 0.45);
        }
        .yellow{
            color: orange;
        }
    }

    .current_state{
        .ant-btn{
            height: auto;
            line-height: auto;
            padding: 2px 7px;
            border: none;
            border-radius: 5px;
            font-size: 13px !important;
            background-color: transparent;
        }
        .ant-btn-link{
            color: #1890ff;
        }
        .ant-btn-text{
            color: #999;
        }
    }
}

.tooltip_text{
    color: red;
    margin-bottom: 20px;
}
.mould{
    margin-left: 16px;
}
