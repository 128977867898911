.ant-tabs-override-category {
  height: calc(100vh - 352x);
  margin-top: 16px;
}

.ant-tabs-override-category > .ant-tabs-nav::before {
  display: none;
}

.ant-tabs-override-category > .ant-tabs-nav > .ant-tabs-nav-wrap {
  padding-bottom: 0;
  border-bottom: 0;
}

.ant-tabs-override3 > .ant-tabs-nav > .ant-tabs-nav-wrap >.ant-tabs-nav-list {
  padding-left: 18px;
}

.ant-tabs-override3 > .ant-tabs-content-holder {
  height: calc(100vh - 253px);
  overflow: hidden;
  overflow-y: auto;
}