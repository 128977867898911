.setting {
  background: #fff;
  position: relative;
  padding: 50px 0px 50px 0px;

  .setting_con {
    /*position: absolute;
    width: 100%;
    height: 100%;
    left: 33%;
    right: 0;
    margin: auto;*/

    #nest-messages {

      .ant-form-item {
        margin-bottom: 20px;
      }

      .ant-form-item-label > label {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999;
      }

      .ant-form-item-label {
        /*text-align: left;*/
      }
    }

    .ant-btn-primary {
      background: #6287EC;
      border-color: #6287EC;
    }

    .ant-select-arrow {
      .anticon {
        > svg {
          vertical-align: middle;
          font-size: 12px;
          margin-top: -6px;
          color: #888;
        }
      }
    }

    .mleft{
      margin-left: 17px;
    }
    .rowBottom{
      margin-bottom: 30px;
    }
    .pTop{
      padding-top: 8px;
    }
    .leftLable{
      text-align: right;
      margin-right: 16px;
      color: #999;
    }
    .rightText{
      color: #333;
    }
  }

}