.feat-message--layout {
  height: calc(100% - 54px);
  border-top: 1px solid #eee;
  display: flex;

  .feat-message--west {
    width: 220px;
    height: 100%;
    background-color: #fff;

    .feat-message--finder {
      padding: 8px;
      background-color: #fafafa;
    }
    
    .feat-message--sessoins {
      height: calc(100% - 48px);
      overflow: hidden;
      overflow-y: auto;
      ul {
        height: auto;
        overflow: hidden;
        margin: 0;
        padding: 0;
      }
      li {
        height: auto;
        overflow: hidden;
        display: flex;
        padding: 8px;

        &:hover {
          background-color: #eaeaea;
        }

        .wt {
          width: 48px;
          height: 48px;
          border-radius: 50%;
          background-color: #fafafa;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .md {
          flex: 1 1;
          flex-shrink: 0;
          height: 48px;
          display: flex;
          padding-left: 8px;
          flex-direction: column;
          justify-content: center;

          h4, p {
            line-height: 20px;
            font-size: 14px;
            color: #373737;
            margin: 0;
          }
          p {
            font-size: 12px;
            color: #a9a9a9;
          }
        }

        &.active {
          background-color: #39f;
          h4 {
            color: #fff;
          }
          p {
            color: #fff;
          }
        }
      }
    }
  }
}

.customized-scrollbar {
  position: relative;
  
  &::-webkit-scrollbar {
    width: 5px;
    background-color: #d5dee3;
    border-radius: 3px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #b7d1e2;
    border-radius: 3px;
  }
}

.feat-layout--message {
  .loader {
    padding: 8px;
    .ant-skeleton-content {
      .ant-skeleton-title {
        margin: 0 !important;
      }
    }
  }
  
  .ant-skeleton-with-avatar {
    .ant-skeleton-content {
      .ant-skeleton-title {
        margin-top: .5em;
        & + .ant-skeleton-paragraph {
          margin-top: .5em;
          margin-bottom: 0;
        }
      }
    }
  }
}
