.report-container {
  font-family: SourceHanSansSC-regular;

  .report-header {
    color: #2e2f33;
    font-size: 20px;
    font-weight: 500;
  }

  .report-item {
    display: flex;
    height: 50px;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;
    background-color: #ffffff;
    margin-top: 6px;
    position: relative;

    &[data-state="0"] {
      &::after {
        background-color: #e6ebf5;
      }
    }

    &[data-state]:not([data-state="0"]) {
      cursor: pointer;

      &::after {
        background-color: #6c97f7;
      }
    }

    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 8px;
      height: 100%;
      z-index: 1;
    }

    &--title {
      flex: 1 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &--right {
      flex-shrink: 0;
      width: 50%;
      display: flex;
      justify-content: space-between;
    }

    &--state {
      display: flex;
      flex-direction: column;
      font-size: 12px;
      color: #9a9fab;
    }

    &--read {
      width: 50px;
      .true {
        color: #3b72e8;
      }
      .false {
        color: #F59A23;
      }
    }

    .report-state--text {
      padding-left: 12px;
      position: relative;

      &[data-state="0"] {
        color: #9a9fab;

        &::before {
          background-color: #9a9fab;
        }
      }

      &[data-state="1"] {
        color: #6c97f7;

        &::before {
          background-color: #6c97f7;
        }
      }

      &::before {
        position: absolute;
        content: "";
        width: 6px;
        height: 6px;
        border-radius: 6px;
        top: 50%;
        transform: translateY(-50%);
        left: 2px;
        background-color: #9a9fab;
      }
    }

    .report-state--time {
      padding-left: 12px;
    }

    &--behavior {
      display: flex;
      align-items: center;
      color: #6c97f7;

      span+span {
        margin-left: 8px;
      }
    }
  }
}

.self-reported--modal {
  .ant-modal-body {
    padding: 0;
  }

  .iframe {
    height: 600px;
    background-color: #f5f5f9;
    border-left: 1px solid #f0f0f0;
  }
}

.report-modal {
  &--container {
    display: flex;

    ::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }

    ::-webkit-scrollbar-track {
      width: 4px;
      background: transparent;
      border-radius: 2rem;
    }

    ::-webkit-scrollbar-thumb {
      background-color: rgba(144, 147, 153, 0.5);
      background-clip: padding-box;
      min-height: 12px;
      border-radius: 2rem;
      -webkit-transition: background-color 0.3s;
      transition: background-color 0.3s;
      cursor: pointer;
    }

    ::-webkit-scrollbar-thumb:hover {
      background-color: rgba(144, 147, 153, 0.3);
    }
  }

  &--header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    border-left: 1px solid #f0f0f0;
    color: #6c97f7;
    font-size: 16px;
  }

  &--record {
    width: 200px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;

    .report-modal--header {
      border-left: none;
    }

    .report-record--select {
      font-size: 16px;
      color: #6c97f7;
      max-width: 160px;

      &:not(.ant-select-open) {
        .ant-select-arrow {
          color: #6c97f7;
        }
      }
    }

    .report-record--content {
      height: 600px;
      overflow-y: auto;

      .record-item {
        height: 50px;
        justify-content: center;
        align-items: center;
        display: flex;
        cursor: pointer;

        &.active {
          background-color: #f5f5f9;
        }

        &--icon {
          color: #6c97f7;
          margin-right: 6px;
          font-size: 14px;
        }
      }
    }
  }

  &--question {
    flex: 1 1;
  }

  &--result {
    flex: 1 1;
  }
}

.modal-report-tips {
  font-size: 16px;
  color: #6c97f7;
  margin-bottom: 12px;
}

.modal-container {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  padding-bottom: 16px;

  &-left {
    width: 48%;

  }

  &-right {
    width: 48%;

    &-select {
      margin-bottom: 12px;
    }

    &-textarea {
      margin-bottom: 12px;
    }
  }



}

.modal-report-module {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
}

.modal-report-content {
  background-color: #6c97f7;
  font-size: 14px;
  padding: 8px 12px;
  color: #fff;
  display: flex;
  align-items: center;
  grid-gap: 2px;
  gap: 2px;
}

.modal-report-nums {
  text-align: right;
  font-size: 12px;
  font-weight: bold;
  padding: 8px 12px;
}

.modal-report-phone {
  margin-top: 12px;
}

.modal-report-exam {
  margin: 12px auto;
}

.modal-report-doctor {
  font-size: 12px;
  margin: 12px auto;
  display: flex;

  &-position {
    width: 70%;
    margin-right: 12px;
  }

  &-name {
    width: 30%;
  }

  &-position,
  &-name {
    color: #6c97f7;
    border: 1px solid #dcdcdc;
    border-radius: 4px;
    padding: 4px 6px;
  }

}

.modal-warn {
  display: flex;
  grid-gap: 20;
  gap: 20;

  &-left {
    width: 50%;
    text-align: center;
  }

  &-right {
    width: 50%;
  }

  &-bottom {
    text-align: center;
    color: #333;
    opacity: 0.5;
  }

  .warn-title {
    text-align: center;
    margin-bottom: 12px;
    font-size: 16px;
    color: #6c97f7;
  }

  .warn-qrcode {
    width: 190px;
    height: 190px;
    border: 1px dashed #6c97f7;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 8px auto;
  }
}