.settlementReview_content {
    padding: 0 16px 16px;
    background: #f5f7fa;
    height: calc(100% - 55px);

    .apply_wrap {
        height: 100%;
        padding: 20px;
        background: #fff;
        overflow: auto;
    }

    .search_wrap_content {
        display: flex;
        justify-content: space-between;
        padding-bottom: 10px;

        .search_left {
            flex-basis: 80%;

            .search_row {
                position: relative;

                .search_label {
                    margin-right: 6px;
                }
            }

            .ant-col {
                margin-bottom: 16px;
                min-width: 200px;
            }
        }

        .right_action {
            flex-basis: 200px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .ant-btn {
                width: 90px;
                margin-bottom: 5px;
            }

        }

    }

    .btns_wrap {
        background: #fff;
        padding-bottom: 10px;

        .ant-btn-background-ghost {
            margin-left: 16px;
        }
    }


    .apply_state {
        .state {
            color: #000;
            position: relative;

            .dot {
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background-color: #000;
                display: none;
            }
        }

        .noStart {
            color: #52C41A;

            .dot {
                background-color: #52C41A;
            }
        }


        .running {
            color: #EF822B;
            cursor: pointer;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            line-clamp: 1;
            -webkit-box-orient: vertical;

            .dot {
                background-color: #EF822B;
            }
        }

        .success {
            color: #3391F0;

            .dot {
                background-color: #3391F0;
            }
        }

        .notPass {
            color: #F03333;

            .dot {
                background-color: #F03333;
            }
        }
    }

    .case-audit-checkbox-wrapper {
        .case-audit-text-area {
            margin-top: 5px;
            border-radius: 5px;
            width: 100%;
            min-height: 100px;
            outline: none;
            border-color: #d4d0d0;
        }
    }
}

.uploadModal {

    .footer_btn {
        display: flex;
        justify-content: space-between;
    }


    .title {
        color: rgba(0, 0, 0, 0.65);
        padding-bottom: 24px;
    }

    .selectProject {
        display: flex;
        align-items: start;
        color: rgba(0, 0, 0, 0.65);
        position: relative;
    }

    .uploadtip {
        padding-left: 70px;
        padding-top: 5px;
        color: rgba(0, 0, 0, .45);
    }

    .notips {
        width: 186px;
        line-height: 30px;
        border-radius: 20px;
        position: absolute;
        left: 230px;
        background: #FFF1F0;
        border: 1px solid #FFA8A5;
        color: #756E6E;
        padding-left: 10px;
    }


    .uploading_content {
        text-align: center;

        .uploading_title {
            font-weight: bold;
            font-size: 16px;
        }

        .uploading_tip {
            padding-top: 5px;
            color: #333;
            padding-bottom: 10px;
        }

        .uploading_progress {
            padding-top: 10px;
            color: rgba(0, 0, 0, .45);
        }
    }

    .upload_success_content {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            margin-right: 10px;
        }

        .success_tip {
            font-weight: bold;
            padding-bottom: 4px;
        }

        .success_num {
            color: #333;
        }
    }


    .upload_fail_content {

        .fail_title {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-bottom: 8px;

            img {
                margin-right: 6px;
            }

            span {
                font-weight: bold;
            }
        }

        .fail_tip {
            text-align: center;
            padding-bottom: 10px;
            color: rgba(0, 0, 0, .8);
        }

        .error_list {
            padding-top: 10px;
            border-top: 1px solid rgba(0, 0, 0, .1);
            max-height: 200px;
            overflow-y: auto;

            .error_item {
                color: #333;
                padding-bottom: 4px;
            }
        }
    }
}