.patient_container {
    padding: 30px;

    .search {
        padding-bottom: 30px;
        display: flex;

        .searchItem {
            margin-right: 10px;
        }
    }
}