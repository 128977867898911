.CheckList_wrap{
  background-color: #fff;
  height: 100%;
  padding: 0 16px 16px;
  position: relative;

  .operate_wrap{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eee;
    padding: 5px 0;
    .title{
      font-size: 16px;
      font-weight: bolder;
      margin: 10px 0;
    }
    .switch{
      display: flex;
      align-items: center;
      border: 1px solid #ddd;
      border-radius: 8px;
      .left{
        padding: 5px 15px;
        border-right: 1px solid #ddd;
        border-radius: 8px 0 0 8px;
      }
      .right{
        padding: 5px 15px;
        border-radius: 0 8px 8px 0;
      }
    }
  }
  .operate_bottom{
    text-align: right;
    padding-top: 16px;
    box-shadow: 0 -4px 4px -2px #eee;
    margin-top: 1px;
    .btns .ant-btn{
      font-size: 14px;
      margin-left: 20px;
    }
  }
  .checkbox_wrap{
    width: 100%;
    >.ant-checkbox-wrapper{
      margin-bottom: 5px;
    }

    .ant-checkbox-group{
      .ant-checkbox-wrapper.ant-checkbox-group-item{
        width: 32%;
        position: relative;
        >span.ant-checkbox{
          position: absolute;
          z-index: 3;
          right: 3%;
          top: 69%;
        }
        >span.ant-checkbox.ant-checkbox-disabled{
          display: none;
        }
        span:nth-of-type(2){
          width: 100%;
          overflow: auto;
          padding:0;
          .options_item{
            position: relative;
            margin-bottom: 5px;
            padding: 5px 0;
            .maskAll{
              position: absolute;
              top:0;
              left:0;
              right: 0;
              bottom: 0;
              background: rgba(255,255,255,.6);
            }
            .optionsContent{
              background-color: #e9eaec;
              text-align: center;
              height: 160px;
              position: relative;
              .mask{
                position: absolute;
                top:0;
                left:0;
                z-index: 1;
                width: 100%;
                background: rgba(0,0,0,.5);
                font-size: 12px;
                color: rgba(255,255,255,.9);
                text-align: left;
                padding: 5px 10px;
              }
            }
          }
        }
        .ant-checkbox-inner{
          border-color: #6287ec;
        }
      }
    }
  }
  
  .ant-row {
    height: calc(100% - 110px);
    
    background-image: linear-gradient(to right, transparent 99.9%, rgba(0, 0, 0, 0.05) 50%), 
    linear-gradient(to left, transparent 99.9%, rgba(0, 0, 0, 0.05) 50%), 
    linear-gradient(to top, transparent 99.9%, rgba(0, 0, 0, 0.05) 50%), 
    linear-gradient(to bottom, transparent 99.9%, rgba(0, 0, 0, 0.05) 50%);
    background-size: 33.3% 50%;
    
    .item{
      /*border: 1px solid #e9eaec;*/
      height: 100%;
      padding: 16px 16px 0;
      
      .col_content{
        cursor: pointer;
        background: #e9eaec;
        height: 88%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        .mask{
          position: absolute;
          top:10px;
          left:5px;
          z-index: 1;
          width: calc(100% - 20px);
          margin-left: 5px;
          background: rgba(0,0,0,.4);
          color: rgba(255,255,255,.9);
          text-align: left;
          padding: 3% 5%;

          svg {
            width: 1.2em;
            height: 1.2em;
          }
        }
        .multiple{
          position: absolute;
          left: 3%;
          bottom: 3%;
          .ant-checkbox-inner{
            border: 1.5px solid #6287ec;
          }
        }
        .ImgStyle{
          max-width:100%;
          max-height:100%;
          object-fit: contain;
        }
        .icon-mark{
          position: absolute;
          bottom: -3px;
          right: -2px;
          width: 20%;
        }
      }
    }
    
  }
  
  .img_tit{
    font-size: 14px;
    color: #333;
    height: 12%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    .txt{
      display: inline-block;
      max-width: calc(100% - 70px);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-left: 5px;
    }
    .small-sign{
      background-color: #eed0a6;
      color: #ef9923;
      border-radius: 3px;
      font-weight: normal;
      display: inline-block;
      font-size: 12px;
      width: 55px;
      text-align: center;
    }
  }
  
}