.e_Group2 {
  width: 68.7%;
  float: right;
  background: #fff;
  min-height: 800px;

  > ul {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    vertical-align: center;
    padding-left: 10px;

    > li {
      width: 50%;
      height: 380px;
      overflow: hidden;
      text-align: center;
      position: relative;

      .e_Group2_title {
        position: absolute;
        bottom: 30px;
        left: 77px;
        margin-top: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #484848;
      }
    }

    > li:nth-of-type(2) {
      .e_Group2_title {
        left: 92px;
        bottom: 28px;
      }
    }

    > li:nth-of-type(3) {
      .e_Group2_title {
        left: 77px;
        bottom: 27px;
      }
    }

    > li:nth-of-type(4) {
      .e_Group2_title {
        left: 94px;
        bottom: 27px;
      }
    }

  }
}
