#edc-loading{
    .loading{
        position: fixed;
        top: 40%;
        left: 50%;
        transform: translate(-50%,-50%);
        padding:0 40px;
        height: 80px;
        line-height: 80px;
        border-radius: 6px;
        text-align: center;
        z-index: 9999;
        font-size:16px;
        color:#fff;
        .ant-spin {
            .ant-spin-dot-spin > .ant-spin-dot-item{
                background-color: #999;
            }
            .ant-spin-text {
                color: #999;
            }
        }
    }
    .overlay{
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 9998;
        background: rgb(255, 255, 255);
        opacity: 0.1;
    }
}