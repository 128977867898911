.ant-tabs-override > .ant-tabs-nav > .ant-tabs-nav-wrap {
  background-color: #fff;
  padding-bottom: 0;
  border-bottom: 0;
}

.ant-tabs-override > .ant-tabs-nav > .ant-tabs-nav-wrap >.ant-tabs-nav-list {
  padding-left: 18px;
}

.ant-tabs-override > .ant-tabs-content-holder {
  height: calc(100vh - 253px);
  overflow: hidden;
  overflow-y: auto;
}