.upload_wrap {
  .crf_upload {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
  }

  .upload_wrap {
    position: relative;
    background: #fff;
    width: 80px;
    height: 30px;
    font-size: 12px;
    overflow: hidden;
    color: #6287ec;
    margin: 0 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    border: 1px solid #6287ec;
    cursor: pointer;

    .upload_file {
      position: absolute;
      right: 0;
      top: 0;
      opacity: 0;
      width: 100%;
      height: 100%;
    }
  }
  .upload_tip {
    margin-left: 6em;
    color: #b2acac;
    font-size: 12px;
  }
  .upload_url {
    margin-left: 6em;
    font-size: 12px;
  }
  .upload_img {
    position: relative;
    min-width: 80px;
    height: 30px;
    margin: 0 5px 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .file_name {
      color: #6287ec;
      font-size: 12px;
    }

    .ant-image,
    .img_item {
      /*width: 80px;
          height: 80px;*/
      max-width: 80px !important;
      max-height: 80px !important;
      object-fit: contain !important;
    }

    .icon_del {
      position: absolute;
      right: -10px;
      top: -5px;
      color: #555;
      cursor: pointer;
      font-size: 16px;
    }
  }
}
