.TransferModals {
  p{
    margin:0;
  }
  .ant-modal-body,
  .ant-modal-footer {
    background: #F6F7FC;
    border-top: none;
  }

  .ant-modal-body {
    padding: 30px 46px 0px 46px;
  }

  .ant-modal-footer {
    padding: 0px 46px 30px 46px;
  }

  .ant-modal-close-x {
    height: 50px;
    line-height: 50px;

    .anticon {
      font-size: 12px;
    }
  }

  .ant-modal-header {
    padding: 14px 26px;
  }

  .ant-modal-title {
    font-family: PingFangSC-Regular, PingFang SC;
    line-height: 21px;
    font-size: 14px;
    font-weight: 500;
    color: #2E2F33;
  }

  .ant-btn.ant-btn-primary,
  .cancelBtn {
    background: #6287EC;
    border: 1px solid #6287EC;
    color: #fff;
  }

  .ant-btn.ant-btn-primary[disabled] {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    border-color: #d9d9d9;
    box-shadow: none;
  }

  .content{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 60px 0 80px;
    .left{
      font-size: 18px;
      max-width: 40%;
      .msg{
        font-size: 14px;
        margin-top: 10px;
      }
      .color{
        color: #6287EC;
      }
    }

    .ant-select-arrow{
      top: 15px;
    }

    /*箭头*/
    .arrows{
      display: flex;
      flex-direction: row;
      align-items: center;
      .square{
        width: 30px;
        height: 20px;
        background-color: #ccc;
      }
      .triangle{
        width: 0;
        height: 0;
        background-color: transparent;
        border:20px solid;
        border-color:transparent transparent transparent #ccc;
      }
    }
    .hintMsg{
      color: red;
      margin-top: 5px;
    }
  }
  
  

}