.dctData_content {
  
    .dct_top_tit{
        background-color: #fff;
        padding: 20px 20px 0 20px;
        font-weight: bolder;
    }
    .dct_top {
      width: 100%;
      background: #fff;
      padding: 10px 16px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .cols-con{
        padding: 0 10px;
        background-color: #fff;
    
        .subjects-right {
          padding-left: 6px;
          text-align: left;
    
          .number{
            font-size: 22px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .name{
            font-size: 14px;
            font-weight: 500;
            color: rgba(0, 0, 0, 0.65);
            margin-bottom: 5px;
          }
        }
      }
    }
  }