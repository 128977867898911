.planDetail {
  padding: 16px;
  height: calc(100% - 59px);
  overflow-y: scroll;
  background: #F6F7FC;

  .planDetailCon {
    width: 100%;

    .detail_title {
      margin-bottom: 12px;
      background: #fff;

      h4 {
        background: #fff;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000;
        padding-left: 20px;
        padding-right: 40px;
        line-height: 60px;
        height: 60px;
        border-bottom: 1px solid #e9e9e9;
        margin-bottom: 0px;
      }

      .info {
        display: flex;
        flex-direction: column;
        padding: 20px;

        .div1,
        .div2 {
          display: flex;
          align-items: center;
          margin-bottom: 16px;

          &:last-child {
            margin-bottom: 0px;
          }

          div {
            width: 20%;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.65);

            span {
              display: inline-block;
              width: 84px;
              text-align: right;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.65);
            }
          }
        }

        .div3 {
          p {
            display: block;
            margin: 0px;
            width: 100%;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.65);

            &:first-child {
              margin-bottom: 4px;
            }

            &:nth-of-type(2) {
              text-align: left;
              letter-spacing: 1.4px;
              margin-bottom: 0;
              height: 47px;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
              word-break: break-all;
              white-space: pre-wrap;
              padding-right: 94px;
            }
          }
        }
      }
    }

    .detail_statistics {
      margin-bottom: 12px;

      ul {
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: space-between;

        li {
          width: 19.4%;
          padding: 20px 0px;
          background: #E3B481;
          border-radius: 8px;
          color: #fff;
          /*text-align: center;*/

          &:nth-of-type(2) {
            background: #4FCBE6;
          }

          &:nth-of-type(3) {
            background: #93E673;
          }

          &:nth-of-type(4) {
            background: #E6DB73;
          }

          &:nth-of-type(5) {
            background: #D091E6;
          }

          .ant-space-align-center {
            width: 100%;
            padding: 0px 30px 0px 20px;

            .ant-space-item {
              width: 50%;
              font-size: 14px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #fff;
              text-align: center;

              &:nth-of-type(2) {
                font-size: 22px;
                text-align: center;
                font-family: Roboto;
                overflow: hidden;
                text-overflow: ellipsis;

                .num {
                  font-size: 22px;
                  text-align: center;
                  font-family: Roboto;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }
            }

            .ant-space-item-split {
              .ant-divider-vertical {
                border-left: 1px solid #fff;
              }
            }
          }


        }
      }
    }

    .detail_con {
      width: 100%;
      min-height: calc(100vh - 320px);
      height: 100%;
      /*display: flex;
      justify-content: space-between;*/
      background: #fff;

      .edit_left {
        position: relative;
        width: 400px;
        background: #fff;
        float: left;
        height: 100%;

        .title {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #000;
          padding-left: 20px;
          padding-right: 40px;
          line-height: 60px;
          height: 60px;
          border-bottom: 1px solid #e9e9e9;
        }

        .con {
          /*height: 492px;
          overflow: scroll;*/
          margin-top: 20px;
          margin-bottom: 120px;
          padding: 0px 20px;

          #cedeModal {
            position: fixed;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            z-index: 99;
            display: none;

            #cedeModal_content {
              position: absolute;
              box-shadow: 0px 0px 3px 0px #d0cfcf;
              z-index: 999;
              background-color: #FFFFFF;

              .ant-menu-item-active {
                background: #6287ec;
                color: #fff;
              }
            }
          }
        }

        .ant-tree {
          position: relative;

          .ant-tree-treenode {
            padding: 2px 0px;

            &.ant-tree-treenode-selected {
              background-color: #6287ec;

              .ant-tree-switcher {
                background-color: #6287ec !important;
                color: #fff !important;
              }

              &:before {
                background-color: #6287ec;

                .ant-tree-switcher {
                  background-color: #6287ec !important;
                  color: #fff !important;
                }
              }
            }
          }

        }

        .ant-menu-vertical {
          border: 1px solid #f0f0f0;
        }
      }

      .edit_right {
        width: calc(100% - 412px);
        float: right;
        background: #fff;
        border-left: 12px solid #F6F7FC;

        .title {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #000;
          padding-left: 20px;
          padding-right: 40px;
          line-height: 60px;
          height: 60px;
          border-bottom: 1px solid #e9e9e9;
        }

        .con {
          padding: 20px;

          .ant-form-item {
            margin-bottom: 20px;
          }
        }

        p {
          &:last-child {
            text-indent: 30px;
          }
        }
      }

      /*新*/

      .title {
        width: 100%;
        height: 60px;
        line-height: 60px;
        background: #fff;
        padding-left: 20px;
        padding-right: 40px;
        border-bottom: 1px solid #e9e9e9;
        position: relative;

        h4 {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #000;
          margin-bottom: 0px;
        }

        .historySelect {
          position: absolute;
          right: 20px;
          top: 0px;
        }
      }

      .con {
        width: 100%;
        overflow: hidden;
        padding: 20px;

        > .scrollCon {
          margin: 0px;
          padding: 0px 20px 0px 20px;
          white-space: nowrap;
          overflow-y: hidden;
          overflow-x: scroll;
          background: #F6F7FC;
          cursor: pointer;
          /*border: 1px solid #F6F7FC;*/

          > li {
            width: 300px;
            display: inline-block;
            text-align: center;
            /*background: #fff;*/
            vertical-align: top;

            .visibleTitle {
              padding: 20px 0px;
              border-bottom: 1px solid #e9e9e9;
              position: relative;
              display: flex;
              justify-content: space-between;

              .t_left {
                width: 40%;
                text-align: right;
                padding-right: 2px;

                .anticon {
                  margin-top: 3px;
                }
              }

              .t_right {
                width: 60%;
                text-align: left;

                p {
                  margin: 0;
                  padding: 0;

                  &:first-child {
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 500;
                    color: #2e2f33;
                  }

                  &:nth-of-type(2) {
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #9a9fab;
                  }
                }
              }

              .anticon {
                font-size: 16px;
                margin-right: 3px;
              }

              .rightIcon {
                position: absolute;
                right: -6px;
                top: 29px;

                .anticon {
                  font-size: 18px;
                }
              }

            }

            .conList {
              cursor: pointer;

              ul {
                margin: 0px;
                padding: 20px 10px 20px 0px;

                li {
                  width: 100%;
                  position: relative;
                  line-height: 40px;
                  /*border-bottom: 12px solid #F6F7FC;*/
                  background: #fff;
                  margin-bottom: 12px;
                  padding: 0px 20px;
                  text-align: left;

                  &:before {
                    content: '';
                    display: inline-block;
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 4px;
                    height: 100%;
                    background: #6c97f7;
                  }
                }
              }
            }

            &:last-child {

              .visibleTitle {

                .rightIcon {
                  display: none;
                }
              }

              .conList {
                ul {
                  padding-right: 0px;
                }
              }
            }
          }
        }
      }
    }

    .ant-select {

      &.ant-select-single {
        .ant-select-selector {
          border-color: #EEEFF7;
        }
      }

      .ant-select-arrow {
        .anticon {
          > svg {
            vertical-align: middle;
            font-size: 12px;
            margin-top: -6px;
            color: #C3C3C3;
          }
        }
      }
    }
  }
}