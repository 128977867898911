.down {
  height: calc(100% - 55px);
  background: #F6F7FC;
  padding: 0px 16px 16px;

  .down-box  {
    height: 100%;
    background: #FFFFFF;
    border-radius: 8px;
    box-shadow: 0px 0px 1px 1px #f3f3f3;
    padding: 5% 10%;

    .ant-typography {
      margin-bottom: 50px;
    }

    svg {
      width: 1.5em;
      height: 1.5em;
    }
  }

  .select-project-box {
    padding-top: 80px;
    height: calc(100% - 135px);
    display: flex;
    justify-content: center;
    align-items: center;
  }



  .generating-box {
    padding-top: 80px;
    width: 500px;
    margin: 10% 40%;
    color: #7ea3cf;
    font-size: 2em;

    .generating-note {
      display: inline;
      margin-left: 20px;
    }

    svg {
      width: 3em;
      height: 3em;
    }
  }

  .finish-box {
    padding-top: 80px;
    width: 500px;
    margin: 10% 40%;
    color: #7ea3cf;
    font-size: 2em;

    div {
      float: left;
    }

    .finish-note {
      display: inline;
      margin-left: 20px;
    }

    svg {
      width: 3em;
      height: 3em;
    }

    .ant-btn {
      margin-left: 20px;
      margin-top: 0px;
      color: #fff;
      svg {
        width: 1em;
        height: 1em;
      }
    }

    .back {
      float: none;
    }

  }
}