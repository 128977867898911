.form {
    position: relative;

    & .formModalCloseBtn {
        position: absolute;
        top: -16px;
        right: -12px;
        cursor: pointer;
    }

    & .form_content {
        width: 100%;
        height: calc(100vh - 250px);
        min-height: 500px;
        background-color: #ffffff;

        & .crf_form_content{
            height: calc(100vh - 300px);
            overflow-y: auto;
        }

        .repeat_msg {
            display: inline-block;
            background-color: #f5d06e;
            color: #fff;
            font-size: 12px;
            letter-spacing: 1px;
            padding: 2px 8px;
            border-radius: 2px;
            margin-bottom: 20px;
        }
    }

    & .footer {
        display: flex;
        justify-content: flex-start;
        margin-top: 20px;
        padding-top: 10px;
        border-top: 1px solid #f0f0f0;
        align-items: center;

        & .controlBtn_content {
            margin-left: 40px;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            & .controlBtn {
                padding: 2px 10px;
                border: 1px solid #8babf0;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
                border-bottom-left-radius: 4px;
                border-bottom-right-radius: 4px;
                cursor: pointer;

                &>span {
                    font-size: 16px;
                }
            }

            & .controlBtn:first-child {
                border-right: 0;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }

            & .controlBtn:nth-child(2) {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }

        & .footer_right {
            margin-left: auto;
            display: flex;
            align-items: center;

            & .time {
                color: #9C9D9D;
                font-size: 12px;
                padding-top: 4px;
            }

            & .formActions {
                margin-left: 20px;

                & .positiveBtn {
                    margin-right: 10px;
                    background-color: rgb(98, 135, 236);
                    border: 1px solid rgb(98, 135, 236);
                }

                & .reverseBtn {
                    margin-right: 10px;
                    background-color: rgb(98, 135, 236);
                    border: 1px solid rgb(98, 135, 236);
                }

                & .savedBtn {
                    margin-right: 10px;
                    background-color: #ffffff;
                    color: rgb(98, 135, 236);
                    border: 1px solid rgb(98, 135, 236);
                }
            }
        }
    }
}

.ant-pagination-item-active {
    border-color: rgb(98, 135, 236) !important;

    a {
        color: rgb(98, 135, 236) !important;
    }
}

.ant-pagination-item:hover {
    border-color: #6287ec;

    a {
        color: #6287ec;
    }
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #6287ec;
}

.ant-tabs-tab:hover {
    color: #6287ec;
}

.ant-tabs-nav-wrap {
    border-bottom: 1px solid #E1E3E3;
    padding-bottom: 2px;
}

.ant-tabs-tab {
    padding: 8px 0;
}

.ant-tabs-ink-bar {
    background: #6287ec;
}

.ant-input-affix-wrapper:hover {
    border-color: #6287ec;
}

.ant-input-affix-wrapper-focused {
    border-color: #6287ec;

}

.ant-picker:hover {
    border-color: #6287ec;
}

.ant-radio:hover {
    .ant-radio-inner {
        border-color: #6287ec;
    }

    .ant-radio-inner::after {
        background-color: #6287ec;
    }
}

.ant-radio-checked-disabled {
    .ant-radio-inner {
        border-color: #6287ec;
    }

    .ant-radio-inner::after {
        background-color: #6287ec;
    }
}

.ant-checkbox-disabled .ant-checkbox-inner {
    background-color: #f5f5f5 !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
    border-color: #6287ec;
    background-color: #6287ec;
}

.ant-checkbox-wrapper:hover {
    .ant-checkbox-inner {
        border-color: #6287ec;

    }
}

.ant-pagination-simple .ant-pagination-simple-pager input:hover {
    border-color: #6287ec;
}