.cso_project_manage_modal_doctor_list {
    .not_allowed {
        cursor: not-allowed;
    }
    .start {
        color: #14ed14;
        position: relative;
        &::after {
            position: absolute;
            left: -10px;
            top: 50%;
            transform: translateY(-50%);
            content: '';
            width: 7px;
            height:7px;
            border-radius: 3.5px;
            background-color: #14ed14;
        }
    }
    .pause {
        color: #ff4d4f;
        &::after {
            position: absolute;
            left: -10px;
            top: 50%;
            transform: translateY(-50%);
            content: '';
            width: 7px;
            height:7px;
            border-radius: 3.5px;
            background-color: #ff4d4f;
        }
    }
    .closed {
        color: rgba(0, 0, 0, 0.85);
        &::after {
            position: absolute;
            left: -10px;
            top: 50%;
            transform: translateY(-50%);
            content: '';
            width: 7px;
            height:7px;
            border-radius: 3.5px;
            background-color: rgba(0, 0, 0, 0.85);
        }
    }
}