.refuseModals{
    .refuseContent{
        padding: 0 40px;
        position: relative;
        .ant-row, .ant-radio-wrapper{
            display: block;
            .ant-form-item textarea.ant-input{
                margin-top: 15px;
                border-radius: 5px;
            }
        }

        .ant-form-item:nth-of-type(1){
            margin-bottom: 10px;
        }
        .ant-form-item:last-of-type{
            position: absolute;
            z-index: 2;
            right: -8px;
        }
    }
}