.visit_list {
  display: flex;
  align-items: end;
  justify-content: space-between;
  margin-bottom: 8px;

  &_left {
    font-size: 14px;
    max-width: 120px;
  }

  &_right {
    font-size: 14px;
  }
}

.workbench {
  width: 100%;
  padding: 0 16px 34px 16px;
  height: calc(100% - 59px);
  overflow-y: scroll;
  background: #F6F7FC;



  .workbench_top {
    width: 100%;

    .cols-con {
      position: relative;
      display: flex;
      flex-direction: row;
      padding: 12px 8px;
      background-color: #fff;
      align-items: center;

      .info-tip {
        position: absolute;
        top: 5px;
        right: 5px;
        cursor: pointer;
        font-size: 14px;
        color: #6c96f7;
      }

      .subjects-left {
        margin: 0 10px;

        .item_icon {
          width: 45px;
          height: 45px;
          border-radius: 50%;
          position: relative;

          .anticon {
            svg {
              font-size: 20px;
              position: absolute;
              left: 0;
              right: 0;
              top: 0;
              bottom: 0;
              margin: auto;
            }
          }
        }
      }

      .subjects-right {
        padding-left: 6px;
        text-align: left;

        .number {
          font-size: 22px;
          font-family: Roboto;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .name {
          font-size: 14px;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.65);
          margin-bottom: 5px;
        }
      }
    }

  }

  .workbench_bottom {
    margin-top: 16px;

    .searchs {
      height: 64px;
      padding-left: 23px;
      padding-top: 24px;
      padding-right: 31px;
      background: #fff;

      .all_search {
        display: inline-block;
        width: 88%;

        .ant-input-group-wrapper {
          display: inline-block;
          width: calc(100% - 86px);

          .ant-input-wrapper {
            input {
              height: 36px;
              /*border: none;*/
              border-right: none;
              border-left: 1px solid #EEEFF7;
              border-top: 1px solid #EEEFF7;
              border-bottom: 1px solid #EEEFF7;
              border-top-left-radius: 2px;
              border-top-right-radius: 0px;
              border-bottom-right-radius: 0px;
              border-bottom-left-radius: 2px;

              &::placeholder {
                font-size: 14px;
              }
            }

            .ant-input-group-addon {
              /*border: none;*/
              border-right: none;
              border-left: none;
              border-top: 1px solid #EEEFF7;
              border-bottom: 1px solid #EEEFF7;
              background: #fff;

              .ant-select-arrow {
                color: #6287EC;
              }

              .ant-select-selection-search input,
              .ant-select-selection-item {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #6287EC;
              }
            }
          }

        }

        .ant-btn.ant-btn-primary {
          border-top-left-radius: 0px;
          border-top-right-radius: 2px;
          border-bottom-right-radius: 2px;
          border-bottom-left-radius: 0px;
          background-color: rgba(98, 135, 236, 1);
          border: none;

          span {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
          }
        }

        .select-after {
          width: 100px;
        }

        [data-theme='compact'] .select-after {
          width: 65px;
        }
      }

      .addSubject {
        display: inline-block;
        width: 10%;
        margin-left: 2%;

        .ant-btn.ant-btn-primary {
          border-top-left-radius: 0px;
          border-top-right-radius: 2px;
          border-bottom-right-radius: 2px;
          border-bottom-left-radius: 0px;
          background-color: #fff;
          border: 1px solid rgba(98, 135, 236, 1);

          span {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(98, 135, 236, 1);
          }
        }
      }
    }

    .ant-tabs-nav {
      background: #fff;
      padding: 15px 31px 0px 23px;
      margin-bottom: 12px;

      .ant-tabs-nav-wrap {
        border-bottom: none;
      }

      .ant-tabs-tab {
        padding-left: 20px;
        padding-right: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(154, 159, 171, 0.6);
        margin-right: 0px;

        &.ant-tabs-tab-active {
          .ant-tabs-tab-btn {
            color: rgba(98, 135, 236, 1);
            /*font-size: 16px;*/
          }
        }
      }

      .ant-tabs-ink-bar {
        background: rgba(98, 135, 236, 1);
      }
    }

    /*.workbench_bottom_con {
      .ant-tabs.ant-tabs-card {
        .ant-tabs-nav {
          margin-bottom: 0px;

          .ant-tabs-nav-list {

            .ant-tabs-tab {
              background: #B8C7CA;
              border-radius: 2px;
              padding-left: 20px;
              padding-right: 20px;

              .ant-tabs-tab-btn {
                color: #fff;
              }

              &.ant-tabs-tab-active {
                background: #fff;

                .ant-tabs-tab-btn {

                }
              }
            }
          }
        }
      }
    }*/
  }
}