.crfDetail {
  padding: 15px 0px 0px 0px;
  height: calc(100% - 40px);
  overflow-y: scroll;

  .detail_top {
    padding: 25px;

    .d_title {
      color: rgba(79, 117, 133, 100);
      font-size: 20px;
      text-align: left;
      font-family: SourceHanSansSC-regular;
    }

    .d_con {
      width: 100%;

      ul {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-content: center;
        flex-wrap: wrap;

        li {
          width: 20%;
          margin-bottom: 25px;

          span {
            color: rgba(16, 16, 16, 100);
            font-size: 14px;
            text-align: left;
            font-family: SourceHanSansSC-regular;
          }
        }
      }
    }
  }

  .detail_con {
    width: 100%;

    .detail_left {
      width: 30%;
      float: left;
      padding: 25px;
      border: 1px solid rgba(187, 187, 187, 100);

      .ant-tree {
        .ant-tree-node-content-wrapper {
          color: rgba(0, 0, 0, 0.45)
        }

        .ant-tree-treenode-selected {
          .ant-tree-node-content-wrapper.ant-tree-node-selected {
            color: #3734A9;

            .ant-tree-title {
              color: #3734A9;

              div {

              }
            }
          }
        }
      }

      .ant-menu-submenu > .ant-menu, .ant-tree, .ant-tree-show-line .ant-tree-switcher {
        background: #fff;
      }
    }

    .detail_right {
      width: 70%;
      float: right;
      padding: 25px;
      border: 1px solid rgba(187, 187, 187, 100);

      p {
        &:last-child {
          text-indent: 30px;
        }
      }
    }

  }

}