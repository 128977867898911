.inputBox {
    position: relative;
    width: 100%;
    overflow: hidden;
  }
  .field-list {
    display: flex;
    justify-content: space-between;
  }
  .field-item {
    box-sizing: border-box;
    width: 46px;
    height: 48px;
    line-height: 46px;
    font-size: 24px;
    color: #363a44;
    text-align: center;
    font-weight: bold;
    border-radius: 8px;
    background-color: #E5E9EF;
  }
  
  .field-item-focus::before {
    content: "";
    display: block;
    width: 1px; 
    height: 23px;
    margin:12px auto;
    background: #bbb;
    animation: blink 1s steps(1) infinite;
  }
  @keyframes blink {
    50% {
      opacity: 0;
    }
  }
  .field-input {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    right: 0;
  
    width: 100%;
    height: 46px;
    padding: 0;
    border: none;
    outline: none;
    opacity: 0;
    background: transparent;
  }
  
  