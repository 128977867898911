.register {
  width: 100%;
  height: 100%;
  background-image: url("register_bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;

  .register_title {
    line-height: 41px;
    color: rgba(89, 136, 145, 100);
    font-size: 28px;
    text-align: center;
    font-family: Roboto;
    margin-bottom: 30px;
  }

  .register_con {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    width: 30%;
    height: 80%;
    text-align: center;
  }

  .register_main {
    width: 100%;
    height: 450px;
    background: rgba(235, 248, 247, .65);
    line-height: 20px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 2px 2px 3px 1px rgba(157, 188, 193, 100);
    padding: 30px;
  }
}
