.queryList_wrap{
    padding: 0 16px 16px;
    background: #f5f7fa;
    height: calc(100% - 60px);

    .queryList{
        height: 100%;
        padding: 20px;
        background: #fff;
        overflow: auto;
        position: relative;
    }
    .header_content{
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;
        .left_wrap{
            .subTitle{
                color: #999;
                font-size: 12px;
            }
        }
        .right_wrap{
            color: #999;
            font-size: 12px;
            .ant-select{
                color: #999;
                font-size: 12px;
                width: auto;
            }
            .choose{
                display: inline-block;
                .ant-select{
                    background: #f2f2f2;
                    border-radius: 8px;
                }
            } 
        }
    }


    .btns_wrap{
        background: #fff;
    }
    
    .ant-btn.ant-btn-link{
        font-size: 12px;
    }
    .partPop{
        text-align: left;
        .ant-btn.ant-btn-link{
            padding-left: 0;
            >span{
                font-size: 12px;
            }
        }
        .ant-btn.ant-btn-primary[disabled] {
            color: rgba(0, 0, 0, 0.25);
            background: #f5f5f5;
            border-color:transparent;
            box-shadow: none;
          }
    }
    .ant-table-pagination.ant-pagination{
        padding: 16px 0 0;
        background: #fff;
        margin:0;
    }

    .con_div{
        text-align: left;
        font-size: 14px;
        .title{
            color: #000;
            margin-bottom: 5px;
            font-size: 13px;
        }
        .sub-title{
            color: rgba(0, 0, 0, 0.45);
        }
        .statetxt{
            font-size: 12px;
            .ant-tag-blue, .ant-tag, .ant-tag-warning{
                border-color: transparent;
            }
        }
    }
}