.imageck-slider {
  height: 64px;

  .slick-arrow {
    &::after,&::before {
      color: rgb(51, 73, 96);
    }
  }

  .slick-list {
    .slick-track {
      .slick-slide {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2px;
        
        div {
          overflow: hidden;
          border-radius: 3px;
          position: relative;
          overflow: hidden;
          border: 1px solid #d8dfea;
          &:hover {
            border: 1px solid #004b83;
            cursor: pointer;
          }
          img {
            padding: 3px;
            border-radius: 3px;
            overflow: hidden;
          }
          i {
            height: 18px;
            line-height: 16px;
            font-size: 11px;
            font-style: normal;
            position: absolute;
            top: 5px;
            left: 5px;
            color: #fff;
            background-color: rgba(0,0,0,.7);
            padding: 0 6px;
            border-radius: 12px;
          }
        }
      }

      .slick-current {
        div {
          padding: 3px;
          background-color: #0c94e8;
          border: 1px solid #004b83;
          img {
            padding: 0;
          }
        }
      }
    }
  }
}