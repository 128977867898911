.video_audit {
    padding: 0 16px 16px;
    background: #f5f7fa;
    height: calc(100% - 60px);
    overflow-y: scroll;

    .audit_wrap {
        padding: 20px;
        background: #fff;
    }

    .search_wrap {

        button.ant-btn.ant-btn-primary {
            border-radius: 5px;
        }

        .ant-form-inline .ant-form-item {
            margin-bottom: 16px;

            .ant-select,
            .ant-input-affix-wrapper,
            .ant-picker {
                width: 200px;
            }
        }
    }


    .ant-btn.ant-btn-link {
        font-size: 13px;
    }

    .partPop {
        text-align: left;

        .ant-btn.ant-btn-link {
            padding-left: 0;
        }

        .ant-btn.ant-btn-primary[disabled] {
            color: rgba(0, 0, 0, 0.25);
            background: #f5f5f5;
            border-color: transparent;
            box-shadow: none;
        }
    }

    .ant-table-pagination.ant-pagination {
        padding: 16px 0 0;
        background: #fff;
        margin: 0;
    }

    .con_div {
        text-align: left;
        font-size: 13px;

        .state_create {
            color: #27ef14;
            position: relative;
            padding-left: 9px;

            .dot {
                width: 5px;
                height: 5px;
                border-radius: 50%;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                background-color: #27ef14;
            }
        }


        .title {
            color: #000;
            margin-bottom: 5px;
        }

        .sub-title {
            color: rgba(0, 0, 0, 0.45);
        }

        .yellow {
            color: orange;
        }
    }

    .ant-table-thead>tr>th {
        color: #666;
        background: #F4F7FC;
    }

    .ant-pagination-options {
        display: none;
    }
}

.videoAuditList {
    max-height: 400px;
    overflow-y: auto;

    .videoItem {
        width: 95%;
        margin: 0 auto 20px;
        background: #f2f2f2;

        video {
            display: block;
            object-fit: contain;
            width: 100%;
            height: 380px;
            margin: auto;
        }
    }
}