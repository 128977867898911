.metaManagement {
  width: 100%;
  padding: 0px 16px 34px 16px;
  height: calc(100% - 59px);
  overflow-y: scroll;
  background: #F6F7FC;

  .dataThemeForm {
   /* margin: 0px;*/
    margin-bottom: 16px;
    /*padding: 20px;
    background: #fff;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;*/

    .codeSetForm {
      margin: 0px;
      padding: 20px;
      background: #fff;
      display: flex;

      .ant-form-item {
        width: 25%;
        padding-right: 20px;
        margin-right: 0px;
        margin-bottom: 16px;
      }

      .searchRow {
        width: 100%;
        display: flex;
        align-items: center;
      }

      .ant-select {
        &.ant-select-single {
          .ant-select-selector {
            border-color: #EEEFF7;
          }
        }
      }

      .ant-form-item-label label {
        /* font-size: 13px;
         text-align: right;
         color: rgba(92, 136, 145, 100);*/
      }

      .ant-form-item-control input {
        /*width: 150px;
        height: 25px;
        border-radius: 3px;*/
      }

      .ant-form-item-control .ant-select-single {
        /*width: 150px;
        height: 25px;
        border-radius: 3px;*/

        .ant-select-selector {
          /*height: 100%;*/
        }

        .ant-select-arrow svg {
          vertical-align: middle;
          font-size: 12px;
          margin-top: -6px;
          color: #c3c3c3;
        }
      }

      /*.ant-form-item-control .ant-btn {
        font-size: 14px;
        text-align: center;
        background: #6287EC;
        border: 1px solid #6287EC;
      }*/

      .ant-btn.ant-btn-primary {
        background: #6287EC;
        border: 1px solid #6287EC;

        span {
          &:last-child {
            /* margin-left: 5px;*/
          }
        }
      }

      .searchBtn {
        border-color: #6287EC;

        span {
          color: #6287EC;
        }
      }
    }

    /*.ant-form-vertical {
      width: calc(100% - 146px);
    }*/

    /*.ant-form-vertical .ant-form-item {
      width: 25%;
      display: inline-block;
      margin-bottom: 20px;

      &:nth-of-type(5) {
        margin-bottom: 0px;
      }
    }*/

    /*.btns {
      width: 146px;
      float: right;
    }*/

    /*.ant-form-item-label label {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.65);
    }*/

    .ant-select {
      /*margin-right: 10px;*/

      &.ant-select-single {
        .ant-select-selector {
          border-color: #EEEFF7;
        }
      }

      .ant-select-selector {
        border-radius: 2px;
        /*height: 36px;*/
        border-color: #EEEFF7;

        /*.ant-select-selection-placeholder {
          line-height: 36px;
        }*/

        input {
          font-size: 14px;
          color: rgba(16, 16, 16, 100);
          font-family: PingFang SC;
        }
      }

      .ant-select-arrow {
        .anticon {
          > svg {
            vertical-align: middle;
            font-size: 12px;
            margin-top: -6px;
            color: #C3C3C3;
          }
        }
      }
    }

    .ant-btn.ant-btn-primary {
      background: #6287EC;
      border: 1px solid #6287EC;

      span {
        &:last-child {
          /* margin-left: 5px;*/
        }
      }
    }

    .searchBtn {
      border-color: #6287EC;

      span {
        color: #6287EC;
      }
    }
  }

  .tableList {
    margin: 0px;
    /*width: 100%;*/
    /*display: flex;
    justify-content: space-around;*/

    .tableBtns {
      width:100%;
      display:flex;
      justify-content: space-between;

      > div {
        float: left;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #6287EC;
        /*margin-right: 8px;*/
        cursor: pointer;

        &.del {
          color: #f04864;
          margin-right: 0px;
        }

        .anticon {
          font-size: 14px;
          margin-right: 1px;
        }
      }
    }
  }
}