.activityList {
  padding: 0 16px 16px;
  background: #f5f7fa;
  height: calc(100% - 55px);

  .status2,
  .status3 {
    color: red;
  }

  .status0 {
    color: #52c41a;
  }

  .status1 {
    color: #ef822b;
  }

  .top_content {
    display: flex;
    justify-content: space-between;

    .search_wrap {
      margin-right: 20px;
    }
  }

  .right_action {
    .btn_first {
      margin-right: 10px;
    }

    button.ant-btn.ant-btn-primary {
      border-radius: 5px;
    }
  }

  .activity_wrap {
    height: 100%;
    padding: 20px;
    background: #fff;
    overflow: auto;
  }

  .btn_search {
    margin-left: 12px;
  }
}

.label_upload {
  width: 280px;
  display: inline-block;
  margin-top: 10px;
}
