.dctReport_list{
    position: relative;
    .partPop{
        text-align: center;
        .ant-btn{
            font-size: 12px;
        }
        .ant-btn-primary{
            display: inline-block;
            background: #6287EC;
            border-color: #6287EC;
            margin-left: 15px;
        }
        .ant-btn.ant-btn-primary[disabled] {
            color: rgba(0, 0, 0, 0.25);
            background: #f5f5f5;
            border-color:transparent;
            box-shadow: none;
          }
    }
    .ant-table-pagination.ant-pagination{
        padding: 16px 16px 0;
        background: #fff;
        margin:0;
    }

    .con_div{
        text-align: left;
        .title{
            font-size: 13px;
            color: #000;
            display: flex;
            align-items: center;
            .tag{
                width: 6px;
                height: 6px;
                border-radius: 100%;
                margin-right: 5px;
            }
            .tagbule{
                background-color: #5a88ee;
            }
            .taggreen{
                background-color: #61d27d;
            }
        }
        .sub-title{
            font-size: 13px;
            color: rgba(0, 0, 0, 0.45);
            margin-bottom: 5px;
        }
    }
    .ant-table-cell:last-child {
        text-align: center;
    }
    
    .already-audit {
        opacity: .5;
        cursor: not-allowed;
    }

    .audit-reject-say-no {
        color: #D12400;
    }

    .case-audit-table-footer {
        text-align: right;
        padding-right: 20px;
        padding-top: 10px;
    }
    .ant-table-footer{
        padding: 0;
    }
}
.case-audit-checkbox-wrapper .ant-checkbox-group .ant-checkbox-wrapper{
    width: 100%;

}
.case-audit-checkbox-wrapper {
    .case-audit-text-area {
        margin-top: 5px;
        border-radius: 5px;
        width: 100%;
        min-height: 100px;
        outline: none;
        border-color: #d4d0d0;
    }
}

.part_cancel {
    .ant-checkbox-group{
        width: 100%;

        .ant-checkbox-group-item {
            width: 33%;
            margin: 0 0 5px 0;
        }
    }
}