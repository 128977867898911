.videoCom {
  .crf_upload {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .upload_wrap {
    position: relative;
    background: #ddd;
    width: 80px;
    height: 80px;
    font-size: 20px;
    overflow: hidden;
    color: #fff;
    margin: 0 5px;
    display: flex;
    justify-content: center;
    align-items: center;

    .upload_file {
      position: absolute;
      right: 0;
      top: 0;
      opacity: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
  }

  .none_img {
    display: inline-block;
    width: 80px;
    margin: 0 5px;
  }

  .upload_img {
    position: relative;
    width: 80px;
    height: 80px;
    margin: 0 5px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ddd;

    .ant-image,
    .img_item {
      /*width: 80px;
          height: 80px;*/
      max-width: 80px !important;
      max-height: 80px !important;
      object-fit: contain !important;
    }

    .icon_del {
      position: absolute;
      right: 0;
      top: 0;
      color: #555;
      cursor: pointer;
      font-size: 16px;
    }

    .curr_video {
      max-width: 100% !important;
    }

    .downLoad {
      width: 100%;
      position: absolute;
      right: 0;
      bottom: -20px;
      font-size: 12px;
      color: #6287ec;
      display: flex;
      justify-content: space-between;

      .opertion {
        cursor: pointer;
        color: #6287ec;
      }
    }
  }


}

.video_fullContent {
  .fullContent {
    padding-top: 36px;

    .video-react {
      video{
        height: 92% !important;
      }
    }
  }
}