.actionView {
    .actionList {
        display: flex;
        align-items: center;

        .actionItem {
            margin-left: 6px;

            >span {
                font-size: 14px;
            }
        }
    }
}

.popover_content {
    max-height: 500px;
    min-width: 300px;
    
    overflow: scroll;
    overflow: -moz-hidden-unscrollable;
    &::-webkit-scrollbar { display: none; }
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
        display: none;
    }

    -ms-overflow-style: none;

    .action_btn {
        margin-top: 10px;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        >.ant-btn {
            background-color: #6287ec;
            border: 1px solid #6287ec;
        }
    }

    .questionList {
        .questionItem {
            border-top: 1px dashed grey;
            padding: 6px 4px;
            max-width: 300px;

            .questionItem_top {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                .left {
                    .left_top {
                        display: flex;

                        .question_type {
                            white-space: nowrap;
                            display: flex;
                            align-items: center;
                            font-size: 12px;

                            >span {
                                font-size: 12px;
                            }
                        }

                        .questionContent {
                            text-align: left;
                            font-size: 12px;
                            padding-right: 10px;
                        }
                    }

                    .left_bottom {
                        font-size: 12px;
                        margin-left: 10px;
                        display: flex;
                        align-items: center;

                        .message {
                            color: #6287ec;
                            font-size: 12px;
                            margin-left: 2px;
                        }

                        >span {
                            margin-right: 2px;
                        }
                    }
                }

                .right {
                    margin-left: auto;
                    white-space: nowrap;
                    display: flex;
                    align-items: center;

                    .answerBtn {
                        border: 1px solid #6287ec;
                        color: #ffffff;
                        background-color: #6287ec;
                        border-radius: 10px;
                        padding: 0px 10px;
                        font-size: 12px;
                        cursor: pointer;
                    }

                    .reopenBtn {
                        border: 1px solid #6287ec;
                        color: #6287ec;
                        background-color: #ffffff;
                        border-radius: 10px;
                        padding: 0px 10px;
                        font-size: 12px;
                        margin-right: 10px;
                        cursor: pointer;
                    }

                    .closeBtn {
                        border: 1px solid #6287ec;
                        color: #ffffff;
                        background-color: #6287ec;
                        border-radius: 10px;
                        padding: 0px 10px;
                        font-size: 12px;
                        cursor: pointer;
                    }
                }
            }

            .questionAction {
                .action_taps {
                    display: flex;
                    align-items: center;
                    margin: 8px 0;
                    border: 1px solid #6287ec;
                    width: 240px;
                    border-radius: 2px;

                    .questionAction_content_tapItem {
                        flex: 1 1;
                        text-align: center;
                        white-space: nowrap;
                        cursor: pointer;
                        border-left: 1px solid #6287ec;
                    }

                    .questionAction_content_tapItem:first-child {
                        border-left: 0;
                    }
                }

                .QuestionActionContent {
                    .questionModifyCom{
                        border: 1px solid #d9d9d9;
                        margin-bottom: 10px;
                        padding: 6px;
                    }
                    .actionBtn {
                        margin-top: 4px;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;

                        .cancelBtn {
                            border: 1px solid #6287ec;
                            background-color: #ffffff;
                            color: #6287ec;
                            padding: 0px 10px;
                            border-radius: 10px;
                            font-size: 12px;
                            cursor: pointer;
                        }

                        .saveBtn {
                            border: 1px solid #6287ec;
                            background-color: #6287ec;
                            color: #ffffff;
                            padding: 0px 10px;
                            margin-left: 10px;
                            border-radius: 10px;
                            font-size: 12px;
                            cursor: pointer;
                        }
                    }
                }
            }
        }

        .questionItem:first-child {
            border-top: 0;
        }
    }

    .traceTable {
        margin-top: 10px;

        table {
            border-radius: 5px;

            th,
            td {
                text-align: center;
                font-family: Open Sans, sans-serif;
                font-size: 0.2rem;
                font-weight: 400;
                line-height: 22px;
                color: #192038;
            }

            thead {
                tr:first-child {
                    background-color: #6287EC;
                    color: #ffffff;

                    th {
                        color: #ffffff;
                    }
                }
            }

            tbody {
                tr:nth-child(2n) {
                    background-color: #eff2fb;
                }
            }
        }
    }
}

.box::-webkit-scrollbar {
    display: none
}