.con_div {
    text-align: left;
    font-size: 14px;

    .title {
        color: #000;
        margin-bottom: 5px;
    }

    .projectName,
    .hospitalName,
    .rejectReasons {
        height: 40px;
        word-break: break-all;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .sub-title {
        color: rgba(0, 0, 0, 0.45);
    }

    .yellow {
        color: orange;
    }

    .running {
        width: 76px;
        color: #EF822B;
        background-color: #FDF2E8;
        font-size: 12px;
        padding: 2px 0;
        text-align: center;
        box-sizing: border-box;
    }

    .ageree {
        width: 76px;
        color: #52C41A;
        background-color: #EAF6E3;
        font-size: 12px;
        padding: 2px 0;
        text-align: center;
        box-sizing: border-box;
    }

    .notPass {
        width: 76px;
        color: #F03333;
        background-color: #FFEEEE;
        font-size: 12px;
        padding: 2px 0;
        text-align: center;
        box-sizing: border-box;
    }

    .three_success {
        color: #52C41A;
        font-size: 14px;
        display: flex;
        align-items: center;

        .icon {
            min-width: 16px;
            min-height: 16px;
            background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' fill='none' version='1.1' width='16' height='16' viewBox='0 0 16 16'%3E%3Cdefs%3E%3CclipPath id='master_svg0_2882_35033'%3E%3Crect x='2' y='2' width='12' height='12' rx='0'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg style='mix-blend-mode:passthrough'%3E%3Cg style='mix-blend-mode:passthrough'%3E%3Cellipse cx='8' cy='8' rx='8' ry='8' fill='%2352C41A' fill-opacity='1'/%3E%3C/g%3E%3Cg style='mix-blend-mode:passthrough' clip-path='url(%23master_svg0_2882_35033)'%3E%3Cg style='mix-blend-mode:passthrough'%3E%3Cpath d='M12.35355,4.646446C12.5488,4.841709,12.5488,5.15829,12.35355,5.353555C12.35355,5.353555,6.85355,10.85355,6.85355,10.85355C6.65829,11.0488,6.34171,11.0488,6.14645,10.85355C6.14645,10.85355,3.646446,8.35355,3.646446,8.35355C3.4511845,8.158290000000001,3.4511845,7.84171,3.646446,7.64645C3.841709,7.45118,4.15829,7.45118,4.353555,7.64645C4.353555,7.64645,6.5,9.7929,6.5,9.7929C6.5,9.7929,11.64645,4.646446,11.64645,4.646446C11.8417,4.4511845,12.1583,4.4511845,12.35355,4.646446C12.35355,4.646446,12.35355,4.646446,12.35355,4.646446Z' fill-rule='evenodd' fill='%23FFFFFF' fill-opacity='1'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
            background-size: 100% 100%;
            margin-right: 4px;
        }
    }

    .three_fail {
        color: #F03333;
        font-size: 14px;
        display: flex;
        align-items: center;

        .icon {
            min-width: 16px;
            min-height: 16px;
            background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' fill='none' version='1.1' width='16' height='16' viewBox='0 0 16 16'%3E%3Cdefs%3E%3CclipPath id='master_svg0_2882_35035'%3E%3Crect x='2' y='2' width='12' height='12' rx='0'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg style='mix-blend-mode:passthrough'%3E%3Cg style='mix-blend-mode:passthrough'%3E%3Cellipse cx='8' cy='8' rx='8' ry='8' fill='%23F03333' fill-opacity='1'/%3E%3C/g%3E%3Cg style='mix-blend-mode:passthrough' clip-path='url(%23master_svg0_2882_35035)'%3E%3Cg style='mix-blend-mode:passthrough'%3E%3Cpath d='M4.646446,4.646446C4.841709,4.4511845,5.15829,4.4511845,5.353555,4.646446C5.353555,4.646446,8,7.2928999999999995,8,7.2928999999999995C8,7.2928999999999995,10.64645,4.646446,10.64645,4.646446C10.8417,4.4511845,11.1583,4.4511845,11.35355,4.646446C11.5488,4.841709,11.5488,5.15829,11.35355,5.353555C11.35355,5.353555,8.70711,8,8.70711,8C8.70711,8,11.35355,10.64645,11.35355,10.64645C11.5488,10.8417,11.5488,11.1583,11.35355,11.35355C11.1583,11.5488,10.8417,11.5488,10.64645,11.35355C10.64645,11.35355,8,8.70711,8,8.70711C8,8.70711,5.353555,11.35355,5.353555,11.35355C5.15829,11.5488,4.841709,11.5488,4.646446,11.35355C4.4511845,11.1583,4.4511845,10.8417,4.646446,10.64645C4.646446,10.64645,7.2928999999999995,8,7.2928999999999995,8C7.2928999999999995,8,4.646446,5.353555,4.646446,5.353555C4.4511845,5.15829,4.4511845,4.841709,4.646446,4.646446C4.646446,4.646446,4.646446,4.646446,4.646446,4.646446Z' fill-rule='evenodd' fill='%23FFFFFF' fill-opacity='1'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
            background-size: 100% 100%;
            margin-right: 4px;
        }
    }
}