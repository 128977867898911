.audit_permission {
    padding: 16px;
    margin: 0 16px;
    background-color: #fff;

    height: calc(100% - 59px);
    overflow-y: scroll;



    .search {
        padding-bottom: 30px;
        display: flex;
        justify-content: space-between;

        .searchLeft {
            display: flex;

            .searchItem {
                margin-right: 10px;
            }
        }

        .addBtn {
            float: right;
        }
    }
}

.button_fail_sure {
    text-align: center;
    margin-top: 20px;
}

.project_wrap {
    display: flex;
    margin-top: 20px;

    .project_project {
        width: calc(100% - 85px);
    }

    .item_project {
        display: flex;
        margin-bottom: 5px;
        margin-right: 10px;
        flex-wrap: wrap;
        width: 100%;

        .tags {
            max-width: 90%;
            white-space: nowrap;
            /*强制一行显示*/
            overflow: hidden;
            /*超出部分隐藏*/
            text-overflow: ellipsis;
            /*最后添加省略号*/
        }

        .item_icon {
            margin-left: 5px;
        }

        .anticon {
            font-size: 12px;
            color: #1890ff;
        }
    }

}