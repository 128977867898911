.banner_content {
  padding: 0 16px 16px;
  background: #f5f7fa;
  height: calc(100% - 55px);
  .btn-operate {
    display: flex;
    grid-gap: 6px;
    gap: 6px;
  }
  .apply_wrap {
    height: 100%;
    padding: 20px;
    background: #fff;
    overflow: auto;
  }

  .search_wrap_content {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;

    .search_left {
      flex-basis: 80%;

      .search_row {
        position: relative;

        .arrow_fold {
          width: 50px;
          position: absolute;
          right: -40px;
          top: 0;
          padding-top: 6px;
          color: #3391f0;
          display: flex;
          align-items: center;
          cursor: pointer;
        }

        .search_label {
          margin-right: 6px;
        }
      }

      .ant-col {
        margin-bottom: 16px;
        min-width: 200px;
      }
    }

    .right_action {
      flex-basis: 200px;
      display: flex;

      grid-gap: 10px;

      gap: 10px;

      .ant-btn {
        width: 90px;
        margin-bottom: 5px;
      }
    }
  }

  .btns_wrap {
    background: #fff;
    padding-bottom: 10px;

    .ant-btn-background-ghost {
      margin-right: 16px;
    }
  }

  .case-audit-checkbox-wrapper {
    .case-audit-text-area {
      margin-top: 5px;
      border-radius: 5px;
      width: 100%;
      min-height: 100px;
      outline: none;
      border-color: #d4d0d0;
    }
  }
}

.img_url {
  width: 70px;
  height: 70px;
}
