.research_container {
    padding: 16px;
    margin: 0 16px;
    background-color: #fff;
    height: calc(100% - 59px);
    overflow-y: scroll;
    padding-top: 0;

    .search {
        display: flex;
        justify-content: space-between;
        background-color: #fff;

        .search_con {
            flex: 1 1;
            display: flex;
            flex-wrap: wrap;

            .search_item {
                margin-right: 30px;
                display: flex;
                align-items: center;
                padding-top: 16px;
                position: relative;
                .label {
                    width: 60px;
                    display: inline-block;
                    text-align: right;
                    margin-right: 8px;
                }
            }
            .item_input_select {
                position: absolute;
                top: 48px;
                right: 0;
                width: 280px;
                background-color: #FFFFFF;
                color: #666666;
                box-shadow: 0px 0px 3px 0px #d0cfcf;
                max-height: 150px;
                overflow: auto;
                z-index: 999;
                cursor: pointer;
                padding: 10px;
                .item_input_select_list:hover {
                    color: #1890ff;
                }
                .item_input_select_list > div {
                    display: inline-block;
                }
            }
        }

        .search_btn {
            display: flex;
            margin-left: 54px;
            margin-right: 27px;
            padding-top: 16px;
        }
    }

    .cpds_content {
        padding-top: 22px;


        .progress {
            text-align: center;

            .status {
                display: flex;

                span {
                    flex: 1 1;
                    color: rgba(51, 51, 51, 0.45);
                }
            }
        }

        .block_list {
            display: flex;
            padding: 0 19px;


            .block {
                min-width: 22px;
                height: 22px;
                border-radius: 50%;
                margin-left: 40px;
                position: relative;

                &:first-child {
                    margin-left: 0;

                    &::after {
                        display: none;
                    }

                    .time {
                        display: none;
                    }
                }

                &::after {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: -40px;
                    content: "";
                    width: 40px;
                    height: 1px;
                    border-bottom: 2px dashed #000;
                }



                .time {
                    position: absolute;
                    top: -10px;
                    left: -40px;
                    width: 40px;
                    text-align: center;
                }
            }



            .success {
                background-image: url('../../../static/csoImages/success.svg');
                background-size: 100% 100%;

                &::after {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: -40px;
                    content: "";
                    width: 40px;
                    height: 1px;
                    border-bottom: 2px solid #3391F0;
                }

                .time {
               
                    color: #3391F0;
                }
            }

            .error {
                background-image: url('../../../static/csoImages/error.svg');
                background-size: 100% 100%;

                &::after {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: -40px;
                    content: "";
                    width: 40px;
                    height: 1px;
                    border-bottom: 2px dashed #FE1805;
                }

                .time {
                    color: #FE1805;
                }
            }
            .refund {
                background-image: url('../../../static/csoImages/refund.svg');
                background-size: 100% 100%;

                &::after {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: -40px;
                    content: "";
                    width: 40px;
                    height: 1px;
                    border-bottom: 2px dashed #FFA34C;
                }

                .time {
                    color: #FFA34C;
                }
                .name {
                    color: #FFA34C;
                }
            }
            .wait {
                background-image: url('../../../static/csoImages/wait.svg');
                background-size: 100% 100%;

                &::after {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: -40px;
                    content: "";
                    width: 40px;
                    height: 1px;
                    border-bottom: 2px dashed #00b40d;
                }

                .time {
                    color: #00b40d;
                }
            }

            .notStarted {
                background-image: url('../../../static/csoImages/notStarted.svg');
                background-size: 100% 100%;

                &::after {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: -40px;
                    content: "";
                    width: 40px;
                    height: 1px;
                    border-bottom: 2px dashed #D8D8D8;
                }

                .time {
                    color: #D8D8D8;
                }
            }


        }

    }
}


.resourceModal {
    .modal_content {
        max-height: 70vh;
        overflow-y: auto;

        .menu {
            padding-bottom: 14px;

            .title {
                font-size: 16px;
                color: #3D3D3D;
                border-bottom: 1px solid #C0DFFF;
                padding-bottom: 7px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                img{
                    width: 24px;
                    height: 24px;
                    margin-right: 5px;
                }
                .pointer{
                    cursor: pointer;
                }
            }

            .menu_content {
                padding-top: 17px;
                display: flex;
                flex-wrap: wrap;

                .info_item {
                    flex-basis: 33%;
                    display: flex;
                    color: #333;
                    padding-bottom: 16px;

                    .info_label {
                        width: 60px;
                    }

                    .widthAuto {
                        width: auto;
                    }

                    .text_right {
                        text-align: right;
                    }

                    .info {

                        .block_list {
                            display: flex;
                            padding: 0 30px;


                            .block {
                                min-width: 22px;
                                height: 22px;
                                border-radius: 50%;
                                margin-left: 60px;
                                position: relative;

                                &:first-child {
                                    margin-left: 0;

                                    &::after {
                                        display: none;
                                    }

                                    .time {
                                        display: none;
                                    }
                                }

                                &::after {
                                    position: absolute;
                                    top: 50%;
                                    transform: translateY(-50%);
                                    left: -60px;
                                    content: "";
                                    width: 60px;
                                    height: 1px;
                                    border-bottom: 2px dashed #000;
                                }
                                .time {
                                    width: 60px; 
                                    position: absolute;
                                    top: -10px;
                                    left: -60px;
                                    text-align: center;
                                    font-size: 12px;
                                }

                                .name{
                                    width: 30px;
                                    position: absolute;
                                    bottom: -28px;
                                    left: 0px;
                                }
                            }
                            .success {
                                background-image: url('../../../static/csoImages/success.svg');
                                background-size: 100% 100%;

                                &::after {
                                    position: absolute;
                                    top: 50%;
                                    transform: translateY(-50%);
                                    left: -60px;
                                    content: "";
                                    width: 60px;
                                    height: 1px;
                                    border-bottom: 2px solid #3391F0;
                                }

                                .time {
                                    position: absolute;
                                    top: -10px;
                                    left: -60px;
                                    text-align: center;
                                    color: #3391F0;
                                }
                                .name{
                                    color: #3391F0;
                                }
                            }

                            .error {
                                background-image: url('../../../static/csoImages/error.svg');
                                background-size: 100% 100%;

                                &::after {
                                    position: absolute;
                                    top: 50%;
                                    transform: translateY(-50%);
                                    left: -60px;
                                    content: "";
                                    width: 60px;
                                    height: 1px;
                                    border-bottom: 2px dashed #FE1805;
                                }

                                .time {
                                
                                    color: #FE1805;
                                }
                                .name{
                                    color: #FE1805;
                                }
                            }
                            .refund {
                                background-image: url('../../../static/csoImages/refund.svg');
                                background-size: 100% 100%;
                
                                &::after {
                                    position: absolute;
                                    top: 50%;
                                    transform: translateY(-50%);
                                    left: -60px;
                                    content: "";
                                    width: 60px;
                                    height: 1px;
                                    border-bottom: 2px dashed #FFA34C;
                                }
                
                                .time {
                                    color: #FFA34C;
                                }
                                .name {
                                    color: #FFA34C;
                                }
                            }
                            .wait {
                                background-image: url('../../../static/csoImages/wait.svg');
                                background-size: 100% 100%;

                                &::after {
                                    position: absolute;
                                    top: 50%;
                                    transform: translateY(-50%);
                                    left: -60px;
                                    content: "";
                                    width: 60px;
                                    height: 1px;
                                    border-bottom: 2px dashed #00b40d;
                                }

                                .time {
                            
                                    color: #00b40d;
                                }
                                .name{
                                    color: #00b40d;
                                }
                            }

                            .notStarted {
                                background-image: url('../../../static/csoImages/notStarted.svg');
                                background-size: 100% 100%;

                                &::after {
                                    position: absolute;
                                    top: 50%;
                                    transform: translateY(-50%);
                                    left: -60px;
                                    content: "";
                                    width: 60px;
                                    height: 1px;
                                    border-bottom: 2px dashed #D8D8D8;
                                }

                                .time {
                                    position: absolute;
                                    top: -10px;
                                    left: -30px;
                                    color: #D8D8D8;
                                }
                                .name{
                                    color: rgba(51,51,51,0.45);
                                }
                            }
                        }
                    }
                }

                .order_item {
                    flex-basis: 50%;

                    .info_label {
                        width: 72px;
                    }
                }

                .order_rate {
                    flex-basis: 100%;

                    .info_label {
                        width: 72px;
                    }
                }
            }

            .practice {
                display: block;

                .info_item {
                    .info_label {
                        width: 110px;
                        text-align: right;
                    }
                }
            }
        }

    }
}

.order_record_modal_wrap {
    .modal_content {
        max-height: 70vh;
        overflow-y: auto;
    }
    .title {
        display: flex;
        align-items: center;
    }
    .title_text {
        font-size: 16px;
        margin-left: 10px;
        font-weight: bold;
    }
    .item {
        display: flex;
        margin-top: 10px;
    }
    .item_left {
        width: 70px;
        margin-right: 10px;
    }
    .item_right {
        flex: 1 1;
    }
    .modal_footer {

    }
}


.order_record_progress_wrap {
    .title {
        font-size: 24px;
    }
    .proress_title_text {
        font-size: 24px;
        margin-left: 10px;
        font-weight: bold;
    }
    .modal_content {
        .title {
            img {
                width: 48px;
            }
        }
    }
    .line_wrap {
        .dot {
            width: 10px;
            height: 10px;
            border-radius: 5px;
            border: #1890ff 1px solid;
            margin-top: 7.5px;
        }
        .line {
            width: 1px;
            height: 100%;
            background-color: #888888;
            margin-left: 50%;
            transform: translateX(-50%);
        }
    }
    .item_left {
        margin-left: 30px;
        .date {
            color: #000000;
        }
        .time {
            color: #888888;
        }
    }
    .item_right {
        border: 1px solid #C4C4C4;
        border-radius: 10px;
        padding: 10px;
        > div {
            display: flex;
            align-items: center;
            margin-top: 4px;
        }
        .r_t {
            color: #3D3D3D;
            font-weight: bold;
        }
        .r_l {
            width: 8px;
            height: 8px;
            border-radius: 4px;
            background-color: #1890ff; 
        }
        .r_m {
            margin-left: 8px;
            color: #6F6F6F;
        }
        .r_r {
            margin-left: 16px;
            color: #000000;
        }
        .r_b_l {

        }
        .r_b_m {
            padding: 8px;
            color: #3D3D3D;
            background-color: #F3F5F9;
            border-radius: 5px;
        }
    }
}

.order_record_vr_modal_wrap {
    .label {
        margin-right: 20px;
    }
}