.metaManagementDetail {
  padding: 16px;
  height: calc(100% - 59px);
  overflow-y: scroll;
  background: #F6F7FC;

  .metaManagementDetailCon {
    width: 100%;

    .detail_title {
      margin-bottom: 12px;
      background: #fff;

      h4 {
        background: #fff;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000;
        padding-left: 20px;
        padding-right: 40px;
        line-height: 60px;
        height: 60px;
        border-bottom: 1px solid #e9e9e9;
        margin-bottom: 0px;
      }

      .info {
        display: flex;
        flex-direction: column;
        padding: 20px;

        > div {
          display: flex;
          align-items: center;

          &:first-child {
            margin-bottom: 16px;
          }

          div {
            width: 20%;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.65);
            word-break: break-all;

            span {
              display: inline-block;
              width: 70px;
              text-align: right;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.65);
            }
          }
        }
      }
    }

    .edit_con {
      /*height: 592px;*/
      min-height: calc(100vh - 320px);
      height: 100%;
      display: flex;
      justify-content: space-between;
      background: #fff;

      .edit_left {
        position: relative;
        width: 400px;
        background: #fff;
        float: left;
        height: 100%;

        .title {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #000;
          padding-left: 20px;
          padding-right: 40px;
          line-height: 60px;
          height: 60px;
          border-bottom: 1px solid #e9e9e9;
        }

        .con {
          /*height: 492px;*/
          margin-bottom: 120px;
          margin-top: 20px;
          padding: 0px 20px;

          #dataThemModal {
            position: fixed;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            z-index: 99;

            #dataThemModal_content {
              position: absolute;
              box-shadow: 0px 0px 3px 0px #d0cfcf;
              z-index: 999;
              background-color: #FFFFFF;

              .ant-menu-item-active {
                background: #6287ec;
                color: #fff;
              }

              .ant-menu-vertical {
                .ant-menu-item {
                  margin-bottom: 0px;
                  margin-top: 0px;

                  .anticon {
                    margin-right: 6px;
                  }
                }
              }
            }
          }
        }

        .ant-tree {
          position: relative;

          .ant-tree-treenode {
            padding: 2px 0px;

            &.ant-tree-treenode-selected {
              background-color: #6287ec;

              .ant-tree-switcher {
                background-color: #6287ec !important;
                color: #fff !important;
              }

              &:before {
                background-color: #6287ec;

                .ant-tree-switcher {
                  background-color: #6287ec !important;
                  color: #fff !important;
                }
              }
            }

            .ant-tree-node-content-wrapper {
              .ant-tree-title {
                word-break: break-all;
              }
            }
          }
        }

        .ant-menu-vertical {
          border: 1px solid #f0f0f0;
        }
      }

      .edit_right {
        width: calc(100% - 412px);
        /*height: 100%;*/
        float: right;
        background: #fff;
        border-left: 12px solid #F6F7FC;

        .title {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #000;
          padding-left: 20px;
          padding-right: 40px;
          line-height: 60px;
          height: 60px;
          border-bottom: 1px solid #e9e9e9;


          .ant-tabs-top {
            .ant-tabs-nav {
              margin-bottom: 0px;
              border-bottom: none;
              margin-top: 8px;

              &:before {
                border-bottom: none;
              }

              .ant-tabs-nav-wrap {
                border-bottom: none;

                .ant-tabs-tab {
                  .ant-tabs-tab-btn {
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #000;
                  }

                  &.ant-tabs-tab-active {
                    .ant-tabs-tab-btn {
                      color: #6287ec;
                    }
                  }
                }
              }
            }
          }
        }

        .con {
          padding: 20px;

          .ant-form-item {
            margin-bottom: 20px;
          }

          .tips {
            color: #6287ec;

            .anticon {
              font-size: 14px;
              margin-right: 6px;
            }
          }
        }

        p {
          &:last-child {
            text-indent: 30px;
          }
        }
      }
    }
  }
}