.batchAudit_container {


    height: calc(100% - 80px);
    overflow-y: auto;

    .batchAudit_content {
        margin: 0 16px;
        padding: 16px;
        height: 100%;
        background-color: #fff;
    }

    .audit_header {
        height: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title {
            font-size: 20px;
        }

        .search {
            display: flex;

            .ant-select,
            .ant-input-search {
                margin-right: 16px;
            }

            .createBtn {
                display: flex;
                align-items: center;

                img {
                    margin-right: 5px;
                }
            }
        }
    }


    .table_content {
        padding-top: 16px;
        height: 100%;


        .state_wait {
            color: #FFB83A;
            position: relative;
            padding-left: 9px;

            .dot {
                width: 5px;
                height: 5px;
                border-radius: 50%;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                background-color: #FFB83A;
            }
        }

        .state_create {
            color: #27ef14;

            .dot {
                background-color: #27ef14;
            }
        }

        .state_approve {
            color: #0092f8;

            .dot {
                background-color: #0092f8;
            }
        }

        .state_postback {
            color: #F482FB;

            .dot {
                background-color: #F482FB;
            }
        }

        .state_error {
            color: #ff2d33;

            .dot {
                background-color: #ff2d33;
            }
        }

        .state_valid {
            color: #A3A3A3;

            .dot {
                background-color: #A3A3A3;
            }
        }




    }
}

.creatModal {
    .title {
        color: rgba(0, 0, 0, 0.65);
        padding-bottom: 24px;
    }

    .selectProject {
        display: flex;
        align-items: center;
        color: rgba(0, 0, 0, 0.65);
    }
}

.uploadModal {

    .footer_btn {
        display: flex;
        justify-content: space-between;
    }


    .title {
        color: rgba(0, 0, 0, 0.65);
        padding-bottom: 24px;
    }

    .selectProject {
        display: flex;
        align-items: start;
        color: rgba(0, 0, 0, 0.65);
        position: relative;
    }

    .uploadtip {
        padding-left: 70px;
        padding-top: 5px;
        color: rgba(0, 0, 0, .45);
    }

    .notips {
        width: 186px;
        line-height: 30px;
        border-radius: 20px;
        position: absolute;
        left: 230px;
        background: #FFF1F0;
        border: 1px solid #FFA8A5;
        color: #756E6E;
        padding-left: 10px;
    }


    .uploading_content {
        text-align: center;

        .uploading_title {
            font-weight: bold;
            font-size: 16px;
        }

        .uploading_tip {
            padding-top: 5px;
            color: #333;
            padding-bottom: 10px;
        }

        .uploading_progress {
            padding-top: 10px;
            color: rgba(0, 0, 0, .45);
        }
    }

    .upload_success_content {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            margin-right: 10px;
        }

        .success_tip {
            font-weight: bold;
            padding-bottom: 4px;
        }

        .success_num {
            color: #333;
        }
    }


    .upload_fail_content {

        .fail_title {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-bottom: 8px;

            img {
                margin-right: 6px;
            }

            span {
                font-weight: bold;
            }
        }

        .fail_tip {
            text-align: center;
            padding-bottom: 10px;
            color: rgba(0, 0, 0, .8);
        }

        .error_list {
            padding-top: 10px;
            border-top: 1px solid rgba(0, 0, 0, .1);
            max-height: 200px;
            overflow-y: auto;

            .error_item {
                color: #333;
                padding-bottom: 4px;
            }
        }
    }
}