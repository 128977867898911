.dctReport_detail{
    background-color: #F6F7FC;
    padding: 16px;
    height: calc(100% - 60px);
    overflow-y: auto;
    
    .title-con{
        padding: 0 0 15px;
        display: flex;
        justify-content: space-between;
        .title{
            font-weight: bolder;
            font-size: 14px;
        }
        .ant-btn-primary{
            background: #6287ec;
            border-color: #6287ec;
        }
    }
    
    .detailContent{
        height: 100%;

        .ant-modal-body{
            padding:20px 16px 16px;
        }
        .titleStyle{
            font-size: 13px;
            margin:0 0 8px;
            font-weight: bolder;
            border-left: 4px solid #6287ec;
            line-height: 12px;
            padding-left: 8px;
        }

        .ant-table-tbody td,
        .ant-table-thead th{
            color: #666;
            font-size: 12px;
        }
    }
    
}
