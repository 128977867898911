.cso_project_manage_select_wrap {
    .select_pre_text {
        margin-right: 12px;
    }
    .select_label {
        margin-right: 24px;
        margin-bottom: 12px;
    }
    .input_content {
        width: 200px;
        margin-right: 24px;
        margin-bottom: 12px;
    }
}