.monitoring {
  height: 100%;
  
  .monitoring_top {
    overflow: hidden;
    background: #fff;
    padding: 20px;

    .selectQueryGroup {
      /*搜索条件*/
      .searchCriteria{
        width: 20%;
        margin-right: 16px;
        height: 36px;
      }
      .col4{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-self: end;
        .anticon{
          font-size: 24px;
          margin-left: 15px;
        }
      }

      .ant-select {
        &.ant-select-single {
          .ant-select-selector {
            .ant-select-selection-search {
              line-height: 36px;

              input,
              input::placeholder {
                line-height: 36px;
              }
            }

            .ant-select-selection-placeholder,
            .ant-select-selection-item {
              line-height: 36px;
            }
          }
        }

        .ant-select-selector {
          border-radius: 2px;
          height: 36px;
          border-color: #EEEFF7;

          input {
            font-size: 14px;
            color: rgba(16, 16, 16, 100);
            font-family: PingFang SC;
          }
        }

        .ant-select-arrow {
          .anticon {
            > svg {
              vertical-align: middle;
              font-size: 12px;
              margin-top: -6px;
              color: #C3C3C3;
            }
          }
        }
      }
      
    }

    .btns {
      float: right;
      width: 20%;
      text-align: right;
      margin-top: 65px;

      .ant-btn {
        border: 1px solid #5CBDCE;
        height: auto;
        padding: 4px 6px;

        > .anticon {
          color: #fff;

          > svg {
            font-size: 13px;
          }
        }

        &.ant-btn-background-ghost {
          > .anticon {
            color: #fff;

            > svg {
              font-size: 14px;
            }
          }
        }

        > .anticon + span {
          margin-left: 5px;
        }

        &:first-child {
          background-color: #3734A9;
          border-color: #3734A9;
          border-radius: 4px 0px 0px 4px;

          span {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            margin-left: 3px;
          }
        }

        &:last-child {
          border-radius: 0px 4px 4px 0px;
          padding: 4px 14px;
          border-color: #D9D9D9;

          span {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.65);
          }
        }
      }
    }
  }

  .echartsGroup {
    width: 100%;
    margin-top: 16px;
    overflow: hidden;
    /*margin-bottom: 25px;*/
    min-height: calc(100% - 90px);
  }

  .statisticsData {
    width: 100%;
    background: #fff;

    > ul {
      margin: 0;
      padding: 0;
      width: 100%;
      display: flex;
      justify-content: center;
      vertical-align: center;

      > li {
        width: 33.33%;
        height: 100%;
        padding: 30px 66px 22px 66px;

        h3 {
          color: rgba(79, 117, 133, 100);
          text-align: left;
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #3734A9;
          margin-bottom: 15px;
        }

        .statisticsData_con {
          height: 300px;

          ul {
            margin: 0;
            padding: 0;
            width: 100%;

            li {
              overflow: hidden;
              padding: 4px 0px 4px 0px;

              div {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.65);

                &:first-child {
                  float: left;
                }

                &:last-child {
                  float: right;
                }
              }
            }
          }
        }
      }

      > li:nth-of-type(2) {
        position: relative;

        &:before {
          content: '';
          display: inline-block;
          position: absolute;
          width: 1px;
          height: 147px;
          background: #F6F7FC;
          left: 0px;
          top: 101px;
        }

        &:after {
          content: '';
          display: inline-block;
          position: absolute;
          width: 1px;
          height: 147px;
          background: #F6F7FC;
          right: 0px;
          top: 101px;
        }

      }
    }
  }

  /*echarts图表*/
  .charts{
    .container{
      background-color: #fff;
      padding:16px;
      margin:16px 0;
      .title{
        padding-bottom: 8px;
        border-bottom: 1px solid #e6e4e4;
        color: #999;
        font-size: 13px;
      }
    }
  }
  
}