.planEdit {
  width: 100%;
  padding: 16px;
  height: calc(100% - 59px);
  overflow-y: scroll;
  background: #F6F7FC;

  .planEditCon {
    width: 100%;
    /*height: 100%;*/
    background: #fff;

    .planEditConTop {
      .planEdit_title {
        background: #fff;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000;
        padding-left: 20px;
        padding-right: 20px;
        line-height: 60px;
        height: 60px;
        border-bottom: 1px solid #e9e9e9;
      }

      .planEdit_con {
        padding: 20px;
        background: #fff;
        width: 100%;

        .planForm {
          margin: 0px;
          display: flex;

          .ant-form-item {
            width: 25%;
            padding-right: 20px;
            margin-right: 0px;
            margin-bottom: 16px;
          }
        }
      }
    }

    .planEditConBottom {
      /*padding: 20px;
      border-top: 12px solid #F6F7FC;*/

      .tabsGroup {
        position: relative;
        min-height: 42px;

        .btnGroup {
          width: 162px;
          position: absolute;
          right: 20px;
          top: 0;
        }

        .ant-tabs {
          width: 100%;

          .ant-tabs-nav {
            width: calc(100% - 190px);
            margin-bottom: 0px;

            .ant-tabs-nav-wrap {
              border-bottom: none;
            }

            &::before {
              border-bottom: none;
            }

            .ant-tabs-tab {
              padding-left: 20px;
              padding-right: 20px;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: rgba(154, 159, 171, 0.6);
              margin-right: 0px;

              &.ant-tabs-tab-active {
                .ant-tabs-tab-btn {
                  color: rgba(98, 135, 236, 1);
                  /*font-size: 16px;*/
                }
              }
            }

            .ant-tabs-ink-bar {
              background: rgba(98, 135, 236, 1);
            }
          }

          .tabPaneCon {
            width: 100%;
            display: flex;
            border-top: 12px solid #F6F7FC;

            .TabPaneLeft {
              width: 400px;

              .left_title {
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #000;
                padding-left: 20px;
                padding-right: 40px;
                line-height: 60px;
                height: 60px;
                border-bottom: 1px solid #e9e9e9;
              }

              .left_con {
                padding: 20px 20px 100px 20px;

                ul {
                  padding: 0;
                  margin: 0;

                  li {
                    height: 44px;
                    line-height: 38px;
                    /*border: 1px solid #6c97f7;*/
                    border-bottom: 6px solid #F6F7FC;
                    cursor: pointer;
                    padding: 0px 10px 0px 14px;
                    position: relative;


                    .title {
                      float: left;
                    }

                    .operation {
                      float: right;

                      .span {
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #2e2f33;
                      }

                      .ant-space-item {
                        font-size: 12px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #2e2f33;

                        .anticon {
                          font-size: 14px;
                          margin-right: 2px;

                          &:first-child {
                            font-size: 12px;
                          }
                        }
                      }

                      .ant-space-item-split {
                        .ant-divider {
                          background: rgba(108, 150, 247, .1);
                          margin: 0px 3px;
                        }
                      }
                    }

                    &:before {
                      content: '';
                      display: inline-block;
                      position: absolute;
                      left: 0;
                      top: 0;
                      width: 4px;
                      height: 100%;
                      background: #6c97f7;
                    }

                    &:last-child {
                      height: 38px;
                      line-height: 38px;
                      cursor: pointer;
                      font-size: 14px;
                      color: rgba(154, 159, 171, 0.6);
                      border: 1px dashed #6c97f7;
                      text-align: center;
                      margin-bottom: 0;
                      margin-top: 10px;
                      padding-left: 0;

                      &:before {
                        display: none;
                      }

                      .anticon {
                        font-size: 14px;
                        margin-right: 5px;
                      }
                    }
                  }
                }
              }

            }

            .TabPaneRight {
              width: calc(100% - 400px);
              border-left: 12px solid #F6F7FC;

              .right_title {
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #000;
                padding-left: 20px;
                padding-right: 40px;
                line-height: 60px;
                height: 60px;
                border-bottom: 1px solid #e9e9e9;
              }

              .right_con {
                padding: 20px 50px 100px 50px;

                .ant-form-item {
                  .ant-form-item-label {
                    width: 100px;
                    text-align: right;
                  }

                  .ant-form-item-control {
                    .ant-form-item-control-input {
                      .ant-form-item-control-input-content {
                        /*.anticon-plus-circle {
                          padding-left: 108px;
                        }*/

                        .anticon {
                          &.anticon-plus-circle {
                            color: #6287EC;
                            font-size: 16px;
                          }
                        }
                      }
                    }
                  }
                }

                .ant-space {
                  .ant-space-item {
                    .anticon {
                      &.anticon-minus-circle {
                        color: #f04864;
                        font-size: 16px;
                      }
                    }
                  }
                }

                .visibleForm1 {
                  .ant-radio-group {
                    .ant-radio {
                      &.ant-radio-checked {
                        .ant-radio-inner {
                          border-color: #6c97f7;

                          &:after {
                            background-color: #6c97f7;
                          }
                        }
                      }
                    }
                  }
                }

                .visibleItem {
                  display: flex;
                  justify-content: space-between;
                  margin-bottom: 20px;
                  line-height: 32px;

                  .label {
                    width: 100px;
                    text-align: right;
                    display: flex;
                    justify-content: flex-end;

                    .sign {
                      color: red;
                      margin-right: 2px;
                      font-size: 14px;
                    }
                  }

                  .visibleItemForm {
                    width: calc(100% - 108px);
                    display: flex;
                    justify-content: space-between;

                    .ant-radio-group {
                      &.ant-radio-group-solid {
                        width: 72px;

                        .ant-radio-button-wrapper {
                          padding: 0px 11px;

                          &.ant-radio-button-wrapper-checked {
                            background-color: #6c97f7;
                            border-color: #6c97f7;
                          }
                        }
                      }
                    }

                    .add {
                      padding: 0px 11px;
                      background-color: #fafafa;
                      border: 1px solid #d9d9d9;
                      height: 32px;
                      line-height: 30px;
                      border-top-left-radius: 2px;
                      border-bottom-left-radius: 2px;
                      border-top-right-radius: 0px;
                      border-bottom-right-radius: 0px;
                      border-right: none;
                      cursor: pointer;
                      -webkit-user-select: none;
                              user-select: none;
                    }

                    .ant-input-group-wrapper {
                      > .ant-input-group {
                        .ant-input:first-child {
                          border-radius: 0px;
                        }
                      }
                    }

                    &.window {

                      .ant-input-group-wrapper {
                        width: calc(100% - 104px);
                      }
                    }
                  }

                  .ant-radio-group {
                    .ant-radio {
                      &.ant-radio-checked {
                        .ant-radio-inner {
                          border-color: #6c97f7;

                          &:after {
                            background-color: #6c97f7;
                          }
                        }
                      }
                    }
                  }
                }

                .visibleItemBtns {
                  text-align: right;
                }
              }
            }
          }
        }

        .addVisible {

          .tabPaneCon {
            width: 100%;
            display: flex;
            border-top: 12px solid #F6F7FC;

            .TabPaneLeft {
              width: 400px;

              .left_title {
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #000;
                padding-left: 20px;
                padding-right: 40px;
                line-height: 60px;
                height: 60px;
                border-bottom: 1px solid #e9e9e9;
              }

              .left_con {
                padding: 20px 20px 100px 20px;

                ul {
                  padding: 0;
                  margin: 0;

                  li {
                    height: 44px;
                    line-height: 38px;
                    /*border: 1px solid #6c97f7;*/
                    border-bottom: 6px solid #F6F7FC;
                    cursor: pointer;
                    padding: 0px 10px;
                    position: relative;


                    .title {
                      float: left;
                    }

                    .operation {
                      float: right;

                      .span {
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #2e2f33;
                      }

                      .ant-space-item {
                        font-size: 12px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #2e2f33;

                        .anticon {
                          font-size: 14px;
                          margin-right: 2px;

                          &:first-child {
                            font-size: 12px;
                          }
                        }
                      }

                      .ant-space-item-split {
                        .ant-divider {
                          background: rgba(108, 150, 247, .1);
                          margin: 0px 3px;
                        }
                      }
                    }

                    &:before {
                      content: '';
                      display: inline-block;
                      position: absolute;
                      left: 0;
                      top: 0;
                      width: 4px;
                      height: 100%;
                      background: #6c97f7;
                    }

                    &:last-child {
                      height: 38px;
                      line-height: 38px;
                      cursor: pointer;
                      font-size: 14px;
                      color: rgba(154, 159, 171, 0.6);
                      border: 1px dashed #6c97f7;
                      text-align: center;
                      margin-bottom: 0;
                      margin-top: 10px;
                      padding-left: 0;

                      &:before {
                        display: none;
                      }

                      .anticon {
                        font-size: 14px;
                        margin-right: 5px;
                      }
                    }
                  }
                }
              }

            }

            .TabPaneRight {
              width: calc(100% - 400px);
              border-left: 12px solid #F6F7FC;

              .right_title {
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #000;
                padding-left: 20px;
                padding-right: 40px;
                line-height: 60px;
                height: 60px;
                border-bottom: 1px solid #e9e9e9;
              }

              .right_con {
                padding: 20px 20px 100px 20px;

                .planSearchItem {
                  padding: 0px 50px 0px 50px;
                }
              }
            }
          }

        }
      }
    }

    .ant-form-item {
      margin-bottom: 20px;
    }

    .ant-btn-primary {
      background: #6287EC;
      border-color: #6287EC;
    }

    .ant-btn {
      border-color: #6287EC;
      margin-right: 10px;
      color: #6287EC;

      &.ant-btn-primary {
        color: #fff;
      }

      &:last-child {
        margin-right: 0px;
      }

      &.searchBtn {
        border-color: #6287EC;

        span {
          color: #6287EC;
        }
      }
    }

    .ant-select {

      &.ant-select-single {
        .ant-select-selector {
          border-color: #EEEFF7;
        }
      }

      .ant-select-arrow {
        .anticon {
          > svg {
            vertical-align: middle;
            font-size: 12px;
            margin-top: -6px;
            color: #C3C3C3;
          }
        }
      }
    }
  }
}

/*弹框*/
.designPlanModal {

  .ant-modal-close-x {
    height: 50px;
    line-height: 50px;

    .anticon {
      font-size: 12px;
    }
  }

  .ant-modal-header {
    padding: 14px 26px;
  }

  .ant-modal-title {
    font-family: PingFangSC-Regular, PingFang SC;
    line-height: 21px;
    font-size: 14px;
    font-weight: 500;
    color: #2E2F33;
  }

  .ant-btn.ant-btn-primary {
    background: #6287EC;
    border: 1px solid #6287EC;
    color: #fff;
  }

  .ant-modal-body {
    padding: 30px 46px;

    .ant-form-item {
      margin-bottom: 20px;
    }

    .ant-form-item-label {
      > label {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.65);
      }
    }

    .ant-radio-group {
      > label {
        span {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.65);
        }

        .ant-radio-checked {
          .ant-radio-inner {
            border-color: #6287EC;
          }
        }
      }
    }
  }

  .ant-modal-body,
  .ant-modal-footer {
    background: #F6F7FC;
    border-top: none;
  }

  .ant-modal-footer {
    padding: 0px;
  }

  .ant-modal-body {
    .addFormModal {
      .btnS {
        width: 100%;
        text-align: right;

        .ant-btn {
          border-color: #6287EC;
          margin-right: 10px;
          color: #6287EC;

          &.ant-btn-primary {
            color: #fff;
          }

          &:last-child {
            margin-right: 0px;
          }

          &.searchBtn {
            border-color: #6287EC;

            span {
              color: #6287EC;
            }
          }
        }
      }
    }
  }

  /*CRF表单模板库 插入模板*/

  .crfFormModal {
    display: flex;
    justify-content: space-between;

    .m_left {
      width: 300px;
      background: #fff;

      .top {
        .all_search {
          display: inline-block;
          width: 100%;

          .ant-input-group-wrapper {
            display: inline-block;
            width: calc(100% - 64px);

            .ant-input-wrapper {
              input {
                height: 36px;
                /*border: none;*/
                border-right: none;
                border-left: 1px solid #EEEFF7;
                border-top: 1px solid #EEEFF7;
                border-bottom: 1px solid #EEEFF7;
                border-top-left-radius: 2px;
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px;
                border-bottom-left-radius: 2px;

                &::placeholder {
                  font-size: 14px;
                }
              }

              .ant-input-group-addon {
                /*border: none;*/
                border-right: none;
                border-left: none;
                border-top: 1px solid #EEEFF7;
                border-bottom: 1px solid #EEEFF7;
                background: #fff;

                .ant-select-arrow {
                  color: #6287EC;
                }

                .ant-select-selection-search input,
                .ant-select-selection-item {
                  font-size: 14px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #6287EC;
                }
              }
            }

          }

          .ant-btn.ant-btn-primary {
            border-top-left-radius: 0px;
            border-top-right-radius: 2px;
            border-bottom-right-radius: 2px;
            border-bottom-left-radius: 0px;
            background-color: rgba(98, 135, 236, 1);
            border: none;

            span {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #FFFFFF;
            }
          }

          .select-after {
            width: 100px;
          }

          [data-theme='compact'] .select-after {
            width: 65px;
          }
        }
      }

      .bottom {
        margin-top: 20px;

        .ant-tree {
          position: relative;

          .ant-tree-treenode {
            padding: 2px 0px;

            &.ant-tree-treenode-selected {
              background-color: #6287ec;

              .ant-tree-switcher {
                background-color: #6287ec !important;
                color: #fff !important;
              }

              &:before {
                background-color: #6287ec;

                .ant-tree-switcher {
                  background-color: #6287ec !important;
                  color: #fff !important;
                }
              }
            }

            .ant-tree-node-content-wrapper {
              .ant-tree-title {
                word-break: break-all;
              }
            }
          }

        }
      }
    }

    .m_right {
      width: calc(100% - 312px);
      background: #fff;

      .title {
        padding-left: 20px;
        padding-right: 20px;
        line-height: 60px;
        height: 60px;
        border-bottom: 1px solid #e9e9e9;
        position: relative;

        h4 {
          margin: 0px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #000;
        }

        .historySelect {
          position: absolute;
          right: 20px;
          top: 0px;
        }
      }

      .con {
        height: 400px;
        padding: 20px;
      }

      .btns {
        text-align: right;
        padding: 20px;

        .searchBtn {
          border-color: #6287EC;
          color: #6287EC;
        }
      }
    }
  }

  /*CRF表单模板库 插入模板*/

  .crfSaveTempForm {
    .searchBtn {
      border-color: #6287EC;
      color: #6287EC;
    }
  }
}