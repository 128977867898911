.dctStatistics_content {
    background-color: #fff;
  
    .top_bottom{
        min-height: 328px;
        height: 328px;

        .tit{
            padding: 10px 20px;
            font-weight: bolder;
        }
        .statisticsNum{
            display: flex;
            justify-content: flex-start;
            padding: 10px 30px;
            .number{
                width: 50%;
                font-size: 24px;
                .numtxt{
                    font-size: 13px;
                    color: #999;
                }
            }
        }
        .ant-card-head{
            border-bottom:none;
            min-height: auto;
        }
        .ant-card-head-title{
            font-size: 12px;
            padding: 5px 0;
        }
        .ant-card-extra{
            padding: 5px 0 5px;
        }
        .ant-select{
            font-size: 12px;
            .ant-select-selector{
                border-color: transparent;
            }
            .ant-select-arrow{
                top: 51%;
            }
        }
        .ant-card-body{
            padding-top: 0;
            padding-bottom: 0;
        }

        .con_div{
            text-align: right;
            font-size: 12px;
            .title{
                display: inline-block;
                color: #333;
                min-width: 60px;
            }
        }

        .ant-table-thead{
            font-size: 12px;
            .ant-table-cell{
                text-align: right;
                color: #999;
                background: transparent;
                padding-top: 0px;
                padding-bottom: 10px;
                border:none;
            }
        }

        .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):before{
            width: 0 !important;
        }

        .ant-table-tbody .ant-table-cell{
            text-align: right;
            padding-top: 5px;
            padding-bottom: 5px;
        }

        .ant-table-tbody > tr > td{
            border-bottom: none;
        }

        .more{
            text-align: center;
            font-size: 12px;
            color: #999;
            padding: 15px 0;
            cursor: pointer;
        }

    }

    .ant-list-item-meta-avatar{
        width: 65px;

        .ant-tag{
            display: inline-block;
            width: 100%;
            text-align: center;
        }

      .ant-tag-default,
      .ant-tag-processing,
      .ant-tag-purple,
      .ant-tag-error,
      .ant-tag-warning{
          border-color: transparent;
      }

      .ant-tag-default{
          background-color: #f4f4f4;
      }
    }

    .ant-card-head-title{
        font-weight: bolder;
        font-size: 14px;
    }

    .timeTxt{
        font-size: 12px;
        color: #888;
    }
  }