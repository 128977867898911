.preview_content{
  height: 100%;
  .left_magnify{
    height: 100%;
    .magnify_wrap{
      height: calc(100% - 200px);
      .ImageBox{
        width: 100%;
        height: 100%;
        background: #e2e0e0;
        .ant-image{
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          .ant-image-img{
            max-width:100%;
            max-height:100%;
            object-fit: contain;
            display: block;
          }
        }
      }
    }

    .info_wrap{
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #e9eaec;
      height: 260px;
      padding: 0 16px;
      margin-bottom: 2%;
      position: relative;
      .info_left{
        margin: 5px 0;
        font-size: 14px;
        
        .tit{
          margin-bottom: 5px;
          font-weight: bolder;
          font-size: 16px;
        }
        .subtit{
          color: #999;
        }
        .sensitiveinfo{
          position: absolute;
          top: 20px;
          right: 30px;
          width: 10%;
        }
      }
      .operate_right{
        font-size: 1.2em;
        width: 25%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        /*align-items: center;*/
        svg {
          width: 2em;
          height: 2em;
        }
        .pass{
          color:#1890ff;
          cursor: pointer;
          text-align: center;
        }
        .nopass{
          color: #ff0000;
          cursor: pointer;
          margin-top: 20px;
          text-align: center;
        }
      }
    }

    .sign{
      background-color: #eed0a6;
      color: #ef9923;
      padding: 3px 10px;
      border-radius: 7px;
      font-weight: normal;
      display: inline-block;
      margin-right: 10px;
      font-size: 15px;
    }
  }
}