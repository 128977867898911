.container {
    display: inline-block;
    .ocr-gif{
        display: inline-block;
        height: 15px;
        margin-top: -3px;
        cursor: pointer;
    }
    .ocr-icon{
        color:#6c96f7;
        cursor: pointer;
    }
    .ocr-num{
        color: #fff;
        text-align: center;
        display: inline-block;
        width: 15px;
        height: 13px;
        line-height: 13px;
        font-size: 10px;
        border-radius: 2px;
        margin-right: 5px;
        margin-bottom: 3px;
        font-weight: normal;
    }
}