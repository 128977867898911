.container {
    margin: 0;
    padding: 0;

    .imgWrapper {
        height: 85px;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        overflow-x: scroll;
        overflow: -moz-hidden-unscrollable;

        &::-webkit-scrollbar {
            display: none;
        }

        -ms-overflow-style: none;
        background-color: #fbfbfb;

        img {
            width: 87px;
            height: 60px;
            margin-right: 12px;
            object-fit: cover;
            border: 1px solid #e6e4e4;
            cursor: pointer;

            &:first {
                margin-left: 8px;
            }

            &.imgActive {
                border: 1px solid#6287EC;
            }
        }

        img:hover {
            transform: scale3d(1.006, 1.006, 1);
            box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
            -o-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
        }

        .noOrc_img {
            width: 87px;
            height: 60px;
            margin-right: 12px;
            object-fit: cover;
            border: 1px solid #e6e4e4;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;

            &>span {
                font-size: 20px;
            }
        }
    }




    .bigPic {
        width: 100%;
        background-color: #666565;
        display: flex;
        height: calc(100vh - 440px);
        min-height: 320px;
        border: 1px solid #e6e4e4;

        .left-pane {
            width: 15%;
            height: 100%;
        }

        .center-pane {
            width: 100%;
            height: 100%;
            -webkit-user-select: none;
                    user-select: none;

            overflow: scroll;
            overflow: -moz-hidden-unscrollable;

            &::-webkit-scrollbar {
                display: none;
            }

            -ms-overflow-style: none;


            img {
                display: block;
                object-fit: contain;
                width: 100%;
                height: 100%;
            }

            & .noOrc_img {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;

                &>span {
                    font-size: 40px;
                }
            }
        }

        .right-pane {
            width: 15%;
            height: 100%;
        }
    }

    .picOpt {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        overflow: scroll;
        overflow: -moz-hidden-unscrollable;

        &::-webkit-scrollbar {
            display: none;
        }

        -ms-overflow-style: none;

        .left {
            padding-left: 10px;
            margin-top: 15px;
            margin-bottom: 10px;
            display: flex;
            align-items: center;

            >span {
                width: 33px;
                height: 30px;
                margin-right: 10px;
                border: 1px solid #e6e4e4;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
            }
        }

        .right {
            padding-right: 10px;
            margin-top: 15px;
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            margin-left: auto;

            >span {
                width: 33px;
                height: 30px;
                margin-left: 10px;
                border: 1px solid #e6e4e4;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
            }
        }
    }
}