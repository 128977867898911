.dictionaryEdit {
  width: 100%;
  padding: 16px;
  height: calc(100% - 59px);
  overflow-y: scroll;
  background: #F6F7FC;

  .dictionaryEditCon {
    width: 100%;
    background: #fff;

    .dictionaryEdit_title {
      background: #fff;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000;
      padding-left: 20px;
      padding-right: 40px;
      line-height: 60px;
      height: 60px;
      border-bottom: 1px solid #e9e9e9;
    }

    .dictionaryEdit_con {
      padding: 50px 0px;
      background: #fff;
    }

    .ant-form-item {
      margin-bottom: 20px;
    }

    .ant-btn-primary {
      background: #6287EC;
      border-color: #6287EC;
    }
  }
}