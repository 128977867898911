.header {
  width: calc(100% - 200px);
  height: 56px;
  /*background: #5CBDCE 10000%;*/
  background: #3391F0 10000%;
  position: fixed;
  right: 0;
  top: 0;
  padding: 0px 15px;
  z-index: 1;
  box-shadow: -6px 1px 6px #f2f2f2;

  .header_icon {
    display: inline-block;
    width: 3%;
    height: 100%;
    float: left;
    line-height: 56px;
    font-size: 17px;
    color: #fff;
  }

  .headerItems {
    .ant-tabs-nav {
      .ant-tabs-nav-wrap {
        border-bottom: none;
      }
    }
    .ant-tabs-top > .ant-tabs-nav::before {
      border-bottom: none !important;
    }
  }
  
  .signOut {
    width: 138px;
    height: 53px;
    position: absolute;
    bottom: -55px;
    right: 0px;
    color: #4F7585;
    font-size: 14px;
    text-align: center;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.4);
    border: 1px solid rgba(255, 255, 255, 100);
    background: #fff;
  }

  .signOut_icon {
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #4F7585;
    margin-top: -3px;
    vertical-align: middle;
    margin-right: 4px;
  }

}

.userName {
  //display:inline-block;
  width: 20%;
  height: 100%;
  /*background: #5CBDCE 10000%;*/
  background: #3391F0 10000%;
  font-size: 16px;
  float: right;
  line-height: 50px;
  color: #fff;
  cursor: pointer;
  position: relative;
  text-align: right;

  .user_name { /*火狐*/
    -webkit-user-select: none; /*webkit浏览器*/ /*IE10*/ /*早期浏览器*/
    -o-user-select: none; /* Opera*/
    user-select: none;
    display: inline-block;
    /*width: 66%;*/
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
  }

  .user_photo {
    display: inline-block;
    vertical-align: top;
    margin-right: 8px;
    margin-top: 10px
  }
}